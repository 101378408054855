import {
    Avatar,
    Box,
    Chip,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Link
} from "@material-ui/core";
import React, {Fragment} from "react";
import StatusButton from "../StatusButton";
import {theme} from "../../theme";
import ListProps from "./list";
import {Link as RouterLink} from "react-router-dom";

type detailType = 'author' | 'curator'

interface ItemDetailsProps {
    type: detailType
    user: string
    date: Date
    userId: number
}

interface CuratedItemDetailsProps {
    date: Date
    reviews: any
    length: number
}

enum detailText {
    author = 'Author',
    curator = 'Curated by'
}

function ItemDetails({date, type, user, userId}: ItemDetailsProps) {
    return (
        <>
            <Box fontWeight="fontWeightBold" display='inline-block'>
                {detailText[type]}
            </Box> :
            <Link component={RouterLink}
                  color='textPrimary'
                  to={`/profile/${userId}`}
                  style={{
                      marginLeft: 5,
                      marginRight: 5
                  }}
            >
                {user}
            </Link>
            &#8226; {type === "author" && 'Posted: '} {date.toLocaleDateString('ro-RO')}
        </>
    )
}

function CuratedItemDetails({date, reviews, length}: CuratedItemDetailsProps) {
    console.log(reviews);
    console.log('dada');
    return (
        <>
            <div style={{display: 'flex'}}>
                <Box fontWeight="fontWeightBold" display='inline-block'>
                    Curated by
                </Box> :
                {reviews
                    .map((review: any, index: number) => (
                        <Link component={RouterLink}
                              color='textPrimary'
                              to={`/profile/${review.user_id}`}
                              style={{
                                  marginLeft: 5,
                                  marginRight: 5
                              }}
                        >
                            {review.name}
                            {index < length - 1 ? ',' : ''}
                        </Link>
                    ))
                }

                &#8226; {date.toLocaleDateString('ro-RO')}
            </div>
        </>
    )
}

export default function EventsList({data}: ListProps<any>) {
    return (
        <>
            {data?.pages.map(page => (
                <Fragment key={page.current_page}>
                    {page.data.map((value: any, index: number) => (
                        <ListItem alignItems="flex-start" divider key={index}>
                            <ListItemAvatar>
                                <Avatar alt={`avatar-${index}`}
                                        src={value.img_url}
                                        variant="square"
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Box display='flex'
                                         flexDirection='row'
                                         justifyContent='space-between'
                                         marginRight='16px'
                                    >
                                        <div>
                                            <Link component={RouterLink}
                                                  color='textPrimary'
                                                  to={`/details/${value.type}/${value.id}`}
                                            >
                                                <Box display='initial'
                                                     fontWeight='fontWeightBold'
                                                     style={{
                                                         marginRight: theme.spacing(1),
                                                         wordBreak: 'break-all',
                                                     }}
                                                >
                                                    {value.title}
                                                </Box>
                                            </Link>
                                            <StatusButton style={{marginRight: 40, textTransform: 'uppercase'}}>
                                                {value.type == 'image' ? 'nft' : value.type}
                                            </StatusButton>
                                        </div>
                                        <span style={{alignItems: 'center'}}>
                                            <StatusButton variant={value.status}
                                                          size="small"
                                                          style={{textTransform: 'uppercase'}}
                                            >
                                                {value.status}
                                            </StatusButton>
                                        </span>
                                    </Box>
                                }
                                secondary={
                                    <Grid container
                                          style={
                                              {
                                                  width: '80%',
                                                  paddingTop: theme.spacing(2)
                                              }}
                                          spacing={2}
                                    >
                                        <Grid item>
                                            {
                                                value.type === 'image' &&
                                                <>
                                                    Event Name: <Box
                                                    display='inline'
                                                    fontWeight="fontWeightBold"
                                                >
                                                    {value.eventName}
                                                </Box></>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                value.type === 'image' &&
                                                <Link component={RouterLink}
                                                      to={`/details/event/${value.id}`}
                                                >
                                                    <Box display='flex' color='black'>
                                                        {value.description}
                                                    </Box>
                                                </Link>
                                            }
                                            {value.type === 'event' && (
                                                value.description
                                            )}
                                        </Grid>

                                        <Grid item container direction='column' alignItems="flex-start">
                                            <Grid item>
                                                <ItemDetails type='author'
                                                             user={value.user.name}
                                                             userId={value.user.id}
                                                             date={value.created_at}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <CuratedItemDetails
                                                    date={value.reviews[value.reviews.length - 1].created_at}
                                                    reviews={value.reviews}
                                                    length={value.reviews.length}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container direction='row' spacing={1}>
                                            {value.timelines_details?.map(({name}: { name: string }, index1: number) =>
                                                <Grid item key={index1}>
                                                    <Chip
                                                        size='small'
                                                        label={name}
                                                        style={{borderRadius: 2, marginRight: 8,color:'white'}}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                </Fragment>
            ))}
        </>
    );
}

