import {PropsWithChildren} from "react";
import './Bubble.css'

export default function Bubble({children} : PropsWithChildren<any>) {
    return (
        <div className='bubble' style={{textAlign:'center', alignItems:'center', whiteSpace: 'normal', marginTop:21}}>
            {children}
        </div>
    )

}