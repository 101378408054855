import {Box, SvgIcon} from "@material-ui/core";
import {ReactComponent as Ok} from "../_icons/check.svg";
import {theme} from "../theme";

enum Title {
    'review' = 'This post was submitted for review',
    'picture' = 'Thank you!',
    'event' = 'Thank you!',
    'contact' = 'Thank you!',
    'review_accept' = 'Thank you!',
    'review_rejected' = 'Thank you!',
    'pass_change' = 'Thank you!',
    'meta_change' = 'Thank you!',
    'already_reviewed' = 'Review started',
    'review_timeout' = 'Review time is up',
    'report' = 'Report submitted',
    'new_timeline' = 'Timeline created'
}

enum Message {
    'review' = 'Your proposal was submited  and is in the process of being reviewd\n' +
        'You will be notified soon about the status of your nft!\n' +
        'We are wishing  you a great day',
    'picture' = 'Your proposal was submited  and is in the process of being reviewd\n' +
        'You will be notified soon about the status of your nft!\n' +
        'We are wishing  you a great day',
    'event' = 'Your event has been submitted and waiting to be \n' +
        'verified and approved, you will be notified when its ready ',
    'contact' = 'Thank you for your message, The administrator will contact you soon.',
    'review_accept' = 'This post was added to the timeline.',
    'review_rejected' = 'This post was rejected.\n' +
        'A notification was sent to the author.',
    'pass_change' = 'Your password has been changed.',
    'meta_change' = 'Data update request sent.',
    'already_reviewed' = 'Another curator has started the review process.\n Thank you for your understanding!',
    'review_timeout' = 'The time maximum time for a review has elapsed. \n Please try again!',
    'report' = 'Your report was successfully sent.\nA curator will analyze the request.\nThank you for your contribution!',
    'new_timeline' = 'Thank you!\nThe timeline was added, users will be able to use it.'
}

export type variant = keyof typeof Title | 'none';

interface IProp {
    variant: variant
}

export default function DialogConfirmation({variant}: IProp) {
    if (variant === 'none')
        return null;

    return (
        <Box display='flex' flexDirection='column' justifyContent="center" alignItems='center' height={600}>

            <SvgIcon
                style={{fontSize: '50px', background: '#24E3AE', padding: 6, borderRadius: 8}}
                component={Ok}
                viewBox='0 0 24 24'
                color='action'
            />

            <Box fontWeight={700} fontSize='1.25rem' textAlign='center' marginTop={'17px'}>
                {Title[variant]}
            </Box>
            <Box whiteSpace='pre-wrap' textAlign='center' marginTop={theme.spacing(0.25)}>
                {Message[variant]}
            </Box>
        </Box>
    )
}