import React, { useState } from "react";
import { Avatar, Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as Fallback } from "../../_icons/no_pic_event.svg";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import "./UnselectedMultipleEvents.css";
import AllMultipleEvents from "./AllMultipleEvents";

const useStyles = makeStyles(() =>
  createStyles({
    unopened: {
      width: 32,
      height: 32,
    },
    fadingBox: {
      width: 8,
      height: 8,
      backgroundColor: "black",
    },
  })
);

export function UnselectedMultipleEvents({ events }: any) {
  const classes = useStyles();

  const hidden = [];
  for (let i = 0; i <= Object.keys(events).length / 2; i++) {
    hidden.push(i);
  }
  console.log("Hidden", hidden);
  const [isHovered, setIsHovered] = useState(false);

  function onHover() {
    setIsHovered(true);
  }

  if (isHovered)
    return <AllMultipleEvents events={events} setIsHovered={setIsHovered} />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      height="100%"
    >
      <Box className="fading" position="absolute" bottom="calc(50% + 15px)">
        {hidden.map((value, index) => (
          <Box
            marginBottom="16px"
            key={`top-${index}`}
            className={classes.fadingBox}
          />
        ))}
      </Box>

      <Avatar
        onClick={onHover}
        variant="square"
        src={events[0].img_url}
        className={classes.unopened}
      >
        <SvgIcon viewBox="0 0 32 32" className={classes.unopened}>
          <Fallback />
        </SvgIcon>
      </Avatar>

      <Box className="fading" position="absolute" top="calc(50% + 15px)">
        {hidden.map((value, index) => (
          <Box
            marginTop="16px"
            key={`bottom-${index}`}
            className={classes.fadingBox}
          />
        ))}
      </Box>
    </Box>
  );
}
