import HookForm from "../HookForm";
import {Grid} from "@material-ui/core";
import {ControlledImage, ControlledInput, ICOntrolledInputProps} from "./withControl";
import {useUser} from "../../userContext";
import {imagenService} from "../../_services/image.services";
import {useMutation} from "react-query";

export default function NewPictureForm() {
    const {dialogState: {options}, dialogDispatch} = useUser();
    let defaultValue = {};

    if (options.entity)
        defaultValue = {
            title: options.entity.title,
            description: options.entity.description,
            source: options.entity.source,
        }

    const fields: ICOntrolledInputProps[]  & Record<string, any> = [
        {
            name: "title",
            rules: {required: 'Title required', maxLength: {value: 120, message: "Title must contain maximum 120 chars"}},
            Component: ControlledInput,
            label:'Picture title',
            additional:120,
        }, {
            name: "description",
            multiline: true,
            rows: 3,
            rules: {
                required: 'Description required',
                maxLength: {value: 2000, message: "Description must be at most 2000 chars long"}
            },
            Component: ControlledInput,
            label:'Description',
            additional: 2000,
            marginTop: 20,
            marginBottom: '0px !important'
        },
        {
            name: "picture",
            rules: {
                required: 'Picture required',
            },
            Component: ControlledImage,
            label:'Upload picture',
            additional:'Supported formats: Gif with sound, png, jpeg.',
            defaultVal: options.entity?.img_url,
            marginTop: 38
        }
    ]
    const {mutate} = useMutation(variables => imagenService.postImage(variables))

    return (
        <HookForm defaultValue={defaultValue} onSubmit={(data) => {
            const form = {...data, event_id: options.event_id, notif_id: options.entity?.notif_id };
            mutate(form);
            dialogDispatch({type: "success", payload: {succes_type: 'picture'} })
        }}

        >
            {(control, setValue) =>
                <div style={{marginBottom:10}}>
                    <Grid container direction="column" style={{paddingBottom:'0px'}} >
                        {fields.map(({Component, ...value}, index) =>
                            <Grid item key={index}>
                                <Component style={{paddingBottom:'-7px'}}  {...value} control={control} setValue={setValue}/>
                            </Grid>
                        )}
                    </Grid>
                </div>

            }
        </HookForm>
    );
}