import {apiClient} from "./authentication.services";
import {IDetails} from "../pages/DetailsPage";


export const imagenService = {
    getImageDetails,
    postImage
};

async function getImageDetails( data: any) {
    const id = data.queryKey[1].id;

    const res = await apiClient.get(`/api/image/${id}`);

    return res.data
}

async function postImage( data: any ): Promise<IDetails> {
    const form = new FormData()
    form.append('picture', data.picture)
    form.append('title', data.title)
    form.append('description', data.description)
    if (data.source !== undefined)
        form.append('source', data.source)
    form.append('event_id', data.event_id)

    const res = await apiClient.post(`/api/image`, form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }});

    return res.data
}