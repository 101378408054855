import LogHOC from "../components/LogHOC";
import {Button, Grid, Link, Typography} from "@material-ui/core";
import withRedirect from "../components/UserRoles/RedirectingPages";
import {ControlledInput, ICOntrolledInputProps} from "../components/Forms/withControl";
import HookForm from "../components/HookForm";
import {Link as RouterLink} from 'react-router-dom';
import {useUser} from "../userContext";
import {useMutation} from "react-query";
import {apiClient, authenticationService} from "../_services/authentication.services";


function SignUpForm() {
    const fields:  ICOntrolledInputProps[]  & Record<string, any> = [
        {
            name: "name",
            placeholder: "John Doe",
            rules: {},
            Component: ControlledInput,
            label: 'Username'
        }, {
            name: "email",
            placeholder: "your email address",
            rules: {required: 'Email required'},
            Component: ControlledInput,
            label: 'Email'

        }, {
            name: "password",
            type: "password",
            placeholder: "********",
            rules: {required: 'Password required', minLength: { value: 8, message: "Password must be at least 8 chars long"}},
            Component: ControlledInput,
            label: 'Password'

        }
    ];

    const {state, dispatch} = useUser()

    const {mutateAsync} = useMutation( variables => authenticationService.signup(variables))


    function onSubmit(data: any) {
        dispatch({type: 'login'})
        mutateAsync(data).then(value => {
            localStorage.setItem("token", value.access_token);
            apiClient.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`

            dispatch({type: 'success'})
            if(state.refetch)
                state.refetch();
        });
    }

    return (
        <HookForm onSubmit={onSubmit} >
            {(control) =>
                <Grid container direction="column" spacing={2} style={{marginLeft:'-2px'}}>
                    {fields.map(({Component, ...value}, index) =>
                        <Grid item key={index}>
                            <Component {...value} control={control} style={{marginBottom: '16px'}} />
                        </Grid>
                    )}
                </Grid>
            }
        </HookForm>
    );
}

function Submit() {
    return (
        <div style={{display:'flex', alignItems:'baseline'}}>
            <Grid item>
                <Button color='primary'
                        variant="contained"
                        type="submit"
                        form='myform'
                        style={{textTransform:'capitalize', width:'167px', fontSize:'14px', padding: '10px 15px', letterSpacing: '-0.005em'}}
                        >
                    Register
                </Button>
            </Grid>
            <Typography style={{marginRight: 6,marginLeft:18, fontSize:'14px', fontFamily: 'Mulish Bold', letterSpacing: '0.3px'}}>Have an account?</Typography>
            <Link underline='always'
                  color='textPrimary'
                  component={RouterLink}
                  to="/login"
                  style={{fontWeight:'bold', fontSize:'18px', letterSpacing: '0.3px'}}
                  >
                Login
            </Link>
        </div>
    )
}

function SignupPage() {

    return (
        <LogHOC Form={SignUpForm} welcomeMsg="Register to continue" Submit={Submit}/>
    );
}

export default withRedirect(SignupPage)