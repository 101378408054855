import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactElement, ReactNode} from "react";

interface IProps {
    Left(classname: string): ReactElement
    Middle?: ReactNode
    Right(classname: string): ReactElement | null
    padding?: boolean
}

const useStyles = makeStyles({
    box: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerBox: {
        display: 'flex'
    },
    left: {
        marginRight: 'auto',
    },
    right: {
        marginLeft: 'auto',
    },
    fullWidth: {
        width: '100%'
    },
    noPadding: {
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 5
    },
    center: {
        margin: 'auto'
    }

})

export default function SpreadWithMiddle({Left, Middle, Right, padding=false}: IProps) {
    const classes = useStyles();

    const setPadding = (className: string) => {
        return `${className} ${!padding && classes.noPadding}`
    }

    return(
        <div style={{ marginBottom: 16 }}>
            <Box className={setPadding(classes.containerBox)}

                 p={1} >
                <Box p={1} className={setPadding(classes.box)} style={{maxWidth: ((window.innerWidth <= 1631 && window.innerWidth > 1403) ? 300 : (window.innerWidth <= 1403 ? 250 : 666))}}>
                        {Left(classes.left)}
                </Box>
                <Box p={1} className={classes.center} fontWeight='fontWeightBold'>
                    {Middle}
                </Box>
                <Box p={1} className={setPadding(classes.box)}  >
                        {Right(classes.right)}
                </Box>
            </Box>
        </div>
    )
}