import React from 'react';

import {Avatar, Badge, Box, Grid, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import StatusButton from "./StatusButton";
import {useQuery} from "react-query";
import {userService} from "../_services/user.services";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            large: {
                width: theme.spacing(11),
                height: theme.spacing(11),
                paddingTop: theme.spacing(1)
            },
            halfbg: {
                paddingTop: theme.spacing(2),
                backgroundImage: "linear-gradient(#222222 50%, transparent 50%)"
            },
            fullbg: {
                background: "#222222",
                backgroundClip: 'content-box',
                borderRadius: "5px 5px 0 0 ",
            },
            btn: {
                paddingTop: 20,
                paddingLeft: 20,
                textTransform:'capitalize',
            },
            descArea: {
                // marginTop: 10
                margin: 20
            },
            btnType: {
                cursor: "default"
            },
            online: {
                color: "white",
                backgroundColor: '#56CD2C',
                border: '2px solid white',
                borderRadius: '50%',
                padding: '0 4px',

            },
        }
    )
);

export default function AboutSection() {
    const classes = useStyles();
    const { id } = useParams<{id: string}>()
    const {data} = useQuery(['user_profile', {id: id}], userService.getUSerProfile);

    if (data === undefined)
        return null;

    return (
        <Grid container >
            <Grid item container alignItems="flex-start" className={classes.fullbg}>
                <Grid item className={classes.btn}>
                    <StatusButton variant={data.roles[0].name} style={{textTransform: 'capitalize', fontSize: 14, letterSpacing: 0.3, fontWeight: 700}}>
                        {data.roles[0].name}
                    </StatusButton>
                </Grid>
            </Grid>

            <Grid item container direction="column" alignItems="center" className={classes.halfbg}>
                <Badge overlap='circle'
                       classes={{badge: classes.online}} badgeContent=''
                       anchorOrigin={{vertical: 'bottom', horizontal: "right"}}
                       invisible={false}>
                    <Avatar variant='square' src={data.avatar} className={classes.large} />
                </Badge>
                <Box fontWeight='fontWeightBold' style={{textTransform: 'capitalize', fontSize: 20, marginTop: 8}}>{data.name}</Box>
            </Grid>

            <Grid item className={classes.descArea}>
                <Box fontWeight='fontWeightBold' style={{fontSize: 16, color: '#000', marginBottom: 10}}>About</Box>
                <Typography style={{fontSize: 14, color: '#000', letterSpacing: 0.3}}>{data.description}</Typography>
            </Grid>
        </Grid>

    )
}

