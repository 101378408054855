import React, { useRef, useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import { DialogAction, DialogState, useUser } from "../userContext";
import DialogConfirmation from "./DialogConfirmation";
import { useMutation } from "react-query";
import { userService } from "../_services/user.services";
import ImageModal from "./ImageModal";
import useClickOutside from "./ClickOutside";
import { AuthOnlyButton } from "./UserRoles/AuthOnly";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    closeButton: {
      position: "absolute",
      right: 25,
      top: 25,
      color: theme.palette.grey[500],
    },
    confirmationPopover: {},
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.root}
      {...other}
      style={{ paddingTop: 35, marginBottom: 45 }}
    >
      {children}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

export type btnType = "confirm" | "form" | "review" | "rejected" | null;

interface Props {
  dispatch: React.Dispatch<DialogAction>;
  text?: string;
  variant: btnType | null;
}

function DialogButtons({ dispatch, text, variant }: Props) {
  const handleClose = () => {
    dispatch({ type: "close" });
  };

  function onContest() {
    mutate({
      id: options.entity.id,
      type: options.type,
      notif_id: options.entity.notif_id,
    });
    dispatch({ type: "close" });
    dispatch({ type: "success", payload: { succes_type: "review" } });
  }

  const {
    dialogState: { options },
  } = useUser();
  const { mutate } = useMutation(userService.contest);

  if (variant === null) return null;

  if (variant === "rejected") {
      if(options.entity.reviews && options.entity.reviews.length >= 3) {
          return (
              <Grid container direction="column" spacing={2} alignItems="stretch">
                  <Grid item>
                      <Box
                          textAlign="center"
                          alignItems="center"
                          marginBottom="30px"
                          fontSize="14px!important"
                          marginTop="-10px"
                          fontWeight="700"
                      >
                          Thank you for your proposal. Unfortunatelly it was rejected by 3 curators. You can propose
                          another event or nft whenever you want.
                      </Box>
                  </Grid>
              </Grid>
          );
      }
      else {
          return (
              <Grid container direction="column" spacing={2} alignItems="stretch">
                  <Grid item style={{textAlign: "center"}}>
                      <Button
                          autoFocus
                          fullWidth
                          variant="outlined"
                          type="submit"
                          form="myform"
                          style={{fontWeight: 700, borderColor: "black"}}
                      >
                          {text}
                      </Button>
                  </Grid>
                  <Grid item style={{textAlign: "center"}}>
                      <Button
                          fullWidth
                          variant="outlined"
                          onClick={onContest}
                          style={{
                              marginBottom: "3px",
                              fontWeight: 700,
                              borderColor: "black",
                          }}
                      >
                          Repeat the review
                      </Button>
                  </Grid>
                  <Grid item>
                      <Box
                          textAlign="center"
                          alignItems="center"
                          marginBottom="3%"
                          fontSize="10px!important"
                          marginTop="0px"
                      >
                          3 curators will be reviewing your proposal for final verdict
                      </Box>
                  </Grid>
              </Grid>
          );
      }
  }

  if (variant === "confirm")
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        style={{ marginBottom: "30px" }}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{
              color: "black",
              borderColor: "black",
              width: "210px",
              height: "45px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            form="myform"
            style={{ width: "210px", height: "45px" }}
          >
            Yes
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Button
          autoFocus
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          form="myform"
          style={{ fontWeight: 700 }}
        >
          {text}
        </Button>
      </Grid>
      <Grid item>
        <Button
          autoFocus
          fullWidth
          variant="text"
          onClick={handleClose}
          style={{ fontWeight: 700, textTransform: "capitalize" }}
          type={variant === "review" ? "reset" : "button"}
          color="primary"
          form="myform"
        >
          {variant === "review" ? "Reject" : "Cancel"}
        </Button>
      </Grid>
    </Grid>
  );
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    textAlign: "center",
    // width: 600
  },
}))(MuiDialogContent);

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    paddingRight: "68px",
    paddingLeft: "68px",
  },
}))(MuiDialogActions);

interface IProp {
  state: DialogState;
  dispatch: React.Dispatch<any>;
}

export default function ActionDialog({
  dispatch,
  state: {
    image,
    header,
    form: Form,
    open,
    success,
    button: { variant, text },
    options,
  },
}: IProp) {
  const handleClose = () => {
    setPopoverOpened(false);
    dispatch({ type: "close" });
  };

  const closeTriggered = () => {
    setPopoverOpened(true);
  };

  const escFunction = (event: { keyCode: number }) => {
    if (event.keyCode === 27) {
      closeTriggered();
    }
  };
  document.addEventListener("keydown", escFunction, false);

  const ref = useRef(null);

  const [popoverOpened, setPopoverOpened] = useState(false);

  useClickOutside((e: any) => {
    console.log('this',e);
    if (ref.current) {
      if (typeof e.target.className === "string") {
        if (
          !e.target.localName.includes("body") &&
          !e.target.className.includes("MuiIconButton-label") &&
          !e.target.className.includes("MuiTypography-root") &&
          !e.target.className.includes("MuiToolbar-root") &&
          !e.target.className.includes("MuiButtonBase-root") &&
          !e.target.className.includes("MuiButton-label") &&
          !e.target.className.includes("MuiList-root") &&
          !e.target.className.includes("MuiGrid-root") &&
          !e.target.className.includes("MuiSelect-root") &&
          !e.path[1].className.includes("MuiPopover-root")
        ) {
          console.log(e);
          closeTriggered();
        }
      }
    }
  }, ref);

  return (
    <>
      <Dialog
        open={image}
        style={{ outline: "none" }}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "#181818",
            boxShadow: "none",
            zIndex: 2,
          },
        }}
      >
        <DialogContent>
          <ImageModal src={image && options.img_url} />
        </DialogContent>
      </Dialog>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ outline: "none" }}
        PaperProps={{
          style: {
            width: 591,
            maxHeight: 867,
          },
        }}
      >
        <div ref={ref}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {header}
          </DialogTitle>
          <DialogContent>
            {Form !== null && <Form />}
            <DialogConfirmation variant={success} />
          </DialogContent>
          <DialogActions>
            <DialogButtons dispatch={dispatch} variant={variant} text={text} />
          </DialogActions>
          <div
            style={{
              width: 350,
              height: 200,
              position: "absolute",
              backgroundColor: "white",
              top: "50%",
              left: "50%",
              marginTop: -100,
              marginLeft: -175,
              display: popoverOpened ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              border: "2px solid #ccc",
              borderRadius: "3px",
              padding: 20,
            }}
          >
            <span>Are you sure you want to exit?</span>
            <AuthOnlyButton
              fullWidth
              color="primary"
              variant="contained"
              size="small"
              onClick={handleClose}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              Yes
            </AuthOnlyButton>
            <AuthOnlyButton
              onClick={() => {
                setPopoverOpened(false);
              }}
              color="secondary"
              variant="contained"
              size="small"
              fullWidth
            >
              Cancel
            </AuthOnlyButton>
          </div>
        </div>
      </Dialog>
    </>
  );
}
