import {Box, Grid} from "@material-ui/core";
import React, {useEffect} from "react";
import {useUser} from "../userContext";

export default function TimeCounter({time, setTimeout}: {time: number, setTimeout: any}) {
    const {dialogDispatch} = useUser()

    function update() {

        setTimeout((time: number) => time - 1)
    }

    useEffect(() =>  {
        const interval = setInterval(update, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])


    useEffect(() =>{
        if (time === 0)
            dialogDispatch({type: 'success', payload: {succes_type: 'review_timeout'}})
    }, [time])

    return (
        <Box
            display='flex'
            flexDirection='row'
            justifyContent='start'

        >
            <Grid container>
                <Grid item xs={3}>
                    <Box fontWeight={700} textAlign='left'>
                        Time left:
                    </Box>
                </Grid>
                <Grid item style={{textAlign: 'start'}}>
                    {Math.floor(time / 60)} m {time % 60} s
                </Grid>
            </Grid>
        </Box>
    )
}