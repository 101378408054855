import {useUser} from "../../userContext";
import {JSXElementConstructor, useEffect} from "react";
import {Redirect, useHistory} from 'react-router-dom';
import {Backdrop, CircularProgress} from "@material-ui/core";

function isUserAuth(user: any) {
    return user
}

export default function withRedirect(WrappedComponent: JSXElementConstructor<any>,
                                     condition=isUserAuth, to='/') {
    return function RedirectComponent() {
        const {state: {user, isLoading, isFetching}} = useUser();

        if (condition(user)) {
            return <Redirect to={to} />
        }

        return <>
            <Backdrop open={isLoading || isFetching}>
                <CircularProgress />
            </Backdrop>
            <WrappedComponent />
        </>
    }
}

export function withLoginRedirect(WrappedComponent: JSXElementConstructor<any>,
                                  condition=isUserAuth) {
    return function RedirectComponent() {
        const {state: {user, isLoading, isFetching}} = useUser();
        const history = useHistory();

        useEffect(() => {

            if (condition(user)) {
                history.goBack()
            }
        },[user])


        return <>
            <Backdrop open={isLoading || isFetching}>
                <CircularProgress />
            </Backdrop>
            <WrappedComponent />
        </>
    }
}