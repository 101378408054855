import * as React from "react";
import {
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
  useEffect,
} from "react";
import { authenticationService } from "./_services/authentication.services";
import NewEventForm from "./components/Forms/NewEventForm";
import NewPictureForm from "./components/Forms/NewPictureForm";
import { Box, CircularProgress } from "@material-ui/core";
import ActionDialog, { btnType } from "./components/ActionDialog";
import { imagenService } from "./_services/image.services";
import { variant } from "./components/DialogConfirmation";
import ContactUsForm, { ContactHeader } from "./components/Forms/ContactUsForm";
import WalletForm from "./components/Forms/WalletForm";
import ChangeUserPermissionForm from "./components/Forms/ChangeUserPermissionForm";
import { useQuery } from "react-query";
import AddNewTimeline from "./components/Forms/AddNewTimeline";
import ReviewForm from "./components/Forms/ReviewForm";
import RejectForm from "./components/Forms/RejectForm";
import RejectionIssueForm from "./components/Forms/RejectionIssueForm";
import { userService } from "./_services/user.services";
import ReportErrorForm from "./components/Forms/ReportErrorForm";

type Action =
  | { type: "login" }
  | { type: "logout" }
  | { type: "success" }
  | { type: "user"; payload: { user: any } }
  | { type: "initial"; payload: { isFetchin: boolean; refetch: any } };

type State = {
  isLoading: boolean;
  user: any;
  refetch: (options?: any) => Promise<any>;
  isFetching: boolean;
};

type Dispatch = (action: Action) => void;

export type DialogAction =
  | {
      type: "event";
      payload: { timeline: { id: number; name: string }[]; entity?: any };
    }
  | {
      type: "image";
      payload: { event_id: number; entity_title: string; entity?: any };
    }
  | { type: "close" }
  | { type: "close_image" }
  | { type: "success"; payload: { succes_type: variant } }
  | {
      type: "confirm";
      payload: { user_id: number; role: string; onSuccess: any };
    }
  | { type: "preview"; payload: { img_url: string } }
  | { type: "contact" }
  | { type: "wallet" }
  | { type: "timeline" }
  | { type: "review"; payload: { entity: any; timeout: number } }
  | { type: "reject"; payload: { timeout: number; rejectionReasons: any } }
  | { type: "user_contest"; payload: { entity: any } }
  | { type: "wait_review"; payload: { entity: any } }
  | { type: "report" };

export type DialogState = {
  open: boolean;
  form: JSXElementConstructor<any> | null;
  header: ReactNode | null;
  success: variant | any;
  options: any | null;
  button: { text?: string; variant: btnType | any };
  image: boolean;
};
export type DialogDispatch = (action: DialogAction) => void;

const UserContext = React.createContext<
  | {
      state: State;
      dispatch: Dispatch;
      dialogState: DialogState;
      dialogDispatch: DialogDispatch;
    }
  | undefined
>(undefined);

function userReducer(state: State, action: Action) {
  switch (action.type) {
    case "login": {
      state.refetch();
      return { ...state, isLoading: true };
    }
    case "success": {
      return { ...state, isLoading: false };
    }
    case "user": {
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        isFetching: false,
      };
    }
    case "initial": {
      return {
        ...state,
        isFetching: action.payload.isFetchin,
        refetch: action.payload.refetch,
      };
    }
    case "logout": {
      return { ...state, user: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function dialogReducer(state: DialogState, action: DialogAction) {
  switch (action.type) {
    case "event": {
      return {
        ...state,
        open: true,
        form: NewEventForm,
        header: action.payload.entity ? (
          <Box fontSize="1.25rem" fontWeight={700}>
            Edit an event - event details
          </Box>
        ) : (
          <Box fontSize="1.25rem" fontWeight={700}>
            Add an event - event details
          </Box>
        ),
        options: {
          timeline: action.payload.timeline,
          entity: action.payload.entity,
        },
        button: { text: "Save event", variant: "form" },
      };
    }
    case "image": {
      return {
        ...state,
        open: true,
        form: NewPictureForm,
        header: action.payload.entity ? (
          <>
            <Box fontSize="1.4rem">Edit picture to this event:</Box>
            <Box fontSize="1.4rem" fontWeight={700}>
              {action.payload.entity_title}
            </Box>
          </>
        ) : (
          <>
            <Box fontSize="1.4rem">Add picture to this event:</Box>
            <Box fontSize="1.4rem" fontWeight={700}>
              {action.payload.entity_title}
            </Box>
          </>
        ),
        service: imagenService.postImage,
        options: {
          event_id: action.payload.event_id,
          entity: action.payload.entity,
        },
        button: {
          text: action.payload.entity ? "Save and repost" : "Save picture",
          variant: "form",
        },
      };
    }
    case "close": {
      return { ...state, open: false, success: "none" };
    }
    case "close_image": {
      return {
        ...state,
        open: state.options.prev_open,
        image: false,
        success: "none",
        options: state.options.preb_options,
        header: state.header,
      };
    }
    case "confirm": {
      return {
        ...state,
        open: true,
        header: (
          <Box
            textAlign={"center"}
            fontWeight={700}
            style={{ marginTop: "70px" }}
          >
            {`Change user rights to ${action.payload.role}?`}
          </Box>
        ),
        button: { variant: "confirm" },
        form: ChangeUserPermissionForm,
        options: action.payload,
      };
    }
    case "preview": {
      return {
        ...state,
        open: false,
        image: true,
        header: state.header,
        options: {
          img_url: action.payload.img_url,
          prev_open: state.open,
          preb_options: state.options,
        },
      };
    }
    case "wallet": {
      return {
        ...state,
        open: true,
        form: WalletForm,
        header: (
          <Box
            fontSize="1.25rem"
            fontWeight={700}
            textAlign="center"
            style={{ letterSpacing: "0.2px", marginBottom: 25, paddingTop: 19 }}
          >
            Connect to walllet
          </Box>
        ),
        button: { variant: null },
      };
    }
    case "timeline": {
      return {
        ...state,
        open: true,
        form: AddNewTimeline,
        header: (
          <Box fontSize="1.4rem" fontWeight={700} textAlign="center">
            Add new timeline
          </Box>
        ),
        button: { text: "Save timeline", variant: "form" },
      };
    }
    case "review": {
      return {
        ...state,
        open: true,
        form: ReviewForm,
        header: (
          <Box fontSize="1.2rem" fontWeight={700}>
            {action.payload.entity.title}
          </Box>
        ),
        button: {
          text: "Accept and add to timeline",
          variant: "review",
          textTransform: "uppercase",
        },
        options: { ...action.payload.entity, timeout: action.payload.timeout },
      };
    }
    case "wait_review": {
      return {
        ...state,
        open: true,
        form: CircularProgress,
        header: (
          <>
            <Box fontSize="1.4rem" fontWeight={700}>
              Review {action.payload.entity.type}
            </Box>
            <Box fontSize="1.2rem">{action.payload.entity.title}</Box>
          </>
        ),
        button: { variant: null },
      };
    }
    case "reject": {
      return {
        ...state,
        open: true,
        form: RejectForm,
        header: (
          <>
            <Box fontSize="1.4rem" fontWeight={700}>
              Rejection reason
            </Box>
            <Box>{state.options.title}</Box>
          </>
        ),
        button: {
          text: "Reject",
          variant: "form",
          textTransform: "capitalize",
        },
        options: {
          ...state.options,
          timeout: action.payload.timeout,
          rejectionReasons: action.payload.rejectionReasons,
        },
      };
    }
    case "contact": {
      return {
        ...state,
        open: true,
        button: { text: "Send", variant: "form" },
        form: ContactUsForm,
        header: <ContactHeader />,
      };
    }
    case "user_contest": {
      return {
        ...state,
        open: true,
        button: { text: "Edit your proposal", variant: "rejected" },
        form: RejectionIssueForm,
        header: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "-45px",
            }}
          >
            <Box fontWeight={700} fontSize={20}>
              {action.payload.entity.entity.title}
            </Box>
            <div
              style={{
                backgroundColor: "#FFA3A3",
                color: "#CE1F1F",
                height: 40,
                width: 163,
                textAlign: "center",
                fontWeight: 700,
                paddingTop: 10,
                marginTop: 20,
              }}
            >
              Rejected
            </div>
          </div>
        ),
        options: action.payload.entity,
      };
    }
    case "success": {
      return {
        ...state,
        open: true,
        form: null,
        header: null,
        success: action.payload.succes_type,
        button: { variant: null },
      };
    }
    case "report": {
      return {
        ...state,
        open: true,
        form: ReportErrorForm,
        header: (
          <Box
            fontSize="1.4rem"
            fontWeight={700}
            style={{ textTransform: "none" }}
          >
            Report error
          </Box>
        ),
        button: { text: "Report problem", variant: "form" },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function UserProvider({ children }: PropsWithChildren<any>) {
  const user = localStorage.getItem("user");

  const [state, dispatch] = React.useReducer(userReducer, {
    isFetching: false,
    user: user !== null ? JSON.parse(user) : null,
    isLoading: false,
    refetch: () => new Promise((_) => console.log(1)),
  });

  const { refetch, isFetching } = useQuery(
    "user",
    authenticationService.getLoggedUser,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      retry: false,
      onError: (_) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        dispatch({ type: "user", payload: { user: null } });
      },
      onSuccess: (data) => {
        console.log("asd");
        localStorage.setItem("user", JSON.stringify(data));
        dispatch({ type: "user", payload: { user: data } });
      },
    }
  );

  const { refetch: fetch_settings } = useQuery(
    "settings",
    userService.getSettings,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      retry: false,
      onSuccess: (data: any[]) => {
        const settings = {};
        for (const elem of data) {
          // @ts-ignore
          settings[elem.name] = elem.value;
        }
        localStorage.setItem("settings", JSON.stringify(settings));
      },
    }
  );

  const [dialogState, dialogDispatch] = React.useReducer(dialogReducer, {
    open: false,
    form: null,
    header: null,
    success: "none",
    options: null,
    button: { variant: null },
    image: false,
  });

  useEffect(() => {
    dispatch({
      type: "initial",
      payload: { refetch: refetch, isFetchin: isFetching },
    });

    if (localStorage.getItem("settings") === null) {
      fetch_settings();
    }
  }, []);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch, dialogState, dialogDispatch };
  return (
    <UserContext.Provider value={value}>
      <>
        {children}
        <ActionDialog dispatch={dialogDispatch} state={dialogState} />
      </>
    </UserContext.Provider>
  );
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

export { UserProvider, useUser };
