import HookForm from "../HookForm";
import {useUser} from "../../userContext";
import {ControlledCheck} from "./withControl";
import {Box} from "@material-ui/core";
import {theme} from "../../theme";
import {userService} from "../../_services/user.services";
import {useMutation} from "react-query";

export default function ChangeUserPermissionForm() {
    const {dialogState, dialogDispatch} = useUser();

    const text = "Sees proposed events and nfts in his feed \nCan filter his feed based on timelines\n" +
        "Approves images and events\nAsks for corrections on images and events \n" +
        "Can reject images and events";
    const items = text.split('\n');

    const {mutateAsync} = useMutation(userService.setRole)

    function submit() {
        mutateAsync(dialogState.options).then(
            dialogState.options.onSuccess()
        )
        dialogDispatch({type: "close"})
    }

    return (
        <div style={{padding:'8%'}}>
            <Box fontWeight={700}>
                By changing this user rights the user will be able to:
            </Box>

            <Box marginTop={theme.spacing(1)} marginBottom={theme.spacing(1)} >
                {items.map((value, index) =>
                <ul key={index} style={{paddingLeft:'20px'}}><li>{value}</li></ul>
                )}
            </Box>


            <HookForm onSubmit={() => submit()}>
                {control =>
                    <ControlledCheck name={'asd'} rules={{required: 'hey!'}} control={control}
                    label={'I take responsability for user actions and I agree with the ones above'}
                    />
                }
            </HookForm>
        </div>
    )
}