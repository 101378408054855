import { Box, Button, SvgIcon, TextField } from "@material-ui/core";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useUser } from "../userContext";
import { userService } from "../_services/user.services";
import { ReactComponent as DeleteRow } from "../_icons/x-circle.svg";
import { v4 as uuidv4 } from "uuid";

function Label({ children }: PropsWithChildren<any>) {
  return (
    <Box flexDirection="column" display="flex" justifyContent="center">
      {children}
    </Box>
  );
}

export default function Settings() {
  const settings = useRef<any>(localStorage.getItem("settings"));
  const { data, isFetched, refetch } = useQuery(
    "rejection_reasons",
    userService.getRejectionReasons
  );

  const rejectionReasons = data;

  const [state, setState] = useState(10);
  const [rejectionReasonsState, setRejectionReasonsState] =
    useState(rejectionReasons);

  const [modified, setModified] = useState(1);
  const [loading, setLoading] = useState(false);

  const mutateAsyncConfig = useMutation(userService.config).mutateAsync;
  const mutateAsyncDeleteRejectionReason = useMutation(
    userService.deleteRejectionReasons
  ).mutateAsync;
  const mutateAsyncStoreRejectionReasons = useMutation(
    userService.storeRejectionReasons
  ).mutateAsync;
  const { dialogDispatch } = useUser();

  useEffect(() => {
    if (settings.current == null) return;
    settings.current = JSON.parse(settings.current);
    setState(settings.current.timeout / 60);
  }, []);
  useEffect(() => {
    if (isFetched) {
      setLoading(true);
    }
  }, [isFetched]);

  useEffect(() => {
    if (rejectionReasons) {
      if (loading) {
        return;
      }

      setRejectionReasonsState(rejectionReasons);
      setLoading(true);
    }
  }, [rejectionReasons]);

  function handleChange(e: any) {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      setState(onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      setState(number);
    }
  }

  function handleRejectionChange(e: any) {
    if (!rejectionReasonsState) {
      return;
    }
    const value = e.target.value;
    let newReasons = [...rejectionReasonsState];
    if (!newReasons) {
      return;
    }

    let reason = newReasons.find((reason: any) => reason.id == e.target.id);

    if (reason) {
      //@ts-ignore
      reason.text = value;
      //@ts-ignore
      reason.modified = true;
      setRejectionReasonsState(newReasons);
    }
  }

  function createNewRow() {
    if (!rejectionReasonsState) {
      return;
    }
    const newRejectionReasonsState = rejectionReasonsState;
    const reasonLenght = Object.keys(rejectionReasonsState).length;
    const newReason = { id: uuidv4(), text: "", new: true };
    //@ts-ignore
    newRejectionReasonsState[reasonLenght] = newReason;
    setRejectionReasonsState(newRejectionReasonsState);
    setModified(modified * -1);
  }

  function submit() {
    mutateAsyncConfig({ timeout: state }).then(() =>
      dialogDispatch({
        type: "success",
        payload: { succes_type: "meta_change" },
      })
    );
    settings.current.timeout = state * 60;
    localStorage.setItem("settings", JSON.stringify(settings.current));
  }

  function submitReasons() {
    if (!rejectionReasonsState) {
      return;
    }
    const dataToSend = rejectionReasonsState;
    dataToSend.forEach((reason: any) => {
      if (!reason.modified) {
        reason.modified = false;
      }
      if (!reason.new) {
        reason.new = false;
      }
    });
    mutateAsyncStoreRejectionReasons({ data: rejectionReasonsState }).then(
      () => {
        dialogDispatch({
          type: "success",
          payload: { succes_type: "meta_change" },
        });
        setLoading(false);
        refetch();
        setModified(modified * -1);
      }
    );
  }

  function deleteReason(id: number) {
    if (
      rejectionReasonsState.find(
        (reason: any) => reason.id === id && reason.new === true
      )
    ) {
      let newRejectionReasonsState = [...rejectionReasonsState];

      newRejectionReasonsState = newRejectionReasonsState.filter(
        (reason: any) => reason.id !== id
      );

      setTimeout(() => {
        setRejectionReasonsState([]);
      }, 100);
      setTimeout(() => {
        setRejectionReasonsState(newRejectionReasonsState);
        setModified(modified * -1);
      }, 100);
    } else {
      mutateAsyncDeleteRejectionReason({ id: id }).then(() => {
        dialogDispatch({
          type: "success",
          payload: { succes_type: "meta_change" },
        });

        setLoading(false);
        refetch();
        setRejectionReasonsState([]);
        setModified(modified * -1);
      });
    }
  }

  return (
    <Box flexDirection="column" display="flex" justifyContent="center">
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="center"
        marginLeft="24px"
        marginRight="24px"
        marginTop="35px"
      >
        <Label>
          <b style={{ fontSize: 24 }}>Timeout Duration Settings</b>
        </Label>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="35px"
        >
          <Box flexDirection="row" display="flex" justifyContent="center">
            <TextField
              inputProps={{ inputmode: "numeric", pattern: "[0-9]*" }}
              variant="outlined"
              id="minutes"
              required
              defaultValue={localStorage.get}
              value={state}
              onChange={handleChange}
              style={{
                marginRight: 10,
                marginBottom: 20,
              }}
            />
            <Label>minutes</Label>
          </Box>
        </Box>
        <Box>
          <Button variant="contained" color="secondary" onClick={submit}>
            Save changes
          </Button>
        </Box>
      </Box>
      <Box
        flexDirection="column"
        display="flex"
        justifyContent="center"
        marginLeft="24px"
        marginRight="24px"
        marginTop="35px"
      >
        <Label>
          <b style={{ fontSize: 24 }}>Post Rejection Settings</b>
        </Label>
        <Label>
          <b style={{ fontSize: 12, marginTop: 16 }}>Reason for rejection</b>
        </Label>
        {loading ? (
          rejectionReasonsState ? (
            rejectionReasonsState.map((reason: any) => {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Box
                    flexDirection="row"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom="20px"
                    width="50%"
                  >
                    <TextField
                      inputProps={{ inputmode: "text", pattern: "[0-9]*" }}
                      variant="outlined"
                      required
                      onChange={handleRejectionChange}
                      id={reason.id}
                      fullWidth
                      defaultValue={reason.text}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Box
                      onClick={() => {
                        deleteReason(reason.id);
                      }}
                    >
                      <SvgIcon viewBox="0 0 20 20">
                        <DeleteRow />
                      </SvgIcon>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : null
        ) : (
          <h1>Loading...</h1>
        )}

        <Box
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 32,
          }}
        >
          <Button variant="contained" color="secondary" onClick={createNewRow}>
            Add row
          </Button>
        </Box>

        <Box>
          <Button variant="contained" color="secondary" onClick={submitReasons}>
            Save changes
          </Button>
        </Box>
        <span style={{ display: "none" }}>{modified}</span>
      </Box>
    </Box>
  );
}
