import {Box, Container, IconButton, InputBase, Select, Tab, Tabs, Toolbar} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {classNameMap} from "../components/StatusButton";
import {TabPanel} from "../components/TabComponent";
import withInfinite from "../components/Lists/InfiiniteScroll";
import DashboardListItem from "../components/Lists/DashboardListItem";
import {entityService} from "../_services/entity.services";
import withRedirect from "../components/UserRoles/RedirectingPages";
import SpreadWithMiddle from "../components/SpreadWithMiddle";
import AppBar from "@material-ui/core/AppBar";
import {RightAppBar} from "../App";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useHistory} from "react-router-dom";
import {ReactComponent as Logo} from "../_icons/histo_logo_dash.svg";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import {userService} from "../_services/user.services";
import {useQueryClient} from "react-query";
import MenuItem from '@material-ui/core/MenuItem';

export interface DashboardData {
    type: string
    img_url: string
    date: Date
    user?: string
    curators?: string[]
    status?: classNameMap
    title: string
    event_title?: string
    review_start?: Date
}

const useStyles = makeStyles(() =>
    createStyles({
        hideScroll: {
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            scrollbarWidth: 'none',
        }
    }));

function Dashboard() {
    const [value, setValue] = useState(0);
    const [filter, setFilter] = useState<string>("all");
    const  classes = useStyles();
    const queryClient = useQueryClient()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handlefilter = (event: React.ChangeEvent<{ value: unknown }>) => {
        setFilter(event.target.value as string);
    };

    useEffect(() => {
        userService.realTime.channel('in-progress')
            .listen('StartReview', (e: object) => {
                queryClient.invalidateQueries(['not_curated', filter])
                queryClient.invalidateQueries(['in_progress', filter])
            })
        return () => {
            userService.realTime.leave('in-progress')
        }
    },[])

    const NotCurated = withInfinite(DashboardListItem, ['not_curated', filter], entityService.getNoReview);
    const Curated = withInfinite(DashboardListItem, ['curated', filter], entityService.getCurated);
    const InProgress = withInfinite(DashboardListItem, ['in_progress', filter], entityService.getProgress);

    const tabs = [<NotCurated/>, <Curated/>, <InProgress/>]

    const history = useHistory()

    const BootstrapInput = withStyles((theme) => ({
        input: {
            borderRadius: 2,
            position: 'relative',
            backgroundColor: 'black',
            border: '1px solid #666666',
            color: '#666666',
            fontSize: 14,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            fontFamily: [
                'Space Grotesk',
            ].join(','),
            '&:focus': {
                borderRadius: 2,
                width: "154px"
            },
        },

    }))(InputBase);

    function goBack() {
        history.push('/')
    }

    return (
        <Container style={{ height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <AppBar position="fixed" color='transparent' elevation={0}>
                <SpreadWithMiddle padding
                                  Left={
                                      classname =>
                                          <Box className={classname} onClick={goBack}>
                                              <Logo/>
                                          </Box>
                                  }

                                  Right={classname =>
                                      <Box className={classname}>
                                          <RightAppBar/>
                                      </Box>
                                  }/>
            </AppBar>
            <Toolbar />

            <Box fontSize='1.35rem'
                 marginTop='70px'
                 fontWeight={700}
                 marginBottom='10px'
            >
                <IconButton style={{backgroundColor: '#3F3F3F', width: 24, height: 24, borderRadius: 5, marginRight:10,
                }} onClick={goBack}>
                    <ArrowBackIcon style={{color: 'white'}}/>
                </IconButton>
                Dashboard
            </Box>
            <Box color='white' style={{backgroundColor: "black"}} >

                <Box display='flex'
                     flexDirection='row'
                     justifyContent='space-between'
                     marginRight='32px'
                     marginLeft='33px'
                >

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{style: {
                                background:'#F54768',
                                height: 5,
                                fontWeight: 700
                            }}}
                    >
                        {['Not Curated', 'Curated', 'In Progress'].map((label, index) =>
                            <Tab style={{fontWeight: 400, color: 'white', textTransform: 'capitalize', fontSize: 16, letterSpacing: 0.2, padding: '10px 12px', minHeight: '64px'}} label={label} key={index}/>
                        )}
                    </Tabs>


                    <Box style={{marginTop:20, marginLeft:500}}>
                        Show:
                    </Box>
                    <Box>
                        <Select
                            native
                            variant="outlined"
                            labelId="label"
                            style={{
                                color: 'white',
                                borderColor: 'white',
                                marginLeft: 5,
                                marginTop: 10,
                                marginBottom: 5,
                                width:154
                            }}
                            value={filter}
                            onChange={handlefilter}
                            input={<BootstrapInput/>}
                        >
                            <option value={'events'}>Events</option>
                            <option value={'images'}>Nfts</option>
                            <option value={'all'}>All</option>
                        </Select>
                    </Box>

                </Box>
            </Box>

            {tabs.map((value1, index) =>
                <TabPanel index={index}
                          value={value}
                          key={`tab${index}`}
                          style={{
                              backgroundColor: '#F1F1F2',
                              paddingRight: 33,
                              paddingLeft: 24,
                              marginBottom: 10,

                          }}
                          {...{className: classes.hideScroll }}
                >
                    {value1}

                </TabPanel>
            )}

        </Container>
    )
}



function userNotAllowed(user: any) {
    return user === null || user.roles[0].name ==='user'
}


export default withRedirect(Dashboard, userNotAllowed ,'/not_allowed');