import HookForm from "../HookForm";
import {
  ControlledInput,
  ControlledPicker,
  ControlledSelect,
  ICOntrolledInputProps,
} from "./withControl";
import { useUser } from "../../userContext";
import { eventService } from "../../_services/event.services";
import { useMutation } from "react-query";

export default function NewEventForm() {
  const {
    dialogState: { options },
    dialogDispatch,
  } = useUser();
  let defaultValue = {};
  const selectedVals: number[] = [];

  if (options.entity)
    defaultValue = {
      title: options.entity.title,
      description: options.entity.description,
      source: options.entity.source,
      date: options.entity.date,
    };

  if (options.entity) {
    for (let i = 0; i < options.timeline.length; i++) {
      if (
        options.entity.timelines_id.find(
          (element: { id: number }) => element.id === options.timeline[i].id
        ) !== undefined
      )
        selectedVals.push(i);
    }
  }

  const fields: ICOntrolledInputProps[] & Record<string, any> = [
    {
      name: "title",
      rules: {
        required: "Title required",
        maxLength: {
          value: 60,
          message: "Title must contain maximum 60 chars",
        },
      },
      Component: ControlledInput,
      label: "Event title",
      additional: 60,
    },
    {
      name: "description",
      rules: {
        required: "Description required",
        maxLength: {
          value: 2000,
          message: "Description must be at most 2000 chars long",
        },
      },
      multiline: true,
      rows: 3,
      Component: ControlledInput,
      label: "Description",
      additional: 2000,
      marginTop: 29,
    },
    {
      name: "source",
      rules: {
        required: "Link required",
        pattern: {
          value:
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
          message: "Invalid link format",
        },
      },
      Component: ControlledInput,
      label: "Source link",
      marginTop: 26,
    },
    {
      name: "date",
      rules: {
        validate: (value: any) => {
          return !isNaN(value.getTime()) || "invalid";
        },
      },
      Component: ControlledPicker,
      label: "Date and time",
      marginTop: 47,
      defaultVal: defaultValue,
    },
    {
      name: "timelines",
      rules: {
        required: "Link required",
        maxLength: {
          value: 2000,
          message: "Description must be at most 2000 chars long",
        },
      },
      Component: ControlledSelect,
      label: "Event Timeline",
      timelines: options.timeline,
      defaultVal: selectedVals,
      marginTop: 24,
      marginBottom: 32,
    },
  ];

  const { mutate } = useMutation((variables) =>
    eventService.postEvent(variables)
  );

  return (
    <HookForm
      defaultValue={defaultValue}
      onSubmit={(data) => {
        data.notif_id = options.entity?.notif_id;
        console.log(data);
        data.date.setHours(16, 0, 0);
        mutate(data);
        console.log(data);

        dialogDispatch({ type: "success", payload: { succes_type: "event" } });
      }}
    >
      {(control, setValue) => (
        <>
          {fields.map(({ Component, ...value }, index) => (
            <Component
              {...value}
              control={control}
              setValue={setValue}
              style={{ background: "#fff" }}
            />
          ))}
        </>
      )}
    </HookForm>
  );
}
