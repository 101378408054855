import React from "react";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export default function ErrorIndicator({error, resetErrorBoundary }: any) {
    const {message, isError} = error
    console.log(error, resetErrorBoundary)

    function close() {
        resetErrorBoundary()
    }

    return (
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={isError} onClose={close} autoHideDuration={6000}>
                <Alert onClose={close} severity="error">
                    {message}
                </Alert>
            </Snackbar>
    )
}