import React, { JSXElementConstructor, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import usePreview from "../../hooks/usePreview";
import { Timeline } from "../../_services/timeline.services";
import axios from "axios";
import { values } from "pusher-js/types/src/core/utils/collections";

interface Iprops extends Record<string, any> {
  control?: any;
  name: string;
  rules: any;
  defaultValue?: any;
}

export interface ICOntrolledInputProps {
  control?: any;
  label?: string;
  name: string;
  placeholder?: string;
  rules: any;
  type?: string;
  Component: JSXElementConstructor<any>;
  hint?: string;
}

export default function withControl(
  WrappedComponent: JSXElementConstructor<any>
) {
  return function RedirectComponent({ control, name, rules, ...rest }: Iprops) {
    return (
      <>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            // <>
            <WrappedComponent
              error={!!error}
              value={value}
              onChange={onChange}
              helperText={error ? error.message : null}
              {...rest}
            />
          )}
          rules={rules}
        />
      </>
    );
  };
}

function withLabel(WrappedComponent: JSXElementConstructor<any>) {
  return function RedirectComponent({
    label,
    additional,
    marginTop,
    ...rest
  }: Iprops) {
    return (
      <>
        <Grid
          container
          justify="space-between"
          style={{ marginTop: label == "Date and time" ? 30 : marginTop }}
        >
          <Grid
            item
            style={{
              marginBottom:
                label == "Date and time"
                  ? -10
                  : label == "Event Timeline"
                  ? 8
                  : 0,
            }}
          >
            <Box fontWeight={700}>{label}</Box>
          </Grid>
          <Grid item>
            <Box fontWeight={700} color="#CFCFCF">
              {additional}
            </Box>
          </Grid>
        </Grid>
        <WrappedComponent {...rest} />
      </>
    );
  };
}

function withDynamicLabel(WrappedComponent: JSXElementConstructor<any>) {
  return function RedirectComponent({
    label,
    value,
    additional,
    marginTop,
    ...rest
  }: Iprops) {
    return (
      <>
        <Grid
          container
          justify="space-between"
          style={{ marginTop: marginTop, marginBottom: 8 }}
        >
          <Grid item>
            <Box
              fontWeight={700}
              height={15}
              style={{
                marginBottom: "2px",
                letterSpacing: "0.2px",
                color: "black",
              }}
            >
              {label}
            </Box>
          </Grid>
          {additional && (
            <Grid item>
              <Box fontWeight={700} color="#CFCFCF">
                {`${additional - (value?.length || 0)} charcters`}
              </Box>
            </Grid>
          )}
        </Grid>
        <WrappedComponent {...rest} value={value} maxLength={additional} />
      </>
    );
  };
}

function MyInput({ maxLength, ...rest }: Record<string, any>) {
  return (
    <TextField
      size="small"
      variant="outlined"
      inputProps={{ maxLength: maxLength }}
      hiddenLabel
      fullWidth
      {...rest}
    />
  );
}

function Picker({
  name,
  defaultVal,
  setValue,
  control,
  rules,
  ...rest
}: Record<string, any>) {
  useEffect(() => {
    setValue("date", new Date(defaultVal.date ? defaultVal.date : new Date()));
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <KeyboardDatePicker
            autoOk
            margin="normal"
            fullWidth
            refuse={/[^a-zA-Z0-9]+/gi}
            mask="__ ___ ____"
            format="dd MMM yyyy"
            inputVariant="outlined"
            value={value}
            onChange={onChange}
            {...rest}
          />
        )}
        name={name}
        control={control}
        rules={rules}
      />
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      backgroundColor: "black",
      color: "white",
      borderRadius: 2,
    },
    selected: {
      background: "#000000 !important",
      color: "white ",
    },
    selectItem: {
      border: "1px solid #484850",
      borderRadius: "2px",
      display: "inline-flex",
      alignSelf: "flex-start",
      flexWrap: "wrap",
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(3),
    },
  })
);

function RejectionReasonSelect({
  onChange,
  reasons,
  ...rest
}: Record<string, any>) {
  console.log("Reasons:", reasons);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const value = event.target.value as number;
    console.log(value);

    updateForm(value);
    closeSelect();
  };
  function updateForm(value: number) {
    onChange(value);
  }
  const openSelect = () => {
    setOpen(true);
  };

  const closeSelect = () => {
    setOpen(false);
  };
  console.log(reasons);
  return (
    <Select
      variant="outlined"
      fullWidth
      open={open}
      onOpen={openSelect}
      onChange={handleChange}
      onSelect={closeSelect}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            width: 100,
            flexWrap: "wrap",
          },
        },
      }}
      {...rest}
        style={{textAlign:"left"}}
    >
      {reasons.map((reason: any, index: number) => (
        <MenuItem key={index} value={reason.text} onClick={closeSelect}>
          {reason.text}
        </MenuItem>
      ))}
    </Select>
  );
}

function TimelineSelect({
  onChange,
  value,
  timelines,
  defaultVal,
  ...rest
}: Record<string, any>) {
  const classes = useStyles();

  const [personName, setPersonName] = React.useState<number[]>(defaultVal);
  const [open, setOpen] = React.useState(false);

  function updateForm(values: number[]) {
    const ret_timelines = Array.from(values);
    ret_timelines.forEach(
      (value1, index, array) => (array[index] = timelines[value1].id)
    );

    onChange(ret_timelines);
  }

  useEffect(() => {
    updateForm(defaultVal);
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const values = event.target.value as number[];

    if (values[values.length - 1] === undefined) return;

    setPersonName(values);
    updateForm(values);
  };

  const openSelect = () => {
    setOpen(true);
  };

  const closeSelect = () => {
    setOpen(false);
  };

  return (
    <Select
      variant="outlined"
      style={{ marginBottom: rest["marginBottom"] }}
      open={open}
      multiple
      value={personName}
      onOpen={openSelect}
      onChange={handleChange}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {(selected as number[]).map((value, index) => (
            <Chip
              onMouseDown={(event) => event.stopPropagation()}
              key={index}
              label={timelines[value].name}
              className={classes.chip}
              color="primary"
              onDelete={() => {
                const remaining = personName.filter((entry) => entry !== value);
                setPersonName(remaining);
                updateForm(remaining);
              }}
            />
          ))}
        </div>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            width: 100,
            flexWrap: "wrap",
          },
        },
      }}
      {...rest}
      fullWidth
    >
      {timelines.map((timeline: Timeline, index: number) => (
        <MenuItem
          dense
          classes={{ selected: classes.selected }}
          key={index}
          value={index}
          className={classes.selectItem}
        >
          {timeline.name}
        </MenuItem>
      ))}
      <Grid container justify="center" className={classes.button}>
        <Button color="primary" variant="contained" onClick={closeSelect}>
          Add
        </Button>
      </Grid>
    </Select>
  );
}

function BrowseFile({ setValue, defaultVal }: Record<string, any>) {
  const {
    url,
    meta,
    handleUploadClick,
    handleDelete,
    setMeta,
    human_readable,
  } = usePreview(defaultVal);
  const index = meta !== null ? meta.name.lastIndexOf(".") : 0;

  const handleUpload = (e: any) => {
    setValue("picture", e.target.files[0]);
    handleUploadClick(e);
  };

  useEffect(() => {
    if (defaultVal === undefined) return;
    axios({
      url: defaultVal, //your url
      method: "GET",
      responseType: "blob",
    }).then((value) => {
      const file = new File([value.data], "file");
      setValue("picture", file);
      setMeta({ size: human_readable(file.size), name: file.name });
    });
  }, []);

  const thisHandleDelete = () => {
    setValue("picture", {});

    handleDelete();
  };

  return (
    <Grid
      item
      container
      justify="space-between"
      alignContent={"space-between"}
      alignItems={"baseline"}
    >
      <Grid item xs={4} style={{ paddingTop: "3%" }}>
        <Button
          variant="outlined"
          component="label"
          style={{
            fontSize: 13,
            textTransform: "capitalize",
            width: 160,
            height: 44,
            color: "#858585",
          }}
        >
          Browse Files
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={handleUpload}
            hidden
          />
        </Button>
      </Grid>

      <Grid item xs={4}>
        {url != null && (
          <Chip
            style={{
              maxWidth: "100%",
              background: "white",
              marginRight: "-100px",
            }}
            avatar={<Avatar alt="Natacha" src={url} />}
            label={
              meta != null && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    {meta.name.substring(0, index)}
                  </span>
                  <span>{meta.name.substring(index)}</span>
                </div>
              )
            }
          />
        )}
      </Grid>

      <Grid item container xs={4} justify="flex-end">
        {meta != null && (
          <Chip
            style={{ maxWidth: "100%", background: "white" }}
            label={meta.size}
            onDelete={thisHandleDelete}
            deleteIcon={
              <SvgIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#FF7979"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#FF7979"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#FF7979"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </SvgIcon>
            }
          />
        )}
      </Grid>
    </Grid>
  );
}

function MyCheckbox({ value, onChange, label }: Record<string, any>) {
  return (
    <FormControlLabel
      control={<Checkbox checked={value} onChange={onChange} color="default" />}
      label={label}
    />
  );
}

export const ControlledInput = withControl(withDynamicLabel(MyInput));
export const NoLabelControlledInput = withControl(MyInput);
export const ControlledSelect = withControl(withLabel(TimelineSelect));
export const ControlledImage = withControl(withLabel(BrowseFile));
export const ControlledCheck = withControl(MyCheckbox);
export const ControlledPicker = withLabel(Picker);
export const ControlledSelectRejectionReasons = withControl(
  withLabel(RejectionReasonSelect)
);
