import {Control, useForm} from "react-hook-form";
import React, {PropsWithChildren, ReactElement} from "react";

interface Props<T> {
    onSubmit: (data: T) => void,
    onReset?: (data: T) => void,

    children: (control: Control, setValue?: any, watch?: any) => ReactElement
    defaultValue?: any
}

export default function HookForm({onSubmit, onReset, defaultValue, children}: PropsWithChildren<Props<any>>) {
    const { control, handleSubmit, setValue, watch } = useForm({defaultValues: defaultValue });

    return (
        <form id='myform' onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            {children(control, setValue, watch)}
        </form>
    )

}
