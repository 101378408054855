import AboutSection from "../components/AboutSection";
import { Box, CircularProgress, Grid, Paper, Toolbar } from "@material-ui/core";
import TabComponent from "../components/TabComponent";
import RightsManagement from "../components/Lists/RightsManagement";
import EventsList from "../components/Lists/EventsList";
import BigContainer from "../components/BigContainer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { theme } from "../theme";
import { useUser } from "../userContext";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { userService } from "../_services/user.services";
import withInfinite from "../components/Lists/InfiiniteScroll";
import React, { ReactNode, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { ReactComponent as Logo } from "../_icons/histo_logo_dash.svg";
import Settings from "../components/Settings";

const useStyles = makeStyles(
  createStyles({
    paper: {
      height: "100%",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
    },
    growSection: {
      height: `calc(100vh - ${
        theme.spacing(1) + theme.spacing(3)
      }px - 2rem - 10px
                                - 65px)`,
    },
  })
);

export type roles = "user" | "admin" | "curator";
type bool = "true" | "false";

const tabsLabel = {
  user: {
    true: ["Posted content"],
    false: ["Posted content"],
  },
  curator: {
    true: ["Curated content", "Posted events and nfts"],
    false: ["Curated content", "Posted events and nfts"],
  },
  admin: {
    true: ["Posted Content"],
    false: ["Posted Content"],
  },
};

interface tabsType {
  tabs: string[];
  tabsComponents: ReactNode[];
}

export default function ProfilePage() {
  const classes = useStyles();
  const {
    state: { user },
  } = useUser();
  const { id } = useParams<{ id: string }>();

  const [tabs, setTabs] = useState<tabsType>({ tabs: [], tabsComponents: [] });

  const { isLoading } = useQuery(
    ["user_profile", { id: id }],
    userService.getUSerProfile,
    {
      onSuccess: (user_profile) => {
        const EntititesList = withInfinite(
          EventsList,
          ["posted_content", { id: id }],
          userService.getPostedContent
        );

        const tabs =
          tabsLabel[user_profile.roles[0].name as roles][
            (
              user != null && user_profile.id === parseInt(user.id)
            ).toString() as bool
          ];
        const tabsComponents = [<EntititesList />];

        const role = user_profile.roles[0].name;
        if (role === "curator") {
          const Curated = withInfinite(
            EventsList,
            ["curated_content", { id: id }],
            userService.getCuratedContent
          );
          tabsComponents.unshift(<Curated />);
        }

        setTabs({ tabs: tabs, tabsComponents: tabsComponents });
      },
    }
  );

  const history = useHistory();

  function goHome() {
    history.push("/");
  }

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <BigContainer>
      <AppBar position="fixed" color="transparent" elevation={0}>
        <Box marginLeft="15px" marginTop="10px">
          <Logo onClick={goHome} />
        </Box>
      </AppBar>

      <Toolbar />

      <Grid container spacing={4} justify="space-between">
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <AboutSection />
          </Paper>
        </Grid>

        <Grid item xs={9} className={classes.growSection}>
          <Paper className={classes.paper}>
            <TabComponent itemsLabel={tabs.tabs}>
              {tabs.tabsComponents}
            </TabComponent>
          </Paper>
        </Grid>
      </Grid>
    </BigContainer>
  );
}
