import axios from "axios";
import {parseISO} from "date-fns";

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*Z)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) {
            body[key] = parseISO(value);
        }
        else if (typeof value === "object") handleDates(value);
    }
}

export const apiClient = axios.create({
    // baseURL: 'http://localhost:8000',
    // baseURL: 'http://86.126.210.54:8000',
    baseURL: 'http://api-histography.flowmatters.io/',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': "XHLHttpRequest",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
    }
});


apiClient.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
});

export const authenticationService = {
    login,
    signup,
    logout,
    getLoggedUser,
    reset
};


async function login(data: any) {
    //
    const res = await apiClient.post('/api/login', data);

    return res.data
}

async function signup(data: any) {
    const res = await apiClient.post('/api/register',data);

    return res.data
}

async function getLoggedUser() {
    try {
        const res = await apiClient.get('/api/user',{
            headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}
        });

        return res.data;
    } catch (e) {
        return null
    }
}

async function logout() {
    // remove user from local storage to log user out
    const res = await apiClient.post('/api/logout');
    localStorage.clear();
    window.location.reload();

    return res
}

async function reset(data: any) {
    const res = await apiClient.post('/api/reset', data);

    return res.data
}