import { apiClient } from "./authentication.services";
import { IDetails } from "../pages/DetailsPage";

import Echo from "laravel-echo";

// @ts-ignore
window.Pusher = require("pusher-js");

const realTime = new Echo({
  broadcaster: "pusher",
  key: "my_key",
  // wsHost: 'localhost',
  wsHost: "api-histography.flowmatters.io",
  wsPort: 6001,
  forceTLS: false,
  disableStats: true,
});

export const userService = {
  vote,
  getPostedContent,
  getUSerProfile,
  getAll,
  setRole,
  setProfile,
  changePassword,
  getCuratedContent,
  review,
  start_review,
  getNotifications,
  contest,
  abort_review,
  readNotifications,
  config,
  getSettings,
  getRejectionReasons,
  storeRejectionReasons,
  deleteRejectionReasons,
  realTime,
};

interface IPostProps {
  type: string;
  id: number;
}

async function vote(data: IPostProps) {
  const res = await apiClient.post(`/api/vote`, data);

  return res.data;
}

async function getPostedContent(data: any) {
  const id = data.queryKey[1].id;

  const url =
    data.pageParam === undefined ? `/api/user_content/${id}` : data.pageParam;
  const res = await apiClient.get(url);

  // res.data.data = Object.values(res.data.data)
  return res.data;
}

async function getCuratedContent(data: any) {
  const id = data.queryKey[1].id;

  const url =
    data.pageParam === undefined ? `/api/user_curated/${id}` : data.pageParam;
  const res = await apiClient.get(url);

  return res.data;
}

async function getUSerProfile(data: any) {
  const id = data.queryKey[1].id;
  const res = await apiClient.get(`api/user/${id}`);

  return res.data;
}

async function getAll(data: any) {
  const url = data.pageParam === undefined ? `/api/users` : data.pageParam;

  const res = await apiClient.get(url);

  return res.data;
}

async function setRole(data: IPostProps) {
  const res = await apiClient.put(`/api/user/role`, data);

  return res.data;
}

async function setProfile(data: any): Promise<IDetails> {
  const form = new FormData();

  if (data.avatar) form.append("avatar", data.avatar);
  form.append("name", data.name);
  form.append("description", data.description);

  const res = await apiClient.post(`/api/user`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
}

async function changePassword(data: any) {
  const res = await apiClient.post(`/api/password`, data);

  return res.data;
}

async function review(data: any) {
  const res = await apiClient.post(`/api/review`, data);

  return res.data;
}

async function contest(data: any) {
  const res = await apiClient.post(`/api/contest`, data);

  return res.data;
}

async function start_review(data: any) {
  const res = await apiClient.post(`/api/start`, data);

  return res.data;
}

async function abort_review(data: any) {
  const res = await apiClient.post(`/api/abort`, data);

  return res.data;
}

async function getNotifications() {
  const res = await apiClient.get(`/api/notifications`);

  return res.data;
}

async function readNotifications(data: any) {
  const res = await apiClient.put(`/api/notifications`, data);

  return res.data;
}

async function config(data: any) {
  const res = await apiClient.put(`/api/settings`, data);

  return res.data;
}

async function getSettings() {
  const res = await apiClient.get(`/api/settings`);

  return res.data;
}

async function getRejectionReasons() {
  const res = await apiClient.get(`/api/rejectionReason`);

  return res.data;
}

async function storeRejectionReasons(data: any) {
  const res = await apiClient.post("/api/rejectionReason/storeArray", data);

  return res.data;
}

async function deleteRejectionReasons(data: any) {
  const id = data.id;

  const res = await apiClient.post(`/api/rejectionReason/delete/${id}`);

  return res.data;
}
