import {PropsWithChildren} from "react";
import {useUser} from "../../userContext";
import {useHistory} from "react-router-dom";
import {Button, ButtonProps} from "@material-ui/core";

export function AuthOnlyButton({onClick, children, ...rest}: PropsWithChildren<ButtonProps>) {
    const {state} = useUser()
    const history = useHistory()

    const clickHandler = (e: any) => {
        if (state.user == null) {

            history.push('/login');
            return
        }
        if (onClick) {
            onClick(e)
        }

    }

    return (
        <Button {...rest} onClick={clickHandler}>
            {children}
        </Button>
    )
}