import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import ImageCard from "./ImageCard";
import { RouteParams } from "../pages/DetailsPage";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { eventService } from "../_services/event.services";
import { Fragment, useEffect, useRef } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import SpreadWithMiddle from "./SpreadWithMiddle";
import { useUser } from "../userContext";
import { AuthOnlyButton } from "./UserRoles/AuthOnly";

export default function ImageGrid({
  id,
  image_id = 0,
  event_title,
  event_status,
}: {
  id: number;
  image_id?: number;
  event_title: string;
  event_status: string;
}) {
  const { dialogDispatch } = useUser();
  const { type } = useParams<RouteParams>();

  const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<any>(
      ["entities", id],
      (pageParam) =>
        eventService.getRelatedImages(id, image_id, pageParam.pageParam),
      {
        getNextPageParam: (lastPage) => {
          return lastPage.next_page_url ?? false;
        },
      }
    );

  useEffect(() => {
    refetch();
  }, [id, image_id]);

  const loadMoreButtonRef = useRef<any>();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: !isFetching && hasNextPage,
  });

  const addImage = () => {
    dialogDispatch({
      type: "image",
      payload: { event_id: id, entity_title: event_title },
    });
  };

  return (
    <>
      {event_status === "approved" ? (
        data?.pages[0].data.length === 0 ? (
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Typography
              style={{
                fontSize: 26,
                fontWeight: 700,
                lineHeight: "37px",
                color: "#E4E4E4",
                marginBottom: 25,
              }}
            >
              {type === "event"
                ? "This event has no nfts"
                : null}
            </Typography>
            {type === "event" ? (
              <AuthOnlyButton
                color="secondary"
                variant="contained"
                onClick={addImage}
                style={{
                  fontWeight: 400,
                  width: 243,
                  letterSpacing: "-0.005em",
                  fontSize: 16,
                  textTransform: "none",
                }}
              >
                Add nft to event
              </AuthOnlyButton>
            ) : null}
          </Box>
        ) : (
          <SpreadWithMiddle
            Left={(classes) => (
              <Box
                fontWeight="fontWeightBold"
                className={classes}
                style={{ fontSize: 26, letterSpacing: 0.3, color: "#0A0A0A" }}
              >
                Most voted
              </Box>
            )}
            Middle={
              <Box
                fontWeight="fontWeightBold"
                style={{ fontSize: 26, letterSpacing: 0.3, color: "#0A0A0A" }}
              >
                Other nfts in this event
              </Box>
            }
            Right={(classes) =>
              type === "event" ? (
                <AuthOnlyButton
                  color="secondary"
                  variant="contained"
                  className={classes}
                  onClick={addImage}
                  style={{
                    fontWeight: 400,
                    width: 243,
                    letterSpacing: "-0.005em",
                    fontSize: 16,
                    textTransform: "none",
                  }}
                >
                  Add nft to event
                </AuthOnlyButton>
              ) : null
            }
          />
        )
      ) : null}

      <Grid container spacing={2}>
        {data?.pages?.map((page) => (
          <Fragment key={page.current_page}>
            {page.data?.map((value: any, index: number) => (
              <Grid
                item
                key={index}
                xs={page.current_page === 1 && index === 0 ? 4 : 2}
              >
                <ImageCard
                  type={
                    page.current_page === 1 && index === 0 ? "most" : undefined
                  }
                  title={value.title}
                  votes={value.vote_cnt}
                  imgUrl={value.img_url}
                  user={value.user}
                  voted={value.voted}
                  event_id={id}
                  entity={{
                    type: "image",
                    id: value.id,
                    description: value.description,
                    status: "approved",
                  }}
                />
              </Grid>
            ))}
          </Fragment>
        ))}

        <Grid item container justify={"center"}>
          <Grid item>
            <div ref={loadMoreButtonRef}> </div>
            {isFetching && <CircularProgress />}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
