import React, {JSXElementConstructor, useRef} from "react";
import {Box, CircularProgress, Grid, List, ListItem} from "@material-ui/core";
import {useInfiniteQuery} from "react-query";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

export default function withInfinite(WrappedComponent: JSXElementConstructor<any>, query: any[], fct: any) {
    return function RedirectComponent() {

        const {
            data,
            isLoading,
            isFetching,
            fetchNextPage,
            hasNextPage,
        } = useInfiniteQuery<any>(
            query,
            fct,
            {
                getNextPageParam: lastPage => {
                    return lastPage.next_page_url ?? false
                },
            }
        )

        const loadMoreButtonRef = useRef<any>()

        useIntersectionObserver({
            target: loadMoreButtonRef,
            onIntersect: fetchNextPage,
            enabled: !isFetching && hasNextPage,
        })

        if (isLoading)
            return (
                <Box display='flex' justifyContent='center'>
                    <CircularProgress style={{marginTop: 29}}/>
                </Box>
            )

        return (
            <List>
                <WrappedComponent data={data}/>
                <ListItem>
                    <Grid item container justify={"center"}>
                        <Grid item>
                            <div ref={loadMoreButtonRef}> </div>
                            {isFetching && <CircularProgress/>}
                        </Grid>
                    </Grid>
                </ListItem>
            </List>

        );
    }
}