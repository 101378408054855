import React, { useEffect, useRef, useState } from "react";
import { props } from "./NewTimeline";
import { Box } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SingleTimelineEvent } from "./SingleTimelineEvent";
import useClickOutside from "../ClickOutside";

const useStyles = makeStyles(() =>
  createStyles({
    avatarGroup: {
      flexDirection: "column-reverse",
      overflow: "auto",
      // scrollbarWidth: 'none',
      "&::-webkit-scrollbar": {
        display: "none",
      },
      backgroundColor: "#C8C8C8",
      padding: 10,
      marginLeft: -10,
    },
    hoveredGroupSecond: {
      width: 44,
      height: 44,
      marginLeft: 0,
      borderRadius: 0,
      marginBottom: 16,
    },
    hoveredGroupHorizontal: {
      width: 37,
      height: 37,
      marginRight: 15,
      border: 0,
      borderRadius: 0,
    },
  })
);

export default function AllMultipleEvents({
  events,
  setIsHovered,
}: {
  events: any[];
  setIsHovered: (e: boolean) => void;
}) {
  const classes = useStyles();
  const [maxNr, setMaxNr] = useState(2);
  const [keepOpen, setHeepOpen] = useState(false);

  const boxRef = useRef();

  // useClickOutside((e: any) => {
  //   if (typeof e.target.className === "string") {
  //     console.log(e.target);
  //     if (
  //       !e.target.className.includes("MuiBox-root") &&
  //       !e.target.className.includes("MuiButton") &&
  //       !e.target.className.includes("MuiSvgIcon-root") &&
  //       !e.target.className.includes("MuiAvatarGroup-root") &&
  //       !e.target.className.includes("MuiLink-root")
  //     ) {
  //       setIsHovered(false);
  //     }
  //   } else {
  //     setIsHovered(false);
  //   }
  // }, boxRef);

  function elementLeft() {
    console.log("make true");

    if (keepOpen) {
      //@ts-ignore
      props.options.preferZoom = false;
      return;
    }
    console.log("make true sergiu");
    // @ts-ignore

    // props.options.toggleZoom(true)
    props.options.preferZoom = true;
    setTimeout(() => {
      setIsHovered(false);
    }, 1000);
  }

  function seeAll(event: any) {
    if (
      typeof event.target.className === "string" &&
      !event.target.className.includes("root")
    )
      return;

    console.log("make false sergiu");

    // @ts-ignore
    // props.options.toggleZoom(false)
    props.options.preferZoom = false;
    setMaxNr(events.length);
  }

  useEffect(() => {
    // @ts-ignore
    setMaxNr(Math.floor((boxRef.current.clientHeight - 50) / 60));
  }, []);

  return (
    <Box
      {...{ ref: boxRef }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
      flex={1}
      onMouseEnter={() => setHeepOpen(false)}
      onMouseLeave={elementLeft}
      // style={{background: "red", padding: 30}}
      onWheel={() => console.log("wheel")}
    >
      <AvatarGroup
        classes={{
          root: classes.avatarGroup,
          avatar: classes.hoveredGroupSecond,
        }}
        max={maxNr}
        spacing="medium"
        onClick={seeAll}
        aria-owns="images-popover"
        style={events.length % 2 === 0 ? { marginTop: -50 } : {}}
      >
        {events.map((value, index) => (
          <SingleTimelineEvent
            setHeepOpen={setHeepOpen}
            key={`event-${index}`}
            event={value}
            single={false}
            first={index === events.length - 1 ? true : false}
          />
        ))}
      </AvatarGroup>
    </Box>
  );
}
