import NotificationsIcon from "@material-ui/icons/Notifications";
import {
  Badge,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";
import { userService } from "../../_services/user.services";
import { theme } from "../../theme";
import NotificationItem from "./NotificationItem";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export default function Notification() {
  const { data, refetch } = useQuery<any[], Error>(
    "notifications",
    userService.getNotifications
  );

  const [showAll, setShowAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
    refetch();
    setShowAll(false);
  }

  function handleClose(event: React.MouseEvent<EventTarget>) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
    setShowAll(false);
  }

  return (
    <>
      <IconButton color="inherit" ref={anchorRef} onClick={handleToggle}>
        <Badge
          badgeContent={data?.filter((notif) => notif.read_at === null).length}
          color="secondary"
        >
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          marginTop: theme.spacing(2),
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              background: "#181818",
              color: "white",
            }}
          >
            <Paper
              style={{
                maxHeight: "85vh",
                overflowY: "auto",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {showAll
                    ? data?.map((notif, index) => (
                        <MenuItem
                          key={index}
                          disabled={
                            notif.data.disabled !== null && notif.data.disabled
                            // || (notif.data.status == "rejected" &&
                            //   notif.read_at !== null)
                          }
                          onClick={() => {
                            handleToggle();
                          }}
                        >
                          <NotificationItem
                            notif={notif}
                            notif_type={notif.type}
                            created_at={notif.created_at}
                            {...notif.data}
                          />
                        </MenuItem>
                      ))
                    : data?.slice(0, 10).map((notif, index) => (
                        <MenuItem
                          key={index}
                          disabled={
                            notif.data.disabled !== null && notif.data.disabled
                            // || (notif.data.status == "rejected" &&
                            //   notif.read_at !== null)
                          }
                          onClick={() => {
                            handleToggle();
                          }}
                        >
                          <NotificationItem
                            notif={notif}
                            notif_type={notif.type}
                            created_at={notif.created_at}
                            {...notif.data}
                          />
                        </MenuItem>
                      ))}
                  {showAll ? null : (
                    <MenuItem
                      onClick={() => {
                        setShowAll(true);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Show All
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

// 'seen - #272727'
