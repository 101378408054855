import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {useUser} from "../userContext";


interface IProps {
    src: string
}

const useStyles = makeStyles((theme) => ({
    icon: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3),
        color: '#FFFFFF',
        backgroundColor: 'black'

    },
    img: {
        maxWidth: '100%',
        maxHeight: '95vh'
    },
    imgContainer: {
        top: '50%',
        left: "50%",
        transform: 'translate(-50%, -50%)',
        display: 'inline-block',
        maxWidth: '100%',
        maxHeight: '100%',
        position: 'relative'
    }
}))

export default function ImageModal({src}: IProps ) {
    const classes = useStyles();
    const {dialogDispatch} = useUser();

    const closeModal = () => {
        dialogDispatch({type: "close_image"});
    }

    return (
        <div className={classes.imgContainer} style={{display:'flex',justifyContent:'center'}}>
            <img alt="asd" className={classes.img} src={src}/>
            <CloseIcon className={classes.icon} onClick={closeModal} />

        </div>
    )
}