import HookForm from "../HookForm";
import {Grid} from "@material-ui/core";
import {ControlledInput, ICOntrolledInputProps} from "./withControl";
import {useUser} from "../../userContext";
import {useMutation} from "react-query";
import {timelineService} from "../../_services/timeline.services";

export default function AddNewTimeline() {
    const { dialogDispatch} = useUser();

    const fields: ICOntrolledInputProps[] & Record<string, any> = [
        {
            name: "name",
            rules: {required: 'Title required', maxLength: {value: 60, message: "Title must contain maximum 60 chars"}},
            Component: ControlledInput,
            label: 'Timeline title',
            additional: 60
        }, {
            name: "description",
            rules: {
                required: 'Description required',
                maxLength: {value: 2000, message: "Description must be at most 2000 chars long"}
            },
            multiline: true,
            rows: 3,
            Component: ControlledInput,
            label: 'Description',
            additional: 2000
        },
    ]

    const {mutate} = useMutation(variables => timelineService.postTimeline(variables))


    return (
        <HookForm onSubmit={(data) => {
            mutate(data)
            dialogDispatch({type: 'success', payload: {succes_type: 'new_timeline'}})
        }} >
            {(control, setValue ) =>
                <Grid container direction="column" spacing={2}>
                    {fields.map(({Component, ...value}, index) =>
                        <Grid item key={index}>
                            <Component {...value} control={control} setValue={setValue}/>
                        </Grid>
                    )}
                </Grid>
            }
        </HookForm>
    );
}