import {createMuiTheme} from "@material-ui/core";

export const theme = createMuiTheme({
    typography: {
        h1: {
            fontFamily: 'Spartan',
            fontSize: 30
        },
        button: {
            textTransform: 'none'
        },
        fontFamily: '"Space Grotesk"'
    },
    overrides: {
        MuiSvgIcon: {
            colorAction: {
                color: '#24E3AE'
            },
            root:{
                color:'black',
            },
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#F54768',
                color: 'white',
                borderRadius: '2px',
                letterSpacing: '0.3px',
                "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: "#F54768"
                }
            },
            containedSecondary: {
                backgroundColor: 'black',
                color: 'white',
                "&:hover": {
                    backgroundColor: "black"
                }
            },
            outlinedPrimary: {
                color: '#5458E7',
                letterSpacing: '0.3px',
                borderColor: '#5458E7'
            },
            outlinedSecondary: {
                color: '#F54768',
                borderColor: '#F54768'
            },
            textPrimary:{
                color:'black'
            }
        },
        MuiSelect: {
           select: {
               "&:focus":{
                   backgroundColor:'unset',
                   borderColor:'unset'
               }
           } 
        },
        MuiListItemText:{
            primary:{
                textTransform:'capitalize',
                fontWeight:'bold'
            }
        },
        MuiListItem:{
            root:{
                paddingLeft:'20px!important'
            }
        },
        MuiAvatar:{
            square:{
                borderRadius:'3px'
            },
        },

        MuiOutlinedInput:{
            focused:{
                borderColor:'black'
            },
            multiline:{
                padding:'0px !important',
            },
            marginDense: {
                padding:'0px',
                margin:'0px'
            }
        },

        MuiFilledInput:{
            underline:{
                "&:before":{
                    borderBottom:'unset',
                },
                "&:after":{
                    borderBottom:'unset',
                },
                "&:hover":{
                    "&:before":{
                        borderBottom:'unset',
                    }
                }

            },
            multiline:{
                "&:hover":{
                    borderBottom:'unset',
                }
            }
        },
        // MuiLink:{
        //     underlineHover:{
        //         "&:hover":{
        //             textDecoration:'unset',
        //         }
        //     }
        // },
        MuiDialog:{
            paper:{
                minWidth:'400px'
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                color: '#ffffff',
                '&$checked': {
                    color: '#ffffff',
                },
            },
        },
        MuiBadge:{
            colorSecondary:{
                backgroundColor:'#38E573'
            }
        }
        // MuiOutlinedInput: {
        //     focused: {
        //         border: '1px solid #'
        //     },
        //
        // },
    }
})