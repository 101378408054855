import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import Timeline from "react-vis-timeline";
import { Box, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { QueryClientProvider, useQuery } from "react-query";
import { eventService } from "../../_services/event.services";
import "./NewTimeline.css";
import { SingleTimelineEvent } from "./SingleTimelineEvent";
import { useHistory } from "react-router-dom";
import { useUser, UserProvider } from "../../userContext";
import { theme } from "../../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { queryClient } from "../../index";
import { UnselectedMultipleEvents } from "./UnselectedMultipleEvents";

function createItem(
  id,
  groupId,
  startTime,
  content,
  timeline,
  history,
  dispatch
) {
  return {
    id: id,
    group: groupId,
    content: content,
    start: startTime.hour(0),
    history: history,
    dispatch: dispatch,
    timeline: timeline,
    type: "box",
  };
}

export const props = {
  initialGroups: [{ id: 1, content: "" }],
  initialItems: [],
  options: {
    width: "100%",
    height: "82vh",
    autoResize: true,
    stack: false, // false == overlap items
    verticalScroll: false,
    selectable: false,
    showCurrentTime: false,
    orientation: { axis: "bottom" },
    showWeekScale: true,
    zoomMax: 31536000000,
    zoomMin: 3600000 * 24 * 6,
    start: moment("2021-01-01"),
    zoomFriction: 20,
    margin: { item: { vertical: 0, horizontal: 10 } },
    max: moment("2025-01-01"),
    min: moment("2018-01-01"),
    loadingScreenTemplate: function () {
      return "<h1>Loading...</h1>";
    },
    margin: 0,
    // zoomable: false,
    // zoomMin: 1000 * 60 * 60 * 24 * 31 / 6  ,
    // zoomMax: 1000 * 60 * 60 * 24 * 31 * 12 * 12,
    template: function (item, element, data) {
      if (!item) {
        return;
      }

      if (item.items !== undefined) {
        return ReactDOM.createPortal(
          ReactDOM.render(
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <UnselectedMultipleEvents events={item.items} />
                </UserProvider>
              </QueryClientProvider>
            </ThemeProvider>,
            element
          ),
          element
          // () => {
          //     window.timeline.redraw();
          // }
        );
      }

      return ReactDOM.createPortal(
        ReactDOM.render(
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <SingleTimelineEvent event={item} />
              </UserProvider>
            </QueryClientProvider>
          </ThemeProvider>,
          element
        ),
        element
        // () => {
        //     window.timeline.redraw();
        // }
      );
    },
    cluster: true,
  },
};

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const zoomScales = [1, 7 * 12 * 0.5, 31 * 12 * 0.5, 365 * 12];
const zoomScaleReadable = ["day", "week", "month", "year"];

function getIndex(index, direction) {
  if (index === -404) index = 2;
  else index -= Math.sign(direction);

  const abort = index < 0 || index === zoomScales.length;

  return [index, abort];
}

const UNGROUPED = "__ungrouped__"; // reserved group id for ungrouped items
const BACKGROUND = "__background__"; // reserved group id for background items without group

export const ReservedGroupIds = {
  UNGROUPED,
  BACKGROUND,
};

function getClusters(oldClusters, scale, options) {
  let { maxItems, clusterCriteria } =
    typeof options === "boolean" ? {} : options;

  if (!clusterCriteria) {
    clusterCriteria = () => true;
  }

  maxItems = maxItems || 1;

  let level = -1;
  let granularity = 2;
  let timeWindow = 0;

  if (scale > 0) {
    if (scale >= 1) {
      return [];
    }

    level = Math.abs(Math.round(Math.log(100 / scale) / Math.log(granularity)));
    timeWindow = Math.abs(Math.pow(granularity, level));
  }

  // clear the cache when and re-generate groups the data when needed.
  if (this.dataChanged) {
    const levelChanged = level != this.cacheLevel;
    const applyDataNow = this.applyOnChangedLevel ? levelChanged : true;
    if (applyDataNow) {
      this._dropLevelsCache();
      this._filterData();
    }
  }

  this.cacheLevel = level;
  let clusters = this.cache[level];
  if (!clusters) {
    clusters = [];
    for (let groupName in this.groups) {
      if (this.groups.hasOwnProperty(groupName)) {
        const items = this.groups[groupName];
        const iMax = items.length;
        let i = 0;
        while (i < iMax) {
          // find all items around current item, within the timeWindow
          let item = items[i];
          let neighbors = 1; // start at 1, to include itself)

          // loop through items left from the current item
          let j = i - 1;
          while (j >= 0 && item.center - items[j].center < timeWindow / 2) {
            if (
              !items[j].cluster &&
              clusterCriteria(item.data, items[j].data)
            ) {
              neighbors++;
            }
            j--;
          }

          // loop through items right from the current item
          let k = i + 1;
          while (
            k < items.length &&
            items[k].center - item.center < timeWindow / 2
          ) {
            if (clusterCriteria(item.data, items[k].data)) {
              neighbors++;
            }
            k++;
          }

          // loop through the created clusters
          let l = clusters.length - 1;
          while (l >= 0 && item.center - clusters[l].center < timeWindow) {
            if (
              item.group == clusters[l].group &&
              clusterCriteria(item.data, clusters[l].data)
            ) {
              neighbors++;
            }
            l--;
          }

          // aggregate until the number of items is within maxItems
          if (neighbors > maxItems) {
            // too busy in this window.
            const num = neighbors - maxItems + 1;
            const clusterItems = [];

            // append the items to the cluster,
            // and calculate the average start for the cluster
            let m = i;
            while (clusterItems.length < num && m < items.length) {
              if (clusterCriteria(items[i].data, items[m].data)) {
                clusterItems.push(items[m]);
              }
              m++;
            }

            const groupId = this.itemSet.getGroupId(item.data);
            const group =
              this.itemSet.groups[groupId] ||
              this.itemSet.groups[ReservedGroupIds.UNGROUPED];
            let cluster = this._getClusterForItems(
              clusterItems,
              group,
              oldClusters,
              options
            );
            clusters.push(cluster);

            i += num;
          } else {
            delete item.cluster;
            i += 1;
          }
        }
      }
    }

    this.cache[level] = clusters;
  }

  return clusters;
}

export default function NewTimeline({ filters, zoom }) {
  const { data, isLoading } = useQuery("events", eventService.getAll);
  const [filterState, setFilterState] = useState(filters);

  let min = "9999999999";
  let max = "0";
  Object.keys(data).forEach((key) => {
    const event = data[key];
    if (event.date < min) {
      min = event.date;
    }
    if (event.date > max) {
      max = event.date;
    }
  });

  useEffect(() => {
    if (filters) {
      setFilterState(filters);
    }
  }, [filters]);

  min = moment(min).subtract(1, "years");
  max = moment(max).add(1, "years");

  props.options.min = min;
  props.options.max = max;
  props.options.zoomMax = max - min;

  const [length, setLength] = useState(data.length);
  // const [zoomable, setZoomable] = useState(true)

  const zoomable = useRef(true);
  const scale = useRef(-404);

  const history = useHistory();
  const ref = useRef();
  const { dialogDispatch } = useUser();

  // props = Object.assign()
  props.options.preferZoom = zoomable ? true : false;

  var visForeground = document.getElementsByClassName("vis-foreground")[0];

  if (visForeground) {
    visForeground.style.zIndex = "20";
  }

  var element = document.getElementsByClassName("vis-itemset")[0];

  let elementX;

  if (element) {
    elementX = element.getBoundingClientRect().x;
    element.addEventListener("mousemove", function (event) {
      drawLines(event);
    });

    element.addEventListener("mousedown", function (event) {
      drawLines(event);
    });

    element.addEventListener("mouseup", function (event) {
      drawLines(event);
    });

    element.addEventListener("mouseout", function (event) {
      drawLines(event);
    });
    element.addEventListener("dragenter", function (event) {
      drawLines(event);
    });
    element.addEventListener("dragover", function (event) {
      drawLines(event);
    });
    element.addEventListener("drop", function (event) {
      drawLines(event);
    });
  }

  function drawLines(event) {
    var x = event.pageX - elementX;

    var straightLine = element.querySelector(".straightLine");

    var slTrans = "translate(" + x + "px, 0px)";

    if (!straightLine) {
      straightLine = document.createElement("div");
      straightLine.classList.add("straightLine");
      straightLine.style.height = "100%";
      straightLine.style.width = "0px";
      straightLine.style.border = "1px dashed black";
      straightLine.style.position = "absolute";
      straightLine.style.opacity = "0.2";

      straightLine.style.zIndex = "10";
      element.appendChild(straightLine);
    }
    straightLine.style.transform = slTrans;
  }

  function zoomFct(scale2, center, delta, event) {
    if (!zoomable.current) return;

    const start = center;

    const [index, abort] = getIndex(scale.current, delta);

    if (abort) return;

    scale.current = index;

    const unit = zoomScaleReadable[index];

    const amount = Math.ceil(ref.current.timeline.props.root.width / 150);

    const new_start = moment(start).subtract(amount / 2, unit);
    const new_end = moment(start).add(amount / 2, unit);

    ref.current.timeline.setWindow(new_start, new_end);

    // if (center == null) {
    //     center = (this.start + this.end) / 2;
    // }
    //
    // const hiddenDuration = DateUtil.getHiddenDurationBetween(this.body.hiddenDates, this.start, this.end);
    // const hiddenDurationBefore = DateUtil.getHiddenDurationBefore(this.options.moment, this.body.hiddenDates, this, center);
    // const hiddenDurationAfter = hiddenDuration - hiddenDurationBefore;
    //
    // // calculate new start and end
    // let newStart = (center-hiddenDurationBefore) + (this.start - (center-hiddenDurationBefore)) * scale;
    // let newEnd   = (center+hiddenDurationAfter) + (this.end - (center+hiddenDurationAfter)) * scale;
    //
    // // snapping times away from hidden zones
    // this.startToFront = delta > 0 ? false : true; // used to do the right autocorrection with periodic hidden times
    // this.endToFront = -delta  > 0 ? false : true; // used to do the right autocorrection with periodic hidden times
    // const safeStart = DateUtil.snapAwayFromHidden(this.body.hiddenDates, newStart, delta, true);
    // const safeEnd = DateUtil.snapAwayFromHidden(this.body.hiddenDates, newEnd, -delta, true);
    // if (safeStart != newStart || safeEnd != newEnd) {
    //     newStart = safeStart;
    //     newEnd = safeEnd;
    // }
    //
    // const options = {
    //     animation: false,
    //     byUser: true,
    //     event
    // };
    // this.setRange(newStart, newEnd, options);
    //
    // this.startToFront = false; // revert to default
    // this.endToFront = true; // revert to default
  }

  useEffect(() => {
    // ref.current.timeline.fit()

    if (data.length < 2) return;
    if (data.length === length) return;

    const { min, max } = {
      min: moment(data[0].date),
      max: moment(data[data.length - 1].date),
    };
    ref.current.timeline.setWindow(min, max);

    ref.current.timeline.setOptions({ ...props.options, zoomMax: max - min });
    // ref.current.timeline.range.zoom = zoomFct
  }, [data, length]);

  function setItems(index = zoom) {
    const unit = zoomScaleReadable[zoomScales.indexOf(index)];

    const filtered = data
      .filter((obj) => {
        return filterState.some((item) => obj.timelines_id.includes(item));
      })
      .map((item) => {
        let date = moment(item.date);

        if (unit === "day") date = date.startOf("week");
        else if (unit === "week") date = date.startOf("week");
        else if (unit === "month") date = date.startOf("month");
        else if (unit === "year") date = date.startOf("year");

        return createItem(
          item.id,
          1,
          date,
          item,
          ref.current.timeline,
          history,
          dialogDispatch
        );
      });

    ref.current.timeline.setItems(filtered);

    if (data.length !== 2) return;

    const start = moment(data[0].date).startOf("year").subtract(2, "months");
    const end = moment(data[data.length - 1].date)
      .endOf("year")
      .add(2, "months");

    // console.log(range_min)
    // ref.current.timeline.setOptions(
    //     {...props.options, min: start, max: end}
    // )
  }

  function setZoomScale(zoom) {
    const { start, end } = ref.current.timeline.getWindow();
    const half = start.getTime() + (end - start) / 2;
    const new_end = half + (zoom * ONE_DAY_IN_MS) / 2;
    const new_Start = half - (zoom * ONE_DAY_IN_MS) / 2;

    ref.current.timeline.setWindow(new_Start, new_end);
  }

  // function onZoom(event) {
  //   if (!zoomable.current) return;

  //   const unit = zoomScaleReadable[scale.current];

  //   data.forEach((data) => {
  //     const item = ref.current.timeline.itemSet.items[data.id];

  //     let date = moment(item.data.content.date);
  //     if (unit === "day") date = date.startOf("week").add(12, "hours");
  //     else if (unit === "week") date = date.startOf("week").add(3, "days");
  //     else if (unit === "month") date = date.startOf("month").add(15, "days");
  //     else if (unit === "year") date = date.startOf("year").add(6, "months");

  //     item.start = date;
  //     ref.current.timeline.itemsData.update(item);
  //   });
  // }

  useEffect(() => {
    // console.log(filters)
    // console.log(ref.current.timeline.itemSet.items[1].data)
    // ref.current.timeline.itemSet.items[1].data.group = filters.length === 1 ? null : 1
    // console.log(ref.current.timeline.itemSet.items[1].data)
    // console.log()
    // ref.current.timeline.itemsData.update(ref.current.timeline.itemSet.items[1].data)

    setItems(zoomScales.indexOf(zoom));
  }, [filterState]);

  // useEffect(() => {
  //   // console.log({
  //   //     ...props.options,
  //   //     zoomable: zoomable.current
  //   // })
  //   //
  //   // console.log(zoomable.current)
  //   // ref.current.timeline.setOptions(
  //   //     {
  //   //         ...props,
  //   //         zoomable: zoomable.current
  //   //     }
  //   // )
  // }, [zoomable.current]);

  useEffect(() => {
    ref.current.timeline.fit();

    // ref.current.timeline.fit()
    //
    // const {min, max} = ref.current.timeline.getItemRange();
    //
    // ref.current.timeline.setOptions(
    //     {...props.options, zoomMax: max - min}
    // )

    setZoomScale(zoom);

    if (data.length === 0) return;

    const start = moment(data[0].date).startOf("year").subtract(2, "months");
    const end = moment(data[data.length - 1].date)
      .endOf("year")
      .add(2, "months");

    // console.log(range_min)
    // ref.current.timeline.setOptions(
    //     {...props.options, min: start, max: end}
    // )
    return;

    scale.current = zoomScales.indexOf(zoom);
    // setZoomScale(zoom)
    const unit = zoomScaleReadable[zoomScales.indexOf(zoom)];

    data.forEach((data) => {
      const item = ref.current.timeline.itemSet.items[data.id];

      let date = moment(item.data.content.date);
      if (unit === "day") date = date.startOf("week").add(12, "hours");
      else if (unit === "week") date = date.startOf("week").add(3, "days");
      else if (unit === "month") date = date.startOf("month").add(15, "days");
      else if (unit === "year") date = date.startOf("year").add(6, "months");

      item.start = date;
      ref.current.timeline.itemsData.update(item);
    });

    // setItems(zoomScales.indexOf(zoom))
  }, [zoom]);

  // setItems(3);

  if (isLoading) return <CircularProgress />;

  return (
    <Box
      id="timelineBox"
      width="100vw"
      height="80vh"
      marginTop="130px"
      marginLeft="91px"
    >
      <Timeline ref={ref} id="timeline" {...props} />
    </Box>
  );
}
