import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ResetPassPage from "./pages/ResetPassPage";
import SignupPage from "./pages/SignupPage";
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from './theme'
import DetailsPage from "./pages/DetailsPage";
import ProfilePage from "./pages/ProfilePage";
import PersonalProfilePage from "./pages/PersonalProfilePage";
import {UserProvider} from "./userContext";
import {QueryClient, QueryClientProvider, QueryErrorResetBoundary} from "react-query";
import ErrorBoundary from "./components/ErrorBoundary";
import Dashboard from "./pages/Dashboard";
import {CssBaseline} from "@material-ui/core";



export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true
        },
        mutations: {
            useErrorBoundary: true
        }
    }
})


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
        <CssBaseline/>
            <QueryErrorResetBoundary>
                {({ reset }) => (
                    <ErrorBoundary reset={reset}>
                        <QueryClientProvider client={queryClient}>
                            <UserProvider>

                                <Router>
                                    <Switch>

                                        <Route path="/" exact component={App}/>
                                        <Route path="/login" component={LoginPage} />
                                        <Route  path="/reset" component={ResetPassPage}/>
                                        <Route  path="/signup" component={SignupPage}/>

                                        <Route exact  path="/details/:type(image|event)/:id/" component={DetailsPage}/>

                                        <Route path="/profile/:id" component={ProfilePage} />
                                        <Route path="/user_profile" component={PersonalProfilePage}/>
                                        <Route path="/dashboard" component={Dashboard}/>

                                        <Route path='*'>
                                            <div>not found</div>
                                        </Route>
                                    </Switch>

                                </Router>

                            </UserProvider>
                        </QueryClientProvider>
                    </ErrorBoundary>
                )}
            </QueryErrorResetBoundary>

        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
