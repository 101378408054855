import {ChangeEvent, useState} from "react";

interface meta {
    name: string
    size: string
}

function human_readable(bytes: number) {
    if (bytes === 0) { return "0.00 B"; }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes/Math.pow(1024, e)).toFixed(2)+' '+' KMGTP'.charAt(e)+'B';
}

export default function usePreview(initial?: string) {

    const [url, setUrl] = useState<string | undefined>(initial);
    const [meta, setMeta] = useState<meta | null>(null);

    const handleUploadClick = (event:  ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const size = file?.size;
        const name = file?.name;

        if (size && name)
            setMeta({size: human_readable(size), name: name});

        setUrl(URL.createObjectURL(file))
    }

    const handleDelete = () => {
        setMeta(null);
        setUrl(undefined)
    }


    return {url, meta, handleUploadClick, handleDelete, setMeta, human_readable};
}