import HookForm from "../HookForm";
import { Avatar, Box, Grid, Link } from "@material-ui/core";
import { useUser } from "../../userContext";
import React, { PropsWithChildren } from "react";
import { useQuery } from "react-query";
import { timelineService } from "../../_services/timeline.services";
import StatusButton from "../StatusButton";

function DetailField({ children, label }: PropsWithChildren<Props>) {
  return (
    <div
      style={{
        marginBottom: 27,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box fontWeight={700}>{label}</Box>
      {children}
    </div>
  );
}

interface Props {
  label: string;
}

export default function RejectionIssueForm() {
  const {
    dialogState: { options },
    dialogDispatch,
  } = useUser();
  const { data } = useQuery("timelines", timelineService.getAll);

  console.log("Entity:", options.entity);

  function enlargeImg(url: string) {
    dialogDispatch({ type: "preview", payload: { img_url: url } });
  }

  if (options.entity === undefined) return null;

  return (
    <HookForm
      onSubmit={(_) => {
        if (options.type === "event") {
          dialogDispatch({
            type: "event",
            payload: {
              entity: options.entity,
              timeline: data === undefined ? [] : data,
            },
          });
        } else if (options.type === "image") {
          dialogDispatch({
            type: "image",
            payload: {
              entity: options.entity,
              event_id: options.entity.event.id,
              entity_title: options.entity.event.title,
            },
          });
        }
      }}
    >
      {(control) => (
        <>
          <div style={{ textAlign: "center", alignItems: "center" }}>
            {options.entity.img_url && (
              <div style={{ marginBottom: 49 }}>
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Avatar
                      variant="square"
                      src={options.entity.img_url}
                      style={{ width: 140, height: 140 }}
                    />
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </div>
            )}

            {options.type === "image" && (
              <DetailField label="Event title">
                {options.entity.event.title}
              </DetailField>
            )}

            <DetailField label="Reason of rejection">
              {options.reason}
            </DetailField>

            <DetailField label="Advice">{options.advice}</DetailField>
          </div>
        </>
      )}
    </HookForm>
  );
}
