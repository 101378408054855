import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import {
  ControlledInput,
  ControlledSelectRejectionReasons,
  ICOntrolledInputProps,
} from "./withControl";
import HookForm from "../HookForm";
import { useUser } from "../../userContext";
import { userService } from "../../_services/user.services";
import { apiClient } from "../../_services/authentication.services";
import TimeCounter from "../TimeCounter";

export default function RejectForm() {
  const { dialogState, dialogDispatch } = useUser();

  const [timeout, setTimeout] = useState(dialogState.options.timeout);
  const [rejectionReasons, setRejectionReasons] = useState(
    dialogState.options.rejectionReasons
  );

  const { mutate: abort } = useMutation(userService.abort_review);

  useEffect(() => {
    return () => {
      abortReview();
    };
  }, []);

  function abortReview() {
    abort({ type: dialogState.options.type, id: dialogState.options.id });
  }

  function reloadAbort() {
    if (document.visibilityState === "hidden") {
      const fd = new FormData();
      fd.append("id", dialogState.options.id);
      fd.append("type", dialogState.options.type);
      navigator.sendBeacon(
        apiClient.defaults.baseURL + "/api/beacon_abort",
        fd
      );
    }
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", reloadAbort);
    return () => {
      abortReview();

      window.removeEventListener("visibilitychange", reloadAbort);
    };
  }, []);

  const fields: ICOntrolledInputProps[] & Record<string, any> = [
    {
      name: "reason",
      rules: {
        required: "Reason required",
        maxLength: {
          value: 60,
          message: "Title must contain maximum 60 chars",
        },
      },
      Component: ControlledSelectRejectionReasons,
      label: "Reason for rejection",
      reasons: rejectionReasons,
      marginTop: 24,
      marginBottom: 32,
    },
    {
      name: "advice",
      rules: {
        required: "Advice required",
        maxLength: {
          value: 2000,
          message: "Description must be at most 2000 chars long",
        },
      },
      multiline: true,
      rows: 3,
      Component: ControlledInput,
      label: "Advice",
      marginTop: 29,
    },
  ];

  const { mutate } = useMutation(userService.review);

  return (
    <div style={{ textAlign: "center" }}>
      <HookForm
        onSubmit={(data) => {
          const form = {
            ...data,
            status: "rejected",
            id: dialogState.options.id,
            type: dialogState.options.type,
          };
          console.log("form", form);
          mutate(form);
          dialogDispatch({
            type: "success",
            payload: { succes_type: "review_rejected" },
          });
        }}
      >
        {(control, setValue) => (
          <>
            {fields.map(({ Component, ...value }, index) => (
              <Component {...value} control={control} setValue={setValue} />
            ))}
            <div style={{ marginTop: 29 }}>
              <TimeCounter time={timeout} setTimeout={setTimeout} />
            </div>
          </>
        )}
      </HookForm>
    </div>
  );
}
