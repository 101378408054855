import { createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { Avatar, Box, Grid, Link, SvgIcon } from "@material-ui/core";
import { theme } from "../theme";
import { useMutation, useQueryClient } from "react-query";
import { userService } from "../_services/user.services";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { useUser } from "../userContext";
import { AuthOnlyButton } from "./UserRoles/AuthOnly";
import React from "react";
import { RouteParams } from "../pages/DetailsPage";
import { apiClient } from "../_services/authentication.services";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // maxWidth: 345,
      position: "relative",
    },
    media: {
      width: "100%",
      height: 170,
    },
    centerMedia: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      margin: 0,
    },
    share: {
      position: "absolute",
      right: 5,
      top: 5,
    },
    whiteIcon: {
      color: "white",
    },
    contentBorder: {
      borderLeft: "1px solid",
      borderRight: "1px solid",
      borderColor: "#000000",
      paddingBottom: 0,
      paddingTop: theme.spacing(0.5),
    },
    actionsBorder: {
      borderLeft: "1px solid",
      borderBottom: "1px solid",
      borderRight: "1px solid",
      borderRadius: "0px 0px 4px 4px",
      borderColor: "#000000",
      padding: "8px 15px 15px",
    },
  })
);

type cardType = "main" | "most" | "rest";

interface IProps {
  title: string;
  votes: number;
  imgUrl: string;
  voted?: boolean;
  type?: cardType;
  user: { id: number; name: string };
  event_id: number;
  entity: { id: number; type: string; description: string; status?: string };
}

export default function ImageCard({
  title = "title",
  votes = 112,
  imgUrl = "https://i.picsum.photos/id/901/1920/1080.jpg?hmac=fgBe0jlryNa127K03tcKL3gthuGnpoFG9xd4s20a6Uc",
  voted = false,
  type = "rest",
  user,
  event_id,
  entity,
}: IProps) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { dialogDispatch } = useUser();
  const { id } = useParams<RouteParams>();

  const onImageClick = () => {
    dialogDispatch({ type: "preview", payload: { img_url: imgUrl } });
  };

  const { mutate } = useMutation(
    // @ts-ignore
    userService.vote,
    {
      onMutate: async () => {
        const int_id = parseInt(id);

        if (type === "main") {
          await queryClient.cancelQueries([entity.type, { id: entity.id }]);

          const previousValue = queryClient.getQueryData([
            entity.type,
            { id: entity.id },
          ]);

          queryClient.setQueryData(
            [entity.type, { id: entity.id }],
            (input) => {
              // @ts-ignore
              const sign = voted ? -1 : 1;

              return {
                // @ts-ignore
                ...input,
                // @ts-ignore
                voted: !input.voted,
                vote_cnt: votes + sign * 1,
              };
            }
          );
          return previousValue;
        }

        await queryClient.cancelQueries(["entities", event_id]);

        const previousValue = queryClient.getQueryData(["entities", event_id]);

        queryClient.setQueryData(["entities", event_id], (input) => {
          // alert(entity.id)
          // @ts-ignore
          const sign = voted ? -1 : 1;

          // @ts-ignore
          input.pages.map((page) => {
            const obj = page.data.find((value: any) => value.id === entity.id);

            if (obj !== undefined)
              Object.assign(
                obj,
                // @ts-ignore
                { voted: !voted, vote_cnt: votes + sign * 1 }
              );

            return page;
          });
          return input;
        });

        return previousValue;
      },
      onError: (error, variables, { previousValue }) => {
        const query =
          type === "main" ? [entity.type, { id: entity.id }] : "entities";
        if (previousValue) queryClient.setQueryData(query, previousValue);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["entities", parseInt(id)]);
        queryClient.invalidateQueries([entity.type, { id: entity.id }]);
        queryClient.invalidateQueries(["event", { id: parseInt(id) }]);
      },
    }
  );

  function cardClicked() {
    if (type === "main" && imgUrl !== null) onImageClick();
    else history.push(`/details/image/${entity.id}`);
  }

  const votesText =
    type === "most" ? `TOTAL VOTES: ${votes}` : `${votes} VOTES`;

  const heading = [
    <Box
      fontWeight="fontWeightBold"
      style={{ wordBreak: "break-word" }}
      key={"title"}
    >
      {title}
    </Box>,
    <>
      <Link color="textSecondary" underline="none" style={{ marginRight: 3 }}>
        By
      </Link>
      <Link
        key="creator"
        component={RouterLink}
        color="textSecondary"
        underline="always"
        to={`/profile/${user.id}`}
      >
        {user.name}
      </Link>
    </>,
  ];

  if (type === "main") heading.reverse();

  function share() {
    const url = apiClient.defaults.baseURL;
    // @ts-ignore
    window
      .open(
        `https://twitter.com/intent/tweet?url=${url}details/${entity.type}/${entity.id}`,
        "_blank"
      )
      .focus();
  }

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.centerMedia}>
        <Avatar
          className={classes.media}
          variant="square"
          src={imgUrl}
          onClick={cardClicked}
        >
          <SvgIcon
            style={{ width: "100%", height: "100%", background: "#E4E4E4" }}
          ></SvgIcon>
        </Avatar>
      </div>

      <IconButton className={classes.share} onClick={share}>
        <ShareOutlinedIcon
          className={classes.whiteIcon}
          style={{ background: "black", opacity: "10%" }}
        />
      </IconButton>

      <CardContent className={classes.contentBorder}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: "100%" }}
          spacing={3}
        >
          <Grid
            item
            style={{ fontWeight: 700, letterSpacing: 0.3, fontSize: 12 }}
          >
            {heading.map((value) => value)}
          </Grid>
          <Grid item>
            <Box
              fontWeight="fontWeightBold"
              style={{ fontSize: 16, fontWeight: 700, letterSpacing: 0.3 }}
            >
              {votesText}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionsBorder}>
        <AuthOnlyButton
          style={{ borderRadius: 0, marginBottom: 7 }}
          fullWidth
          color="secondary"
          variant="contained"
          size="small"
          onClick={() => {
            mutate(entity);
          }}
          disabled={entity.status !== "approved" ? true : false}
        >
          {entity.status !== "approved"
            ? entity.type === "event"
              ? "EVENT NOT APPROVED YET"
              : "NFT NOT APPROVED YET"
            : voted
            ? "UNVOTE"
            : "VOTE"}
        </AuthOnlyButton>
      </CardActions>
    </Card>
  );
}
