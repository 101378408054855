import {apiClient} from "./authentication.services";
import {IDetails} from "../pages/DetailsPage";


export const contactService = {
    postInquiry,
    postReport
};


async function postInquiry(data: any ): Promise<IDetails> {
    const res = await apiClient.post(`/api/contact`, data);

    return res.data
}


async function postReport(data: any ): Promise<IDetails> {
    const res = await apiClient.post(`/api/report`, data);

    return res.data
}