import HookForm from "../HookForm";
import {Box, Grid} from "@material-ui/core";
import {ControlledInput, ICOntrolledInputProps} from "./withControl";
import {useUser} from "../../userContext";
import {useMutation} from "react-query";
import {contactService} from "../../_services/contact.services";

export default function ContactUsForm() {
    const {dialogDispatch} = useUser();

    const fields: ICOntrolledInputProps[] & Record<string, any> = [
        {
            name: "email",
            rules: (_: any) => {
                return {
                    required: "Email address required",
                };
            },
            Component: ControlledInput,
            label: 'Email address',
        }, {
            name: "email_repeated",
            rules: (getValues: any) =>{
                const emailAddress = getValues('email', "");
                return {
                    required: "Repeat email",
                    validate: (value: string) => {
                        return value === emailAddress || "The email addresses do not match"
                    }
                };
            },
            Component: ControlledInput,
            label: "Repeat email address"
        }, {
            name: "message",
            rules: (_: any) => {
                return {
                    required: 'Message required',
                };
            },
            multiline: true,
            rows: 3,
            Component: ControlledInput,
            label: 'Your message',
        }
    ]

    const {mutate} = useMutation((variables) => contactService.postInquiry(variables))

    return (
        <HookForm onSubmit={(data) => {
            mutate(data)
            dialogDispatch({type: 'success', payload: {succes_type: 'contact'}})
        }} >
            {(control, setValue, getValues ) =>
                <Grid container direction="column"  spacing={2}>
                    {fields.map(({Component, rules, ...value}, index) =>
                        <Grid item key={index}>
                            <Component {...value} control={control} setValue={setValue} rules={rules(getValues)}/>
                        </Grid>
                    )}
                </Grid>
            }
        </HookForm>
    );
}

export function ContactHeader() {
    return (
        <div style={{marginLeft:'20%', marginRight:'20%'}} >
            <Box fontWeight={700} fontSize='1.25rem' textAlign='center' style={{marginBottom: 15, letterSpacing: 0.2}}>
                Contact us
            </Box>

            <Box textAlign='center' style={{letterSpacing: 0.2}}>
                Feel free to contact us
            </Box>
        </div>
    )

}