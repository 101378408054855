import HookForm from "../HookForm";
import { Avatar, Box, Chip, Grid, GridSize, Link } from "@material-ui/core";
import { useUser } from "../../userContext";
import { useMutation, useQuery } from "react-query";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { userService } from "../../_services/user.services";
import { apiClient } from "../../_services/authentication.services";
import TimeCounter from "../TimeCounter";

interface Props {
  label: string;
  childXs?: GridSize;
  style?: React.CSSProperties;
}

function DetailField({
  children,
  label,
  childXs,
  style,
}: PropsWithChildren<Props>) {
  return (
    <Grid container style={style}>
      <Grid item xs={3}>
        <Box fontWeight={700} textAlign="left">
          {label}
        </Box>
      </Grid>
      <Grid item xs={childXs} style={{ textAlign: "start" }}>
        {children}
      </Grid>
    </Grid>
  );
}

function formatDate(date: string) {
  return new Date(date)
    .toLocaleString("ro-RO", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
    .replace(".", "");
}

export default function ReviewForm() {
  const { dialogState, dialogDispatch } = useUser();
  const [timeout, setTimeout] = useState(dialogState.options.timeout);

  const { mutate } = useMutation(userService.review);
  const { mutate: abort } = useMutation(userService.abort_review);
  const shouldAbort = useRef(true);

  const rejectionReasons = useQuery(
    "rejection_reasons",
    userService.getRejectionReasons
  ).data;

  function enlargeImg(url: string) {
    dialogDispatch({ type: "preview", payload: { img_url: url } });
  }

  function abortReview() {
    abort({ type: dialogState.options.type, id: dialogState.options.id });
  }

  function reloadAbort() {
    if (document.visibilityState === "hidden") {
      const fd = new FormData();
      fd.append("id", dialogState.options.id);
      fd.append("type", dialogState.options.type);
      navigator.sendBeacon(
        apiClient.defaults.baseURL + "/api/beacon_abort",
        fd
      );
    }
  }

  useEffect(() => {
    window.addEventListener("visibilitychange", reloadAbort);
    return () => {
      if (shouldAbort.current) abortReview();

      window.removeEventListener("visibilitychange", reloadAbort);
    };
  }, []);

  return (
    <HookForm
      onSubmit={(_) => {
        const form = {
          status: "approved",
          type: dialogState.options.type,
          id: dialogState.options.id,
        };
        mutate(form);
        dialogDispatch({
          type: "success",
          payload: { succes_type: "review_accept" },
        });
      }}
      onReset={(_) => {
        shouldAbort.current = false;
        dialogDispatch({
          type: "reject",
          payload: { timeout: timeout, rejectionReasons: rejectionReasons },
        });
      }}
    >
      {() => (
        <>
          {dialogState.options.type === "image" && (
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  variant="square"
                  src={dialogState.options.img_url}
                  style={{ width: 140, height: 140 }}
                />
              </Grid>
              <Grid item>
                <Link
                  component="button"
                  underline="always"
                  color="textPrimary"
                  onClick={() => enlargeImg(dialogState.options.img_url)}
                  style={{ fontWeight: 400 }}
                >
                  Click to enlarge
                </Link>
              </Grid>
            </Grid>
          )}

          <DetailField
            label="Description"
            childXs={12}
            style={{ marginTop: 35 }}
          >
            <div style={{ marginTop: 6 }}>
              {dialogState.options.description}
            </div>
          </DetailField>

          {(dialogState.options.source && dialogState.options.type === "event") && (
            <DetailField label="Source link" style={{ marginTop: 30 }}>
              <Link
                href={dialogState.options.source}
                target="_blank"
                color="textPrimary"
              >
                {dialogState.options.source}
              </Link>
            </DetailField>
          )}

          {dialogState.options.type === "image" &&
            dialogState.options.updated_at && (
              <DetailField label="Date and time" style={{ marginTop: 30 }}>
                {formatDate(dialogState.options.updated_at)}
              </DetailField>
            )}

          {dialogState.options.type === "event" && dialogState.options.date && (
            <DetailField label="Date and time" style={{ marginTop: 30 }}>
              {formatDate(dialogState.options.date)}
            </DetailField>
          )}

          {dialogState.options.timelines_details && (
            <DetailField
              label="Event Timeline"
              style={{
                marginTop: 30,
                marginBottom: 50,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item container direction="row" spacing={1}>
                {dialogState.options.timelines_details.map((timeline: any) => (
                  <Grid item key={`timelines-${timeline.id}`}>
                    <div style={{ paddingBottom: 10 }}>
                      <Chip
                        label={timeline.name}
                        style={{
                          backgroundColor: "black",
                          borderRadius: 5,
                          color: "white",
                        }}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </DetailField>
          )}
          <TimeCounter time={timeout} setTimeout={setTimeout} />
        </>
      )}
    </HookForm>
  );
}
