import {useState} from "react";
import {Box, Button, Grid, SvgIcon} from "@material-ui/core";
import HookForm from "../components/HookForm";
import {NoLabelControlledInput} from "../components/Forms/withControl";
import {ReactComponent as ResetSent} from '../_icons/reset_sent.svg'
import {ReactComponent as Reset} from '../_icons/reset.svg'
import {authenticationService} from "../_services/authentication.services";
import {useMutation} from "react-query";


export default function ResetPassPage() {
    const [reset, setReset] = useState(true)

    const {mutate} = useMutation(variables => authenticationService.reset(variables))

    const onSubmit = (data: any) => {
        mutate(data)
        setReset(false)
    }

    return (
        <Grid container direction='column' justify='center' alignItems='center' style={{height: '100vh'}}>

            <Grid item>
                <SvgIcon
                    style={{fontSize: '6em'}}
                    component={reset? Reset : ResetSent}
                    viewBox={reset? '0 0 60 55' : '0 0 63 42'}
                />
            </Grid>

            <Grid item style={{marginTop:10}}>
                <Box fontWeight={700} fontSize={reset ? 24 : 32} style={{letterSpacing: '0.2px'}}>
                    {
                        reset ? 'Recover password' : 'Check your email'
                    }
                </Box>
            </Grid>
            <Grid item style={{marginTop:10,letterSpacing: '0.2px', fontSize:'1rem'}}>
                {
                    reset ?
                        'Write your email address to reset'
                        :
                        'An email with recovery link has been sent to provided address'
                }
            </Grid>

            {
                reset &&

                <Grid item style={{marginTop: 90}}>
                    <Box fontWeight={700}>Email</Box>
                    <Grid container direction='row' spacing={2} alignItems='center' style={{flex: 1}}>
                        <Grid item style={{height: 100}}>
                            <HookForm onSubmit={onSubmit}>
                                { control =>
                                    <NoLabelControlledInput control={control} name='email' rules={{required: 'email required'}} style={{letterSpacing: '0.2px'}}/>
                                }
                            </HookForm>
                        </Grid>
                        <Grid item style={{height: 100}}>
                            <Button color='primary' variant='contained' style={{height:'40px', width:'110px', fontSize:19, textTransform:'capitalize'}} size='small' type='submit' form='myform' >Send</Button>
                        </Grid>
                    </Grid>
                </Grid>

            }

        </Grid>
    )
}