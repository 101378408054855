import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Card, Link, SvgIcon } from "@material-ui/core";
import { ReactComponent as EventFallback } from "../../_icons/no_image_event.svg";
import moment from "moment";
import { AuthOnlyButton } from "../UserRoles/AuthOnly";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useMutation, useQueryClient } from "react-query";
import { userService } from "../../_services/user.services";
import { useUser } from "../../userContext";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      width: 529,
      height: 230,
    },
    cover: {
      width: 192,
      height: 192,
      marginTop: 20,
      marginLeft: 20,
    },
  })
);

export default function HoveredCard({ content, history, close }: any) {
  const {
    state: { user },
  } = useUser();

  const classes = useStyles();
  const [buttonVisibility, setButtonVisibility] = useState(true);
  const queryClient = useQueryClient();

  const contentDiv = useRef<HTMLDivElement>(null);
  const link =
    content.event_id === undefined ? "/details/event/" : "/details/image/";
  const id = content.event_id === undefined ? content.id : content.event_id;

  const { mutate } = useMutation(
    // @ts-ignore
    userService.vote,
    {
      onMutate: async () => {
        await queryClient.cancelQueries("events");

        const previousValue = queryClient.getQueryData("events");

        console.log("Previous value", previousValue);

        queryClient.setQueryData("events", (input) => {
          const sign = content.voted ? -1 : 1;

          // @ts-ignore
          let obj = input.find((value: any) => value.id === id);
          console.log("Content:", content);
          console.log("Object", obj);

          if (content.event_id !== undefined)
            obj = obj.accepted_images.find(
              (value: any) => value.id === content.id
            );

          console.log("Object", obj);

          // @ts-ignore
          Object.assign(
            obj,
            // @ts-ignore
            { voted: !content.voted, vote_cnt: content.vote_cnt + sign * 1 }
          );
          Object.assign(
            content,
            // @ts-ignore
            { voted: !content.voted, vote_cnt: content.vote_cnt + sign * 1 }
          );
          // @ts-ignore
          return input;
        });

        return previousValue;
      },
      onError: (error, variables, { previousValue }) => {
        if (previousValue) queryClient.setQueryData("events", previousValue);
      },
      onSettled: () => {
        queryClient.invalidateQueries("events");
      },
    }
  );

  const clickRedirect = () => {
    close();
    history.push(`${link}${content.id}`);
  };

  const clickUser = () => {
    close();
    history.push(`/profile/${content.user.id}`);
  };

  useEffect(() => {
    const element = contentDiv.current;

    if (element != null) {
      const verticalOverflow = element.offsetHeight < element.scrollHeight;
      setButtonVisibility(verticalOverflow);
    }
  }, []);

  function vote() {
    let type = "event";
    if (content.event_id !== undefined) {
      type = "image";
    }

    mutate({ id: content.id, type: type });
  }

  return (
    <Card className={classes.root}>
      <Avatar variant="square" className={classes.cover} src={content.img_url}>
        <SvgIcon
          viewBox="0 0 188 190"
          style={{ width: "100%", height: "100%" }}
        >
          <EventFallback />
        </SvgIcon>
      </Avatar>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        marginTop="10px"
        marginLeft="20px"
        marginRight="30px"
        marginBottom="15px"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          fontSize="0.85rem"
          color="#B6B6B6"
        >
          <Box>
            {content.date
              ? moment(new Date(content.date)).format("DD MMM yyyy")
              : " "}
          </Box>
          <Box style={{ marginBottom: 10 }}>
            Posted by:
            <Link
              component="button"
              underline="always"
              style={{ color: "#B6B6B6", marginLeft: 5 }}
              onClick={clickUser}
            >
              {content.user.name}
            </Link>
          </Box>
        </Box>
        <Box
          flex={1}
          fontSize="0.85rem"
          marginTop="16px"
          marginBottom="10px"
          display="flex"
          flexDirection="column"
        >
          <Box fontWeight={700} style={{ wordBreak: "break-all" }}>
            {content.title}
          </Box>
          <Box flex="1 1 0" {...{ ref: contentDiv }} overflow="hidden">
            {content.description}
          </Box>

          <Box>
            <Link
              underline="always"
              color="textPrimary"
              onClick={clickRedirect}
              component="button"
              style={{ fontWeight: 700 }}
            >
              Read more
            </Link>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box fontWeight={700} fontSize="1.28rem">
            Votes: {content.vote_cnt}
          </Box>
          {user != null ? (
            <AuthOnlyButton
              style={{ width: "133px", height: "34px" }}
              color="secondary"
              variant="contained"
              onClick={vote}
            >
              {content.voted ? "UNVOTE" : "VOTE"}
            </AuthOnlyButton>
          ) : (
            <AuthOnlyButton
              style={{ width: "133px", height: "34px" }}
              color="secondary"
              variant="contained"
              onClick={vote}
              disabled={true}
            >
              {"LOGIN TO VOTE"}
            </AuthOnlyButton>
          )}
        </Box>
      </Box>
    </Card>
  );
}
