import React, { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import { Box, Button, Grid, InputBase, Link } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import SpreadWithMiddle from "./components/SpreadWithMiddle";
import { useUser } from "./userContext";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { timelineService } from "./_services/timeline.services";
import { AuthOnlyButton } from "./components/UserRoles/AuthOnly";
import { eventService } from "./_services/event.services";
import Notification from "./components/MainPageHeader/Notification";
import UserProfile from "./components/MainPageHeader/UserProfile";
import NewTimeline, { zoomScales } from "./components/NewTimeline/NewTimeline";
import TimelinesFilter, {
  drawerWidth,
} from "./components/NewTimeline/TimelinesFilter";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: "#F1F1F2",
    },
    drawerPaper: {
      width: drawerWidth,
      borderRadius: 4,
      maxHeight: `calc(100vh - ${2 * theme.spacing(1)}px)`,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      background: "#333238",
      overflowY: "hidden",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      paddingTop: 80,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    timeline: {
      transform: "rotate(90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(-90deg)",
      textAlign: "center",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
    checkbox: {
      color: "white",
      "&$checked": {
        color: "white",
      },
    },
    actionButton: {
      backgroundColor: "white",
      width: 152,
      fontSize: "14px",
      borderRadius: "2px",
      letterSpacing: "0.3px",
      padding: "8px 9px",
      borderWidth: "2px",
      "&:hover": {
        borderWidth: "2px",
      },
    },
    loginButton: {
      backgroundColor: "transparent",
      width: 152,
      fontSize: "14px",
      borderRadius: "2px",
      letterSpacing: "0.3px",
      padding: "8px 9px",
      borderWidth: "2px",
      "&:hover": {
        borderWidth: "2px",
      },
    },
    rightAppBarContainer: {
      alignItems: "center",
      marginRight: 20,
    },
  })
);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 2,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #323232",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Space Grotesk"].join(","),
    "&:focus": {
      borderRadius: 2,
      width: "154px",
    },
  },
}))(InputBase);

export function RightAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const { state, dialogDispatch } = useUser();

  const toLogin = () => {
    history.push("/login");
  };

  const contactUs = () => {
    dialogDispatch({ type: "contact" });
  };

  function openWallet() {
    dialogDispatch({ type: "wallet" });
  }

  return (
    <Grid
      container
      spacing={4}
      justify="center"
      className={classes.rightAppBarContainer}
    >
      <Grid item>
        <Link component="button">
          <Box
            fontWeight={700}
            fontSize="1rem"
            letterSpacing="0.3px"
            color="black"
          >
            About us
          </Box>
        </Link>
      </Grid>

      <Grid item>
        <Link component="button" onClick={contactUs}>
          <Box
            fontWeight={700}
            fontSize="1rem"
            letterSpacing="0.3px"
            color="black"
          >
            Contact
          </Box>
        </Link>
      </Grid>

      {state.user != null && (
        <>
          <Notification />
          <Grid item>
            <UserProfile />
          </Grid>
        </>
      )}

      <Grid item>
        {state.user === null ? (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={toLogin}
            className={classes.loginButton}
            style={{ fontWeight: 700, letterSpacing: 0.3 }}
          >
            Login / Signup
          </Button>
        ) : (
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={openWallet}
            className={classes.actionButton}
            style={{ fontWeight: 700 }}
          >
            Connect wallet
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default function App() {
  const classes = useStyles();
  const { state, dialogDispatch } = useUser();
  const history = useHistory();
  const [period, setPeriod] = useState<number>(zoomScales[3]);

  const [checkboxes, setCheckboxes] = React.useState<number[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriod(parseInt(event.target.value as string));
  };

  const timelines = useQuery("timelines", timelineService.getAll).data;
  const { data } = useQuery("events", eventService.getAll, {
    onSuccess: () => {
      if (timelines) {
        setCheckboxes([]);
        const newBoxes = Array.from({ length: timelines.length }, (e, i) => i);
        setCheckboxes(newBoxes);
      }
    },
  });

  const events = data;

  if (timelines === undefined || events === undefined) return null;

  const addEvent = () => {
    if (state.user == null) {
      history.push("/login");
      return;
    }
    dialogDispatch({ type: "event", payload: { timeline: timelines } });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={classes.appBar}
        color="transparent"
        elevation={0}
      >
        <SpreadWithMiddle
          padding
          Left={(classname) => (
            <Select
              variant="outlined"
              value={period}
              onChange={handleChange}
              className={classname}
              style={{
                backgroundColor: "white",
                width: "154px",
                height: 40,
                color: "black",
                fontWeight: 700,
                marginLeft: "24px",
              }}
              input={<BootstrapInput />}
            >
              <MenuItem
                value={zoomScales[0]}
                style={{
                  marginTop: -8,
                  width: 160,
                  borderLeft: "1px solid #323232",
                  borderRight: "1px solid #323232",
                  borderTop: "1px solid #323232",
                  borderRadius: "4px 4px 0px 0px",
                  fontWeight: period === zoomScales[0] ? 700 : 400,
                }}
              >
                Day
                <svg
                  className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style={{ paddingBottom: 3 }}
                >
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
              </MenuItem>
              <MenuItem
                value={zoomScales[1]}
                style={{
                  width: 160,
                  borderLeft: "1px solid #323232",
                  borderRight: "1px solid #323232",
                  fontWeight: period === zoomScales[1] ? 700 : 400,
                }}
              >
                Week
              </MenuItem>
              <MenuItem
                value={zoomScales[2]}
                style={{
                  width: 160,
                  borderLeft: "1px solid #323232",
                  borderRight: "1px solid #323232",
                  fontWeight: period === zoomScales[2] ? 700 : 400,
                }}
              >
                Month
              </MenuItem>
              <MenuItem
                value={zoomScales[3]}
                style={{
                  marginBottom: -8,
                  width: 160,
                  borderLeft: "1px solid #323232",
                  borderRight: "1px solid #323232",
                  borderBottom: "1px solid #323232",
                  borderRadius: "0px 0px 4px 4px",
                  fontWeight: period === zoomScales[3] ? 700 : 400,
                }}
              >
                Year
              </MenuItem>
            </Select>
          )}
          Middle={
            <Grid container justify="flex-end">
              <AuthOnlyButton
                variant="contained"
                color="primary"
                onClick={addEvent}
                style={{
                  width: 264,
                  paddingTop: 10,
                  paddingBottom: 10,
                  boxShadow: "0",
                  fontWeight: 700,
                  letterSpacing: 0.3,
                }}
              >
                Add new event
              </AuthOnlyButton>
            </Grid>
          }
          Right={(classname) => (
            <Box className={classname}>
              <RightAppBar />
            </Box>
          )}
        />
      </AppBar>

      <TimelinesFilter setCheckboxes={setCheckboxes} />

      <NewTimeline filters={checkboxes} zoom={period} />
    </div>
  );
}
