import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener, Divider,
    Grid,
    Grow, Link,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SvgIcon
} from "@material-ui/core";
import React, {PropsWithChildren, SVGProps} from "react";
import {useUser} from "../../userContext";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {theme} from "../../theme";
import {ReactComponent as Settings} from "../../_icons/settings.svg";
import {ReactComponent as Logout} from "../../_icons/log-out.svg";
import {ReactComponent as Toggle} from "../../_icons/toggle.svg";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {Link as RouterLink, useHistory} from "react-router-dom";
import {authenticationService} from "../../_services/authentication.services";
import {useMutation} from "react-query";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {roles} from "../../pages/ProfilePage";

interface ItemIconProps {
    icon: SVGProps<any>
}

function MenuItemIcon({icon, children}: PropsWithChildren<ItemIconProps>) {
    return (
        <Grid container direction="row" alignItems="center" spacing={2} style={{paddingLeft: '17px', paddingRight: '22px', marginBottom: 21}}>
            <Grid item>
                <SvgIcon style={{width: '1.375rem', height: '1.375rem', fontSize: '1.5rem', marginRight: '11px', display: 'flex', alignItems: 'center'}}>
                    {icon}
                </SvgIcon>
            </Grid>

            <Grid item>
                {children}
            </Grid>
        </Grid>
    )
}

function capitalize([first, ...rest]: string) {
    return first.toUpperCase() + rest.join('')
}

const useStyles = makeStyles(() =>
    createStyles({

        user: {
            color: "#FFC656",
            fontSize: "0.875rem",
            fontWeight: 700
        },
        curator: {
            color: "#6AA6FF",
        },

        admin: {
            color: "#A378FF",
        },
        menuItem: {
            transition: '.2s',
            '&:hover': {
                background: '#272727'
            }
        }

    }));

export default function UserProfile() {
    const classes = useStyles();
    const {state: {user}, dispatch, } = useUser();
    const history = useHistory();
    const {mutate} = useMutation( authenticationService.logout, {
        onSuccess: _ => localStorage.clear()
    })
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    function handleToggle () {
        setOpen((prevOpen) => !prevOpen);
    }

    function handleClose(event: React.MouseEvent<EventTarget>) {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    }

    function onSettingsClick() {
        history.push('/user_profile')
    }

    function onLogoutClick() {
        mutate()
        dispatch({type: 'logout'})
        history.push('/')
    }

    function onProfileClick() {
        history.push(`/profile/${user.id}`);
    }

    function onSwitchClick() {
        if (user.roles[0] === 'user')
            history.push('/login')
        else
            history.push('/dashboard')
    }

    return (
        <>
            <Chip variant="outlined"
                  ref={anchorRef}
                  deleteIcon={<ArrowDropDownIcon style={{color: 'white'}}/>}
                  onDelete={handleToggle}
                  avatar={<Avatar variant='square' src={user.avatar} style={{height:"36px",width:"36px"}} />}
                  style={{borderRadius: 2, background: '#333238', height: '44px',width:'83px'}}
            />

            <Popper open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{marginTop: theme.spacing(2), zIndex: 1, width: 370}}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            background: '#181818',
                            color: 'white',
                            borderRadius: '2px'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    <MenuItem className={classes.menuItem} onClick={onProfileClick} style={{color:'white', borderRadius: '2px', paddingLeft: '0', marginLeft: 10, marginBottom: 4, paddingTop: 10, paddingBottom: 10}}>
                                        <Avatar variant='square'  src={user.avatar} style={{width: 60, height: 60, marginLeft: 10}} />
                                        <Box display='flex'
                                             flex={1}
                                             flexDirection='row'
                                             justifyContent='space-between'
                                             marginLeft='18px'
                                             alignItems='center'
                                        >
                                            <Box display='flex' flexDirection='column'>
                                                <Box fontSize='1.25rem' fontWeight={700} style={{lineHeight: '33px', marginBottom: 5}}>
                                                    {user.name}
                                                </Box>
                                                <Box className={classes[user.roles[0].name as roles]}>
                                                    {capitalize(user.roles[0].name)}
                                                </Box>
                                            </Box>
                                            <KeyboardArrowRightIcon style={{color: 'white'}}/>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem disabled style={{marginBottom: 0}}>
                                        <Divider light style={{backgroundColor: '#333333', width:'100%', opacity: 1, marginBottom: 0}}/>
                                    </MenuItem>

                                    <MenuItem onClick={onSwitchClick}>
                                        {(user.roles[0].name === 'curator' || user.roles[0].name === 'admin') &&
                                        <MenuItemIcon icon={<Toggle/>}>
                                            <Link style={{color: 'white'}}>
                                                Dashboard
                                            </Link>
                                        </MenuItemIcon>
                                        }
                                    </MenuItem>
                                    <MenuItem onClick={onSettingsClick}>
                                        <MenuItemIcon icon={<Settings/>}>
                                            <Link style={{color: 'white'}}>
                                                Settings
                                            </Link>
                                        </MenuItemIcon>
                                    </MenuItem>
                                    <MenuItem onClick={onLogoutClick}>

                                        <MenuItemIcon icon={<Logout/>}>
                                            <Link style={{color: 'white'}}>
                                                Logout
                                            </Link>
                                        </MenuItemIcon>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>

    )
}