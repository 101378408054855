import {
    Avatar,
    Grid,
    InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Select
} from "@material-ui/core";

import React, {Fragment, useState} from "react";
import {userService} from "../../_services/user.services";
import {useUser} from "../../userContext";
import withInfinite from "./InfiiniteScroll";
import ListProps from "./list";

interface ISelectProps {
    userId: number,
    currentRole?: string,
}

function LabeledSelect({userId, currentRole='user'}: ISelectProps) {
    const [role, setRole] = useState(currentRole)
    const {dialogDispatch} = useUser()

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newRole = event.target.value as string
        dialogDispatch({type: 'confirm', payload: {user_id: userId, role: newRole,
                onSuccess: () => setRole(newRole)
            }})
    };

    return (
        <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
                <InputLabel id="label" style={{fontWeight:'bold', color:'black', fontSize: 14, letterSpacing: 0.3}}>Rights</InputLabel>
            </Grid>
            <Grid item>

                <Select
                    native
                    variant="outlined"
                    labelId="label"
                    value={role}
                    onChange={handleChange}
                    style={{height:'30px', width:'130px', fontWeight:500}}
                >
                    <option value={'user'}>User</option>
                    <option value={'curator'}>Curator</option>
                    <option value={'admin'}>Admin</option>
                </Select>
            </Grid>

        </Grid>
    );
}

function RightsManagement({data}: ListProps<any>) {
    return (
        <>
            {data?.pages.map(page => (
                <Fragment key={page.current_page}>
                    {page.data.map((value: any, index: number) => (
                        <ListItem alignItems="flex-start" divider style={{marginTop:'15px', marginBottom:'20px', paddingBottom:'20px'}}>
                            <ListItemAvatar>
                                <Avatar alt={`avatar-${index}`}
                                        src={value.avatar}
                                        variant="square"
                                />
                            </ListItemAvatar>
                            <ListItemText
                                style={{fontWeight:'bolder'}}
                                primary={value.name}
                                secondary={`Joined on ${value.created_at.toLocaleDateString('ro-RO')}`}
                            />
                            <ListItemSecondaryAction style={{}}>
                                <LabeledSelect userId={value.id}
                                               currentRole={value.roles[0].name}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </Fragment>
            ))}

        </>
    );
}

export default withInfinite(RightsManagement,['all_users'], userService.getAll)