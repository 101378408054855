import React, {useEffect, useRef, useState} from "react";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "@material-ui/core";

interface IProps {
    children?: any
}

const useStyles = makeStyles(() => ({

    hidden: {
        overflow: "hidden",
        textOverflow: 'ellipsis',
        position: "absolute",
        bottom: 0,
        top:0,
        width: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    card: {
        paddingTop: 0,
        paddingBottom: 0,
        display:'flex',
        flex: 1
    },
    hiddenParent: {
        position: 'relative',
        width: '100%',
        paddingRight:'inherit'
    },
    cardActions: {
        padding: '0px 16px'
    }
}))


export default function ShowMore({ children }: IProps) {
    const [hide, setHide] = useState(true);
    const [buttonVisibility, setButtonVisibility] = useState(true);

    const classes = useStyles();
    const contentDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = contentDiv.current;

        if (element != null) {
            const verticalOverflow = element.offsetHeight < element.scrollHeight
            setButtonVisibility(verticalOverflow);
        }
    },[])

    const togleVisibility = () => {
        setHide(!hide);
    };

    return (
        <>
            <CardContent className={classes.card}>
                <div className={hide ? classes.hiddenParent : classes.fullWidth}>
                    <div ref={contentDiv}
                         className={hide ? classes.hidden : undefined}
                         style={{whiteSpace: 'pre-wrap'}}
                    >
                        {children}
                    </div>
                </div>
            </CardContent>

            {
                buttonVisibility &&

                <CardActions className={classes.cardActions} style={{marginBottom:13, marginTop:19}}>
                    <Link component="button" variant="body2" color="textSecondary"
                          underline="always" onClick={togleVisibility}>
                        {hide ? 'Read more' : 'Read less'}
                    </Link>
                </CardActions>
            }
        </>
    )
}