import TabComponent from "../components/TabComponent";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Toolbar,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import usePreview from "../hooks/usePreview";
import BigContainer from "../components/BigContainer";
import { theme } from "../theme";
import {
  ControlledInput,
  ICOntrolledInputProps,
} from "../components/Forms/withControl";
import HookForm from "../components/HookForm";
import { useUser } from "../userContext";
import { useMutation } from "react-query";
import { userService } from "../_services/user.services";
import withRedirect from "../components/UserRoles/RedirectingPages";
import AppBar from "@material-ui/core/AppBar";
import { ReactComponent as Logo } from "../_icons/histo_logo_dash.svg";
import { useHistory } from "react-router-dom";
import RightsManagement from "../components/Lists/RightsManagement";
import Settings from "../components/Settings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  })
);

function PersInfForm() {
  const classes = useStyles2();

  const fields: ICOntrolledInputProps[] & Record<string, any> = [
    {
      name: "name",
      rules: { required: "Title required" },
      Component: ControlledInput,
      label: "Name",
      variant: "outlined",
      hint: "Will be displayed on your profile",
      defaultValues: null,
      disabled: true
    },
    {
      name: "description",
      rules: {
        required: "Description required",
        maxLength: {
          value: 400,
          message: "Description must be at most 2000 chars long",
        },
      },
      multiline: true,
      rows: 5,
      Component: ControlledInput,
      label: "About me",
      additional: 400,
      variant: "outlined",
      hint: "Will be displayed on your profile",
      defaultValues: null,
    },
  ];

  const {
    state: { user, refetch },
  } = useUser();
  const { mutateAsync } = useMutation((variables) =>
    userService.setProfile(variables)
  );
  const [loading, setLoading] = useState(false);

  const initial = {
    name: user.name,
    description: user.description,
  };

  const { dialogDispatch } = useUser();

  return (
    <HookForm
      defaultValue={initial}
      onSubmit={(data) => {
        setLoading(true);
        mutateAsync(data).then((_) => {
          refetch();
          setLoading(false);
          dialogDispatch({
            type: "success",
            payload: { succes_type: "meta_change" },
          });
        });
      }}
    >
      {(control, setValue) => (
        <>
          <Backdrop open={loading}>
            <CircularProgress />
          </Backdrop>

          <UploadImgBtn user={user} setValue={setValue} />

          <Divider
            variant="fullWidth"
            style={{ marginTop: theme.spacing(1) }}
          />

          <Box
            flexDirection="column"
            flex={1}
            display="flex"
            justifyContent="space-between"
            width="60%"
            className={classes.tab}
            style={{ marginLeft: "unset" }}
          >
            <div
              style={{
                marginBottom: "42px",
                fontWeight: 700,
                fontSize: "1.5rem",
              }}
            >
              Personal information
            </div>
            <Grid
              container
              direction="row"
              spacing={2}
              justify="center"
              alignItems="center"
            >
              {fields.map(({ Component, hint, ...value }, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={8}>
                    <Component {...value} control={control} />
                  </Grid>
                  <Grid item xs={4}>
                    <Box fontSize="1rem" height="2rem" />
                    <Box color="#9C9C9C" fontSize="0.85rem" textAlign="center">
                      {hint}
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </HookForm>
  );
}

function ResetPasswordForm() {
  const fields: ICOntrolledInputProps[] & Record<string, any> = [
    {
      name: "password",
      rules: (_: any) => {
        return {
          required: "Current password required",
        };
      },
      variant: "outlined",
      type: "password",
      Component: ControlledInput,
      label: "Current password",
      hint: "Will be displayed on your profile",
    },
    {
      name: "new_password",
      type: "password",
      rules: (_: any) => {
        return {
          required: "New password required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters long",
          },
        };
      },
      variant: "outlined",
      Component: ControlledInput,
      label: "New password",
    },
    {
      name: "new_password_repeated",
      type: "password",
      rules: (getValues: any) => {
        const password = getValues("new_password", "");

        return {
          required: "Repeat password",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters long",
          },
          validate: (value: string) => {
            return value === password || "The passwords do not match";
          },
        };
      },
      variant: "outlined",
      Component: ControlledInput,
      label: "Repeat password",
      hint:
        "New passwords must be at least 6 characters long. \n" +
        "Tip: Make it really hard to guess (not password123).",
    },
  ];

  const { mutateAsync } = useMutation((variables) =>
    userService.changePassword(variables)
  );
  const { dialogDispatch } = useUser();
  const [loading, setLoading] = useState(false);

  return (
    <HookForm
      onSubmit={(data) => {
        setLoading(true);
        mutateAsync(data).then((_) => {
          setLoading(false);
          dialogDispatch({
            type: "success",
            payload: { succes_type: "pass_change" },
          });
        });
      }}
    >
      {(control, _, getValues) => {
        return (
          <>
            <Backdrop open={loading}>
              <CircularProgress />
            </Backdrop>

            <Grid container direction="row" spacing={2}>
              {fields.map(({ Component, hint, rules, ...value }, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <Component
                      {...value}
                      control={control}
                      rules={rules(getValues)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="flex-start"
                    direction="column"
                  >
                    <Box fontSize="1rem" height="2rem" />
                    <Box
                      color="#9C9C9C"
                      fontSize="0.85rem"
                      whiteSpace="pre-wrap"
                    >
                      {hint}
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </>
        );
      }}
    </HookForm>
  );
}

function UploadImgBtn({ user, setValue }: { user: any; setValue: any }) {
  const classes = useStyles();
  const { url, handleUploadClick } = usePreview(user.avatar);

  const handleUpload = (e: any) => {
    setValue("avatar", e.target.files[0]);
    handleUploadClick(e);
  };

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="flex-start"
      style={{ marginBottom: "5%" }}
    >
      <Grid item xs={2}>
        <Avatar className={classes.large} variant="square" src={url} />
      </Grid>
      <Grid item container direction="column" xs={5} spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            component="label"
            style={{
              textTransform: "capitalize",
              color: "black",
              fontWeight: 500,
            }}
          >
            Choose file
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleUpload}
              hidden
            />
          </Button>
        </Grid>
        <Grid item>
          Your nft size should be optimally 300x300 px. Supported formats:
          jpg, png with maximum file size of 500 kb.
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles2 = makeStyles(
  createStyles({
    paper: {
      height: "100%",
      borderRadius: 5,
    },
    growSection: {
      // height: `calc(100vh - ${theme.spacing(1) + 2 * theme.spacing(3)}px - 2rem )`,
      height: `calc(100vh - ${
        theme.spacing(1) + theme.spacing(3)
      }px - 2rem - 10px
                                - 90px)`,
      background: "white",
    },
    tab: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(7),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  })
);

function PersonalProfilePage() {
  const classes = useStyles2();

  const history = useHistory();

  const {
    state: { user, refetch },
  } = useUser();

  console.log("User", user);

  const role: string = user.roles[0].name;

  function goHome() {
    history.push("/");
  }

  const itemsLabel = ["Profile settings", "Account settings"];

  if (role === "admin") {
    itemsLabel.push("Interface settings");
    itemsLabel.push("Rights management");
  }

  return (
    <BigContainer>
      <AppBar position="fixed" color="transparent" elevation={0}>
        <Box marginLeft="15px" marginTop="10px">
          <Logo onClick={goHome} />
        </Box>
      </AppBar>

      <Toolbar />
      <Box
        className={classes.growSection}
        display="flex"
        flexDirection="column"
      >
        <TabComponent itemsLabel={itemsLabel}>
          <Box
            flexDirection="column"
            flex={1}
            display="flex"
            justifyContent="space-between"
            className={classes.tab}
            style={{ marginLeft: "5%" }}
          >
            <Box
              fontWeight={700}
              fontSize="1.5rem"
              style={{ marginBottom: 42 }}
            >
              Profile picture
            </Box>

            <PersInfForm />
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                form="myform"
                style={{ height: "45px", width: "167px" }}
              >
                Save changes
              </Button>
            </Box>
          </Box>

          <Box
            flexDirection="column"
            flex={1}
            display="flex"
            justifyContent="space-between"
            className={classes.tab}
            style={{ marginLeft: "5%" }}
          >
            <Box>
              <Box
                fontWeight={700}
                fontSize="1.5rem"
                style={{ marginBottom: theme.spacing(12) }}
              >
                Change password
              </Box>
              <Box display="flex" flexDirection="column" width="70%">
                <ResetPasswordForm />
              </Box>
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                form="myform"
                style={{ height: "45px", width: "167px" }}
              >
                Save changes
              </Button>
            </Box>
          </Box>
          {role === "admin" ? (
            <Box
              flexDirection="column"
              flex={1}
              display="flex"
              justifyContent="space-between"
              className={classes.tab}
              style={{ marginLeft: "5%" }}
            >
              <Settings />
            </Box>
          ) : null}
          {role === "admin" ? <RightsManagement /> : null}
        </TabComponent>
      </Box>
    </BigContainer>
  );
}

function userNotAuth(user: any) {
  return !user;
}

export default withRedirect(PersonalProfilePage, userNotAuth, "/login");
