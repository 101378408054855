import { Avatar, Box, Divider, Link } from "@material-ui/core";
import SpreadWithMiddle from "../SpreadWithMiddle";
import React, { useState } from "react";
import { useUser } from "../../userContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import { userService } from "../../_services/user.services";

interface Iprops {
  notif: any;
}

function time_difference(date1: Date) {
  const diff = moment.duration(new Date().getTime() - date1.getTime());
  return diff.humanize();
}

export default function NotificationItem({ notif }: Iprops) {
  const [isHovered, setIsHovered] = useState(false);
  const { dialogDispatch } = useUser();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation(userService.start_review);

  const { mutate } = useMutation(
    // @ts-ignore
    userService.readNotifications,
    {
      onMutate: async () => {
        await queryClient.cancelQueries("notifications");

        const previousValue = queryClient.getQueryData("notifications");

        queryClient.setQueryData("notifications", (input) => {
          Object.assign(
            // @ts-ignore
            input.find((value: any) => value.id === notif.id),
            { read_at: new Date() }
          );
          return input;
        });

        return previousValue;
      },
      onError: (error, variables, { previousValue }) => {
        if (previousValue)
          queryClient.setQueryData("notifications", previousValue);
      },
      onSettled: () => {
        queryClient.invalidateQueries("notifications");
      },
    }
  );

  function onNotificationClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    const { advice, reason, entity, status, type } = notif.data;

    mutate({ id: notif.id });
    if (status === "progress") event.stopPropagation();
    else if (notif.type === "App\\Notifications\\ReviewedContent") {
      if (status === "rejected")
        dialogDispatch({
          type: "user_contest",
          payload: {
            entity: {
              entity: { ...entity, notif_id: notif.id },
              status: status,
              advice: advice,
              reason: reason,
              type: type,
            },
          },
        });
      else history.push(`details/${type}/${notif.data.id}`);
    } else if (notif.type === "App\\Notifications\\NewContent") {
      dialogDispatch({ type: "wait_review", payload: { entity: entity } });
      mutateAsync({ id: entity.id, type: entity.type })
        .then((value) => {
          dialogDispatch({
            type: "review",
            payload: { entity: entity, timeout: value.timeout },
          });
        })
        .catch((_) => {
          dialogDispatch({
            type: "success",
            payload: { succes_type: "already_reviewed" },
          });
        });
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        width="420px"
        padding="12px"
        style={{
          // backgroundColor: notif.read_at !== null ? "#272727" : undefined,
          backgroundColor: isHovered ? "#272727" : undefined,
          opacity: notif.read_at !== null ? 0.5 : 1,
          borderRadius: 8,
          paddingBottom: 0,
        }}
        onClick={onNotificationClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box display="flex" flexDirection="row">
          <Box
            display="flex"
            flex={1}
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              fontSize: 14,
              color: "#dedede",
              fontWeight: 400,
            }}
          >
            {notif.data.message}
          </Box>

          {(notif.data.img_url || notif.data.entity?.img_url) != null && (
            <Avatar
              style={{
                width: 40,
                height: 40,
                marginLeft: 35,
              }}
              variant="square"
              src={notif.data.img_url || notif.data.entity?.img_url}
            />
          )}
          {(notif.data.img_url || notif.data.entity?.img_url) == null && (
            <div
              style={{
                width: 40,
                height: 40,
                marginLeft: 35,
              }}
            />
          )}
        </Box>

        <SpreadWithMiddle
          Left={(classname) => (
            <Box
              color="#DEDEDE"
              className={classname}
              style={{ opacity: "20%", fontSize: 14, fontWeight: 400 }}
            >
              {`${time_difference(notif.created_at)} ago`}
            </Box>
          )}
          Right={(classname) =>
            notif.data.in_progress ? (
              <Box color="#52FF97" className={classname}>
                Review in progress
              </Box>
            ) : notif.type === "App\\Notifications\\NewContent" ? (
              <Link
                underline="always"
                className={classname}
                style={{
                  color: "#CFCFCF",
                  fontWeight: 700,
                  fontSize: 14,
                }}
              >
                Click to review
              </Link>
            ) : null
          }
        />
      </Box>
      {/*Flickering issue*/}
      {/*<Divider light style={{backgroundColor: '#333333', width:'100%', marginTop: isHovered ? 14 : 0}}/>*/}
      <Divider
        light
        style={{
          backgroundColor: "#333333",
          width: "100%",
          marginTop: isHovered ? 0 : 0,
        }}
      />
    </Box>
  );
}
