import React from "react";
import {Button, Grid, Link, Typography} from "@material-ui/core";
import {ControlledInput, ICOntrolledInputProps} from "../components/Forms/withControl";
import HookForm from "../components/HookForm";
import LogHOC from "../components/LogHOC";
import {withLoginRedirect} from "../components/UserRoles/RedirectingPages";
import {Link as RouterLink} from "react-router-dom";
import {apiClient, authenticationService} from "../_services/authentication.services";
import {useUser} from "../userContext";
import {useMutation} from "react-query";

function LoginForm( ) {
    const fields: ICOntrolledInputProps[]  & Record<string, any> = [
        {
            name: "email",
            placeholder: "your email address",
            rules: {required: 'First name required'},
            Component: ControlledInput,
            label: 'Email'

        }, {
            name: "password",
            type: "password",
            placeholder: "********",
            rules: {required: 'Password required', minLength: { value: 8, message: "Password must be at least 8 chars long"}},
            Component: ControlledInput,
            label: 'Password'
        }
    ]

    const {state, dispatch} = useUser()

    const {mutateAsync} = useMutation( variables => authenticationService.login(variables))


    function onSubmit(data: any) {
        dispatch({type: 'login'})
        mutateAsync(data).then(value => {
            localStorage.setItem("token", value.access_token);
            apiClient.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`
            dispatch({type: 'success'})
            if(state.refetch)
                state.refetch();
        });
    }

    return (
        <>
            <HookForm onSubmit={onSubmit} >
                {(control) =>
                    <Grid container direction="column" spacing={2} style={{marginLeft:'-2px'}}>
                        {fields.map(({Component, ...value}, index) =>
                            <Grid item key={index}>
                                <Component {...value} control={control} style={{marginBottom: '16px'}} />
                            </Grid>
                        )}
                    </Grid>
                }
            </HookForm>
            <Grid item container justify="flex-end">
                <Grid item>
                    <Link
                        component={RouterLink}
                        underline='always'
                        color='textSecondary'
                          to="/reset">
                        Forgot my password!
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}

function Submit() {
    return (
        <div style={{alignItems:'baseline',display:'flex'}}>
            <Grid item>
                <Button color='primary'
                        variant="contained"
                        type="submit"
                        form='myform'
                        style={{width:'153px', fontSize: '14px', letterSpacing: '-0.005em'}}
                >
                    Login
                </Button>
            </Grid>
            <Typography style={{marginRight: 3,marginLeft:29, fontFamily: 'Mulish Bold', letterSpacing: '0.3px'}}>Don't have an account?</Typography>
            <Link  underline='always'
                    style={{fontWeight:'bold', fontSize:'18px', letterSpacing: '0.3px'}}
                   color='textPrimary'
                   component={RouterLink}
                   to="/signup">
                Register
            </Link>
        </div>
    )
}

function LoginPage() {
    return (
        <LogHOC Form={LoginForm} welcomeMsg="Login to continue" Submit={Submit}/>
    );
}

export default withLoginRedirect(LoginPage);