import {apiClient} from "./authentication.services";


export const timelineService = {
    getAll,
    postTimeline
};

export interface Timeline {
    id: number
    name: string
}

async function getAll( ): Promise<Timeline[]> {
    const res = await apiClient.get('/api/timeline');

    return res.data
}

async function postTimeline(data: any) {
    const res = await apiClient.post('/api/timeline', data);

    return res.data
}