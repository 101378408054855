import { Avatar, Box, Grow, Popover, SvgIcon } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import { ReactComponent as Fallback } from "../../_icons/no_pic_event.svg";
import HoveredCard from "./HoveredCard";
import { AvatarGroup } from "@material-ui/lab";
import { ReactComponent as NewImage } from "../../_icons/add_img.svg";
import moment from "moment";
import Bubble from "../Bubble/Bubble";
import useClickOutside from "../ClickOutside";

const useStyles = makeStyles(() =>
  createStyles({
    unopened: {
      width: 32,
      height: 32,
    },

    detailsBox: {
      borderRadius: 4,
      width: 120,
      backgroundColor: "white",
      borderColor: "black",
      borderWidth: 1,
      // overflow: 'hidden'
      whiteSpace: "initial",
      wordBreak: "break-all",
      padding: 10,
    },

    triangleUp: {
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderBottom: "12px solid white",
    },

    popover2: {
      pointerEvents: "none",
    },
    popoverContent2: {
      pointerEvents: "auto",
    },
    hoveredGroupHorizontal: {
      width: 37,
      height: 37,
      marginRight: 15,
      border: 0,
      borderRadius: 0,
    },
  })
);

export function SingleTimelineEvent({
  event: { content, history, dispatch },
  single = true,
  setHeepOpen,
  first = false,
}: any) {
  const classes = useStyles();

  const { date, title, img_url, id } = content;

  function addImage() {
    closeBoth();
    dispatch({ type: "image", payload: { event_id: id, entity_title: title } });
  }

  const [openedPopover, setOpenedPopover] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);

  const [popoverContent, setPopoverContent] = useState({});
  const [openedImagesPopover, setOpenedImagesPopover] = useState(false);
  const [eventOpened, setEventOpened] = useState(false);
  const [avatarHovered, setAvatarHovered] = useState(false);

  let hasSpaceToRight: boolean = false;

  //@ts-ignore
  const timelineWidth = document
    .getElementById("timelineBox")
    ?.getBoundingClientRect().width;

  const popoverAnchor = useRef<any>(null);

  const domElement = popoverAnchor.current;

  if (domElement && timelineWidth) {
    const clientRect = domElement.getBoundingClientRect();
    if (timelineWidth - clientRect.x > 250) {
      hasSpaceToRight = true;
    } else {
      hasSpaceToRight = false;
    }
  }

  useClickOutside((e: any) => {
    if (typeof e.target.className === "string") {
      if (
        !e.target.className.includes("MuiBox-root") &&
        !e.target.className.includes("MuiButton") &&
        !e.target.className.includes("MuiSvgIcon-root") &&
        !e.target.className.includes("MuiAvatarGroup-root") &&
        !e.target.className.includes("MuiLink-root")
      ) {
        closeBoth();
      }
    } else {
      closeBoth();
    }
  }, popoverAnchor);

  const onAvatarClick = () => {
    openImagePopover();
    setEventOpened(true);
    setKeepOpen(true);
  };

  const popoverEnter = (keep?: boolean) => {
    setOpenedPopover(true);
  };

  function popoverEnterWrapper() {
    popoverEnter();
  }

  const popoverLeave = (keep?: boolean) => {
    setOpenedPopover(false);
    setOpenedImagesPopover(false);
  };

  function popoverLeaveWrapper() {
    popoverLeave();
  }

  function closeBoth() {
    setOpenedPopover(false);
    setOpenedImagesPopover(false);
    setEventOpened(false);
    // const nodes = document.getElementsByClassName(
    //   "MuiAvatar-root MuiAvatar-square"
    // );
    // console.log("fired");
    // for (const c of nodes) {
    //   if (c.className.includes("-unopened-")) {
    //     if (c.className !== popoverAnchor.current.className) {
    //       const parentNode = c.parentNode;
    //       //@ts-ignore
    //       parentNode.style.opacity = "1";
    //     }
    //   }
    // }
  }

  function closeBoth2() {
    setOpenedPopover(false);
    setOpenedImagesPopover(false);
    // setEventOpened(false);
    setKeepOpen(false);
  }

  function openImagePopover() {
    if (
      content.accepted_images.length === 0 &&
      localStorage.getItem("token") === null
    )
      return;

    setOpenedPopover(false);
    setOpenedImagesPopover((prevState) => {
      if (setHeepOpen) setHeepOpen(!prevState);
      return !prevState;
    });
  }

  function closeImagesPopover() {
    setTimeout(() => {
      setOpenedImagesPopover(false);
    }, 2000);
  }

  <Popover
    className={classes.popover2}
    classes={{
      paper: classes.popoverContent2,
    }}
    open={openedPopover}
    anchorEl={popoverAnchor.current}
    PaperProps={{
      onMouseEnter: () => {
        popoverEnter(keepOpen);
      },
      onMouseLeave: () => {
        popoverLeave(keepOpen);
      },
      style: {
        padding: 15,
        backgroundColor: "transparent",
        boxShadow: "none",
        zIndex: 100,
      },
    }}
    anchorOrigin={
      openedImagesPopover || keepOpen || single
        ? {
            vertical: "bottom",
            horizontal: "center",
          }
        : {
            vertical: "center",
            horizontal: hasSpaceToRight ? "right" : "left",
          }
    }
    transformOrigin={
      openedImagesPopover || keepOpen || single
        ? {
            vertical: "top",
            horizontal: "center",
          }
        : {
            vertical: "center",
            horizontal: hasSpaceToRight ? "left" : "right",
          }
    }
    TransitionComponent={Grow}
    transitionDuration="auto"
  >
    <HoveredCard content={popoverContent} history={history} close={closeBoth} />
  </Popover>;
  const clickSeeAll = () => {
    history.push(`/details/event/${content.id}`);
  };

  window.addEventListener("scroll", closeBoth);
  window.addEventListener("mouseenter", closeBoth);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      height="100%"
      alignSelf="center"
      zIndex={9999}
      onWheel={() => {
        closeBoth();
      }}
      onMouseLeave={closeBoth}
    >
      <Avatar
        variant="square"
        className={classes.unopened}
        style={{
          marginTop: single || first ? 0 : 16,
        }}
        src={img_url}
        onMouseEnter={() => {
          popoverEnter();
          setPopoverContent(content);
        }}
        onMouseLeave={() => {
          popoverLeave(keepOpen);
          closeBoth2();
        }}
        onClick={onAvatarClick}
        onWheel={() => {
          closeBoth();
        }}
        ref={popoverAnchor}
      >
        <SvgIcon viewBox="0 0 32 32" className={classes.unopened}>
          <Fallback />
        </SvgIcon>
      </Avatar>

      {single && !openedPopover && (
        <>
          <Bubble>
            <Box fontWeight={700}>{moment(date).format("MMM DD")}</Box>
            <Box>{title}</Box>
          </Bubble>
        </>
      )}

      <Popover
        className={classes.popover2}
        classes={{
          paper: classes.popoverContent2,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        PaperProps={{
          onMouseEnter: () => {
            popoverEnterWrapper();
            var straightLine =
              document.getElementsByClassName("straightLine")[0];

            if (straightLine) {
              straightLine.remove();
            }
          },
          onMouseLeave: () => {
            popoverLeave(keepOpen);
          },
          style: {
            marginTop: 15,
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: 0,
            padding: 15,
          },
        }}
        anchorOrigin={
          openedImagesPopover || keepOpen || single
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: hasSpaceToRight ? "right" : "left",
              }
        }
        transformOrigin={
          openedImagesPopover || keepOpen || single
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: hasSpaceToRight ? "left" : "right",
              }
        }
        TransitionComponent={Grow}
        transitionDuration="auto"
      >
        <HoveredCard
          content={popoverContent}
          history={history}
          close={closeBoth}
        />
      </Popover>

      <Popover
        className={classes.popover2}
        classes={{
          paper: classes.popoverContent2,
        }}
        open={openedImagesPopover}
        anchorEl={popoverAnchor.current}
        id="images-popover"
        PaperProps={{
          onMouseEnter: () => {
            if (!keepOpen) return;
            setOpenedImagesPopover(true);
            var straightLine =
              document.getElementsByClassName("straightLine")[0];
            if (straightLine) {
              straightLine.remove();
            }
          },
          onMouseLeave: () => {
            if (keepOpen) return;
            setOpenedImagesPopover(false);
            setKeepOpen(false);
          },
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: "15px 15px 15px 15px",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: hasSpaceToRight ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: hasSpaceToRight ? "left" : "right",
        }}
        TransitionComponent={Grow}
        transitionDuration="auto"
      >
        <Box
          display="flex"
          flexDirection="row"
          style={{
            backgroundColor: "#333238",
            height: 45,
            alignItems: "center",
            marginLeft: "4%",
          }}
        >
          {content.accepted_images.length != 0 && (
            <>
              <AvatarGroup
                classes={{ avatar: classes.hoveredGroupHorizontal }}
                style={{
                  backgroundColor: "transparent",
                  marginLeft: 5,
                  padding: "15px 0px 15px 0px",
                }}
                max={5}
                spacing="medium"
              >
                {content.accepted_images.map((value: any, index: number) => (
                  <Avatar
                    key={`image=${index}`}
                    style={{
                      width: 37,
                      height: 37,
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                    variant="rounded"
                    src={value.img_url}
                    onMouseEnter={() => {
                      const img = value;
                      img.event_id = id;
                      setPopoverContent(img);
                      setKeepOpen(true);
                      popoverEnter(true);
                    }}
                  />
                ))}
              </AvatarGroup>
              <Box
                onClick={clickSeeAll}
                style={{
                  width: 42,
                  height: 32,
                  marginRight: 5,
                  marginLeft: 5,
                  background: "#F1F1F2",
                  fontSize: 11,
                  fontWeight: "bold",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "1px",
                  color: "black",
                  cursor: "pointer",
                  lineHeight: 1,
                }}
              >
                <span>
                  See <br /> all
                </span>
              </Box>
            </>
          )}
          {localStorage.getItem("token") !== null && (
            <Avatar
              key={`imageAdd`}
              style={{
                width: 37,
                height: 37,
                marginRight: 9,
                backgroundColor: "transparent",
                marginLeft: 7,
                borderRadius: "4px",
              }}
              variant="rounded"
              onClick={addImage}
            >
              <NewImage />
            </Avatar>
          )}
        </Box>
      </Popover>
    </Box>
  );
}
