import { ReactComponent as Logo } from "../../_icons/histo_logo.svg";
import List from "@material-ui/core/List";
import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { timelineService } from "../../_services/timeline.services";
import { useUser } from "../../userContext";
import { useHistory } from "react-router-dom";

export const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: "#F1F1F2",
    },
    drawerPaper: {
      width: drawerWidth,
      borderRadius: 4,
      maxHeight: `calc(100vh - ${2 * theme.spacing(1)}px)`,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      background: "#333238",
      overflowY: "hidden",
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      marginTop: 20,
      marginBottom: 41,
      marginLeft: 35,
      marginRight: 50,
      display: "flex",
      justifyContent: "center",
    },

    checkbox: {
      "& .MuiSvgIcon-root": {
        color: "white",
      },
    },
    list: {
      display: "flex",
      flex: 1,
      overflowY: "auto",
      flexDirection: "column",
      fontSize: "0.875rem",
    },
    listItem: {
      transition: ".2s",
      "&:hover": {
        background: "#272727",
      },
    },
    checkBoxLabel: {
      justifyContent: "space-between",
      flex: 1,
      color: "#F7F7F7",
      fontSize: "0.875rem",
      letterSpacing: "0.03px",
    },

    checkBoxLabel2: {
      justifyContent: "space-between",
      flex: 1,
      fontWeight: 700,
      color: "#F7F7F7",
      fontSize: "0.875rem",
      letterSpacing: "0.03px",
    },
    divider: {
      backgroundColor: "#404040",
    },
  })
);

interface IProps {
  setCheckboxes: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function TimelinesFilter({ setCheckboxes }: IProps) {
  const classes = useStyles();
  const { data } = useQuery("timelines", timelineService.getAll);
  const {
    state: { user },
    dialogDispatch,
  } = useUser();
  const [setAll, setSetAll] = useState(true);
  const [boxes, setBoxes] = useState(new Array(data?.length).fill(true));
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const newBoxes = Array.from({ length: data.length }, (e, i) => i);
      setCheckboxes(newBoxes);
    }
  }, [data]);

  function allThreadsClick() {
    if (!data) {
      return;
    }
    setSetAll((prevState) => {
      setBoxes(new Array(data?.length).fill(!prevState));
      if (prevState === false) {
        const newBoxes = Array.from({ length: data.length }, (e, i) => i);
        setCheckboxes(newBoxes);
      } else {
        setCheckboxes([]);
      }

      return !prevState;
    });
  }

  function addTimeline() {
    dialogDispatch({ type: "timeline" });
  }

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    setCheckboxes((prevState) => {
      const val = parseInt(event.target.value);
      const nextState = Array.from(prevState);

      if (event.target.checked) nextState.push(val);
      else {
        const index = nextState.indexOf(val);
        nextState.splice(index, 1);
      }
      return nextState;
    });
  }

  function refreshPage() {
    history.push("/");
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Logo onClick={refreshPage} />
      </div>

      <List className={classes.list}>
        <Divider className={classes.divider} />

        <ListItem className={classes.listItem}>
          <FormControlLabel
            style={{
              fontSize: "0.875rem",
              marginLeft: "4px",
              fontWeight: "bolder",
            }}
            className={classes.checkBoxLabel2}
            control={
              <Checkbox
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M5 19h14V5H5v16z"
                      fill="white"
                      strokeWidth="0.8px"
                      stroke="white"
                    />
                    <path
                      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z "
                      fill="white"
                    />
                  </svg>
                }
                checkedIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M5 19h14V5H5v14zm2.41-7.4l2.58 2.58 6.59-6.59L17.99 9l-8 8L6 13.01l1.41-1.41z"
                      fill="white"
                      strokeWidth="0.8px"
                      stroke="white"
                    />
                    <path
                      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z "
                      fill="white"
                    />
                    <path
                      d="M18 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
                      fill="black"
                    />
                  </svg>
                }
                checked={setAll}
                onChange={allThreadsClick}
                className={classes.checkbox}
              />
            }
            label={
              <Typography style={{ fontWeight: 700 }}>All Threads</Typography>
            }
            labelPlacement="start"
          />
        </ListItem>

        {data?.map((value, index) => (
          <React.Fragment key={`timeline-${index}`}>
            <Divider className={classes.divider} />

            <ListItem className={classes.listItem}>
              <FormControlLabel
                style={{ fontSize: "0.875rem", marginLeft: "4px" }}
                className={classes.checkBoxLabel}
                control={
                  <Checkbox
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                          d="M5 19h14V5H5v14z"
                          fill="white"
                          strokeWidth="0.8px"
                          stroke="white"
                        />
                        <path
                          d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z "
                          fill="white"
                        />
                      </svg>
                    }
                    checkedIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                          d="M5 19h14V5H5v14zm2.41-7.4l2.58 2.58 6.59-6.59L17.99 9l-8 8L6 13.01l1.41-1.41z"
                          fill="white"
                          strokeWidth="0.8px"
                          stroke="white"
                        />
                        <path
                          d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z "
                          fill="white"
                        />
                        <path
                          d="M18 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
                          fill="black"
                        />
                      </svg>
                    }
                    className={classes.checkbox}
                    value={value.id}
                    checked={boxes[index]}
                    onChange={(e) => {
                      const new_checks = [...boxes];
                      new_checks[index] = !boxes[index];
                      setBoxes(new_checks);

                      var alltrue = !/false/i.test(new_checks.join(""));
                      var allfalse = !/true/i.test(new_checks.join(""));

                      setSetAll(alltrue || allfalse);
                      handleCheckbox(e);
                    }}
                  />
                }
                label={value.name}
                labelPlacement="start"
              />
            </ListItem>
          </React.Fragment>
        ))}

        <Divider className={classes.divider} />
      </List>

      {user?.roles?.length > 0 && user?.roles[0].name === "admin" && (
        <Box display="flex" justifyContent="center" style={{ color: "white" }}>
          <Button onClick={addTimeline}>Add new timeline</Button>
        </Box>
      )}
    </Drawer>
  );
}
