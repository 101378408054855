import {apiClient} from "./authentication.services";


export const entityService = {
    getNoReview,
    getCurated,
    getProgress
};


async function getNoReview(data: any) {
    const filters = data.queryKey[1];

    const url = data.pageParam === undefined ? `/api/no_review` : data.pageParam;
    const res = await apiClient.get(url, {
        params: {
            filters: filters
        }
    });

    return res.data
}

async function getCurated(data: any) {
    const filters = data.queryKey[1];

    const url = data.pageParam === undefined ? `/api/curated` : data.pageParam;

    const res = await apiClient.get(url, {
        params: {
            filters: filters
        }
    });
    res.data.data = Object.values(res.data.data)

    return res.data
}

async function getProgress(data: any) {
    const filters = data.queryKey[1];

    const url = data.pageParam === undefined ? `/api/progress` : data.pageParam;

    const res = await apiClient.get(url, {
        params: {
            filters: filters
        }
    });

    return res.data
}