import {apiClient} from "./authentication.services";
import {IDetails} from "../pages/DetailsPage";


export const eventService = {
    getEventDetails,
    getRelatedImages,
    postEvent,
    getAll
};

async function getEventDetails(data: any) {
    const id = data.queryKey[1].id;

    const res = await apiClient.get(`/api/event/${id}`);

    return res.data
}

async function getRelatedImages(id = 0, image_id: number,  url = `/api/event/${id}/images`) {
    const res = await apiClient.get(url, {
        params: {
            exclude_id: image_id
        }
    });


    return res.data;
}

async function postEvent(data: any) {
    const res = await apiClient.post('/api/event', data);
    return res.data;
}

async function getAll(data: any) {
    const res = await apiClient.get('/api/event');
    return res.data;
}