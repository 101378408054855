import React, {CSSProperties, ReactNodeArray, useState} from "react";
import {Box, Tab, Tabs} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: any
    value: any
    style?: CSSProperties
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    if (value !== index)
        return null;

    return (
        <Box flex={1} flexDirection='column' display="flex"
             role="tabpanel"
             hidden={value !== index}
             id={`full-width-tabpanel-${index}`}
             aria-labelledby={`full-width-tab-${index}`}
             overflow='auto'
             {...other}
        >
            {children}
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        tab: {
            alignItems: 'baseline',
        },
        tabPanel: {
            overflow: "auto"
        },
        hideScroll: {
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            scrollbarWidth: 'none',
        }
    }));

interface IProps {
    itemsLabel: string[],
    children: ReactNodeArray
}

export default function TabComponent({itemsLabel, children}: React.PropsWithChildren<IProps>) {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const  classes = useStyles();

    if (itemsLabel.length === 1) {
        return (
            <>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    variant="fullWidth"
                    TabIndicatorProps={{style: {background:'black'}}}
                >
                    <Tab classes={{wrapper: classes.tab}} label={itemsLabel[0]}/>
                </Tabs>

                <TabPanel value={value} index={0}>
                    {children}
                </TabPanel>
            </>
        )
    }

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                TabIndicatorProps={{style: {background:'black'}}}
            >
                {itemsLabel.map((label, index) =>
                    <Tab style={{ textTransform: 'capitalize', fontSize: 16, minHeight: 57 }} label={label} key={index}/>
                )}
            </Tabs>

            {children.map((node, index) =>
                <TabPanel index={index} value={value} key={`tab${index}`}  {...{className: classes.hideScroll }} >
                    {node}
                </TabPanel>)}
        </>
    )
}