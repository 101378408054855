import {Button} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {PropsWithChildren} from "react";

export type classNameMap =
    "eventType"
    | "rejected"
    | "approved"
    | "user"
    | "curator"
    | "admin"
    | 'progress'
    | 'contested';


interface IProps {
    variant?: classNameMap
    size?: "small" | "medium" | "large"
}

const useStyles = makeStyles(() =>
    createStyles({
        eventType: {
            borderBlockColor: "#000000",
            backgroundColor: "#ffffff",
            '--text-color': "#4B4B4B"
        },
        rejected: {
            backgroundColor: "#FFA3A3",
            '--text-color': "#CE1F1F"

        },
        approved: {
            backgroundColor: "#8BF5A9",
            '--text-color': "#2BB051"
        },
        user: {
            backgroundColor: "#FFC656",
            '--text-color': "#000000"
        },
        curator: {
            background: "#6AA6FF",
            '--text-color': "#000000"
        },
        contested: {
            background: "#F5EB8B",
            '--text-color': "#AC9F26"
        },
        progress: {
            backgroundColor: "#FFF069",
            '--text-color': "#58510C"
        },
        admin: {
            backgroundColor: "#A378FF",
            '--text-color': "#000000"
        },
        disabled: {
            "&$disabled": {
                color: 'var(--text-color)'
            }
        }
    }));


export default function StatusButton(
    {children, variant="eventType", size, ...rest}: PropsWithChildren<IProps & Record<string, any>>  ) {
    const classes = useStyles();

    return (
        <Button size={size}  classes={{ root: classes[variant], disabled: classes.disabled }}
                variant="outlined" disabled {...rest} >
            {children}
        </Button>
    );
}
