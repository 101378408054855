import {Grid, Typography} from "@material-ui/core";
import login_img from "../pages/login_img.png";
import React, {JSXElementConstructor} from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: "100vh",
            // maxHeight: "90%",
            width: "100%",
            maxWidth: "100vw"
        },
        img: {
            height: "90vh",
            maxHeight: "100vh",
            width: "90%"
        },
        mainHeading: {
            fontFamily: 'Spartan Bold',
            letterSpacing: '7.3px',
            fontSize: '30px'
        }
    }),
);

interface Props {
    Form: JSXElementConstructor<any>
    Submit: JSXElementConstructor<any>
    welcomeMsg: string
}

export default function LogHOC({ Form, welcomeMsg, Submit}: Props) {
    const classes = useStyles();

    return (
        <Grid container
              justify="space-between"
              alignItems="center"
              spacing={1}
              className={classes.root}
              style={{background:'white'}}
        >
            <Grid item xs={6}>
                <img className={classes.img} alt="complex" src={login_img}/>
            </Grid>
            <Grid item xs={6} container
                  direction="column"
                  alignItems="flex-start"
                  style={{height: "90vh"}}>

                <Grid item>
                    <Typography variant="h1" className={classes.mainHeading} style={{marginBottom:101}}>
                        HISTOGRAPH
                    </Typography>
                </Grid>
                <Grid item style={{marginBottom:34}}>
                    <Typography variant="h5" className={'fw7'} style={{letterSpacing: '0.2px'}}>
                        Welcome!
                    </Typography>
                    <Typography variant="h6" style={{letterSpacing: '0.2px'}}>
                        {welcomeMsg}
                    </Typography>
                </Grid>

                <Grid item container direction="column" spacing={2} xs={6}>
                    <Form/>
                </Grid>


                <Grid item container direction="row" alignItems="center" spacing={2}>
                    <Submit/>
                </Grid>
            </Grid>
        </Grid>
    );
}