import  {useEffect} from 'react'

export default function useIntersectionObserver({
                                                    root=null,
                                                    target,
                                                    onIntersect,
                                                    threshold = 1.0,
                                                    rootMargin = '0px',
                                                    enabled = true,
                                                }: {root?: any, target: any, onIntersect: any, threshold?: number, rootMargin?: string, enabled?: boolean }) {
    useEffect(() => {
        if (!enabled) {
            return
        }

        const observer = new IntersectionObserver(
            entries =>
                entries.forEach(entry => entry.isIntersecting && onIntersect()),
            {
                root: root && root.current,
                rootMargin,
                threshold,
            }
        )

        const el = target && target.current

        if (!el) {
            return
        }

        observer.observe(el)

        return () => {
            observer.unobserve(el)
        }
    }, [target.current, enabled])
}
