import {PropsWithChildren} from "react";
import {Box, Container, SvgIcon} from "@material-ui/core";
import {ReactComponent as Histograph} from "../_icons/histo.svg";
import {ReactComponent as Twitter} from "../_icons/twitter.svg";
import {ReactComponent as Discord} from "../_icons/discord.svg";
import {ReactComponent as Telegram} from "../_icons/telegram.svg";
import {makeStyles} from "@material-ui/core/styles";
import {theme} from "../theme";

const useStyles = makeStyles({
    normalIcon: {
        fontSize: '2em',
        marginLeft: theme.spacing(5)
    },
    container: {
        minHeight: `calc(100vh -  ${ theme.spacing(1) + theme.spacing(6) }px) `,
        display: 'flex',
        flexDirection: 'column',
    },
    containerRoot: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(6),
        

        minHeight: `calc(100vh - ${ theme.spacing(1) + theme.spacing(6) }px) `,
        background: '#F1F1F1'
    }
})

interface IProps {
    background?: boolean
    style?: CssProps
}

export default function BigContainer({children, background=false, style}: IProps & PropsWithChildren<any>) {
    const classes = useStyles();

    return (
        <div className={classes.containerRoot} style={style}>
            <Container maxWidth="lg" className={classes.container} style={background ? {background: 'white'} : {}}>

                <Box flex={1} >
                        {children}
                </Box>
                <Box
                    textAlign='center'
                    display="flex"
                    justifyContent="space-between"
                    // paddingBottom='5%'
                        // padding="5%"
                >
                    <Box display='flex' textAlign='center' style={{marginTop:67, marginBottom:44}} >
                        <SvgIcon
                            style={{fontSize: '2em'}}
                            component={Histograph}
                            viewBox='0 0 32 32'
                        />
                        <Box  color='#989BAE' style={{marginLeft: theme.spacing(1)}} display='flex' justifyContent='center' flexDirection="column">
                            © Histograph . All rights reserved
                        </Box>
                    </Box>
                    <Box  style={{marginTop:67, marginBottom:44}}
                        display="flex"
                        justifyContent='space-between'
                    >
                        <Box color='#989BAE' display='flex' justifyContent='center' flexDirection="column">
                            Keep in touch with us
                        </Box>
                        <SvgIcon
                            className={classes.normalIcon}
                            component={Twitter}
                            viewBox='0 0 29 24'
                        />
                        <SvgIcon
                            className={classes.normalIcon}
                            component={Discord}
                            viewBox='0 0 21 24'
                        />
                        <SvgIcon
                            className={classes.normalIcon}
                            component={Telegram}
                            viewBox='0 0 28 24'
                        />
                    </Box>
                </Box>

            </Container>
        </div>
    )
}