import ErrorIndicator from "./ErrorIndicator";

import React, { Component, ErrorInfo, ReactNode, Fragment } from "react";

interface Props {
    children: ReactNode;
    reset: () => void
}

interface State {
    isError: boolean
    message: string
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        isError: false,
        message: ''
    };

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { isError: true, message: error.message };
    }

    public componentDidCatch(error: any, errorInfo: ErrorInfo) {
        this.setState(({ message: error.response?.data.message || error.message, isError: true  }))
    }

    public resetError() {
        this.setState({ isError: false, message: "" })
        this.props.reset()
    }

    public render() {


        return (
            <Fragment >
                <ErrorIndicator error={this.state} resetErrorBoundary={this.resetError.bind(this)}/>
                {this.props.children}
            </Fragment>
        )
    }
}

export default ErrorBoundary;
