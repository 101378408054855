import { useEffect } from "react";
//@ts-ignore
const useClickOutside = (closeModal, ref) => {
  //@ts-ignore
  const handleClickOutside = (e) => {
    if (ref.current) {
      if (!ref || !ref.current.contains(e.target)) {
        closeModal(e);
      }
    }
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("click", handleClickOutside, true);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
export default useClickOutside;
