import {Button, SvgIcon} from "@material-ui/core";
import {ReactComponent as Coin} from "../../_icons/coin.svg";
import {ReactComponent as Trust} from "../../_icons/trust_wallet.svg";
import {ReactComponent as Meta} from "../../_icons/metamask.svg";
import {theme} from "../../theme";

// import {ReactComponent as Histograph} from "../../_icons/histo.svg";

export default function WalletForm() {
    return (
        <div>
            <Button variant='contained' color='secondary' fullWidth
                    style={{justifyContent: 'space-between', padding: 16, fontSize: '1rem', letterSpacing: 0.2, fontWeight: 700 }}
                    endIcon={
                        <SvgIcon
                            style={{fontSize: '2em'}}
                            component={Meta}
                            viewBox='0 0 32 33'
                        />
                    }>
                Metamask
            </Button>
            <Button variant='contained' color='secondary' fullWidth
                    style={{justifyContent: 'space-between',
                        marginTop: theme.spacing(2), padding: 16, fontSize: '1rem', letterSpacing: 0.2, fontWeight: 700 }}
                    endIcon={
                        <SvgIcon
                            style={{fontSize: '2em'}}
                            component={Trust}
                            viewBox='0 0 32 32'
                        />
                    }>
                Trust wallet
            </Button>
            <Button variant='contained' color='secondary' fullWidth
                    style={{justifyContent: 'space-between',
                        marginTop: theme.spacing(2), padding: 16, marginBottom: 14, fontSize: '1rem', letterSpacing: 0.2, fontWeight: 700 }}
                    endIcon={
                        <Coin/>
                    }>
                Coinbase Wallet
            </Button>
        </div>
    )
}