import { Box, Button, Card, CardHeader, Grid, Link } from "@material-ui/core";
import ImageCard from "../components/ImageCard";
import ShowMore from "../components/ShowMore";

import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import StatusButton from "../components/StatusButton";
import { useQuery } from "react-query";
import { eventService } from "../_services/event.services";
import { imagenService } from "../_services/image.services";
import BigContainer from "../components/BigContainer";
import ImageGrid from "../components/ImageGrid";
import React, { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import { ReactComponent as Logo } from "../_icons/histo_logo_dash.svg";
import { useUser } from "../userContext";
const isUrl = require("is-url");

type type = "event" | "image";

export interface RouteParams {
  type: type;
  id: string;
}

function Title({ type, id: title }: RouteParams) {
  const { dialogDispatch } = useUser();

  function reportError() {
    dialogDispatch({ type: "report" });
  }

  return (
    <Grid
      container
      justify="space-between"
      style={{ marginBottom: 31, alignItems: "center" }}
    >
      <Grid
        item
        style={{
          fontSize: "1.625em",
          color: "#353535",
          letterSpacing: 0.2,
          fontWeight: 700,
        }}
      >
        {title}
      </Grid>
      <Grid item>
        {type === "event" ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF0000",
              color: "white",
              textTransform: "none",
              borderRadius: 0,
              letterSpacing: "-0.005em",
              boxShadow: "none",
            }}
            onClick={reportError}
          >
            Report error
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}

interface SubheaderProps {
  type: type;
  creator: { id: number; name: string };
  date: Date;
  source: string;
}

function SubHeader({ type, creator, date, source }: SubheaderProps) {
  const validUrl = isUrl(source);
  return (
    <Grid container justify="space-between">
      <Grid item style={{ marginBottom: 20 }}>
        <Link
          underline="none"
          variant="body2"
          color="textSecondary"
          style={{
            marginRight: "5px",
            color: "#B6B6B6",
            fontSize: "0.75rem",
            fontWeight: 700,
          }}
        >
          Posted by:
        </Link>
        <Link
          component={RouterLink}
          underline="always"
          variant="body2"
          color="textSecondary"
          to={`/profile/${creator.id}`}
          style={{ color: "#B6B6B6", fontSize: "0.75rem", fontWeight: 700 }}
        >
          {creator.name}
        </Link>

        <Link
          underline="none"
          variant="body2"
          color="textSecondary"
          hidden={type === "event"}
          style={{
            marginLeft: "5px",
            color: "#B6B6B6",
            fontSize: "0.75rem",
            fontWeight: 700,
          }}
        >
          on {date.toLocaleDateString("ro-RO")}
        </Link>
      </Grid>
      {validUrl && (
        <Grid item>
          <Link
            underline="none"
            variant="body2"
            color="textSecondary"
            style={{ color: "#B6B6B6", fontSize: "0.75rem", fontWeight: 700 }}
          >
            Source:
          </Link>
          <Link
            target="_blank"
            href={source}
            underline="always"
            color="textSecondary"
            style={{
              marginLeft: 5,
              color: "#B6B6B6",
              fontSize: "0.75rem",
              fontWeight: 700,
            }}
          >
            {new URL(source).hostname}
          </Link>
        </Grid>
      )}
    </Grid>
  );
}

export interface IDetails {
  title: string;
  event?: { id: number; title: string };
  votes: number;
  img_url: string;
  source: string;
  description: string;
  created_at: Date;
  user: { id: number; name: string };
  voted?: boolean;
  event_id?: number;
  status: string;
}

interface IDetailsProps {
  id: number;
  type: type;
  data: any;
}

function Details({ type, id, data }: IDetailsProps) {
  if (data === undefined) return null;

  return (
    <Grid
      container
      spacing={3}
      justify="flex-start"
      direction="row"
      style={{ paddingTop: 30, paddingBottom: 60 }}
    >
      <Grid item xs={3}>
        <Grid item style={{ paddingBottom: 41 }}>
          <StatusButton
            fullWidth
            style={{
              fontSize: "1.625em",
              fontWeight: 700,
              borderColor: "#000",
              borderRadius: 2,
              letterSpacing: 0.2,
              color: "#000",
              textTransform: "uppercase",
            }}
          >
            {type == 'image' ? 'nft' : type}
          </StatusButton>
        </Grid>
        <Grid item>
          <ImageCard
            type="main"
            title={data.title}
            votes={data.vote_cnt}
            imgUrl={data.img_url}
            user={data.user}
            voted={data.voted}
            event_id={id}
            entity={{
              type: type,
              id: id,
              description: data.description,
              status: data.status,
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={9}
        container
        alignItems="stretch"
        style={{ overflow: "auto" }}
      >
        <Card
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          elevation={0}
        >
          <CardHeader
            style={{ paddingTop: 0, fontSize: "1.625em" }}
            title={<Title type={type} id={data.title} />}
            subheader={
              type === "image" ? (
                <>
                  Event Name:{" "}
                  <Box display="inline" fontWeight="fontWeightBold">
                    <Link
                      component={RouterLink}
                      to={`/details/event/${data.event?.id}`}
                      color={"textPrimary"}
                    >
                      {data.event?.title}
                    </Link>
                  </Box>
                </>
              ) : null
            }
          />
          <ShowMore>
            <SubHeader
              type={type}
              date={data.created_at}
              creator={data.user}
              source={data.source}
            />
            <div
              style={{
                fontSize: "1em",
                color: "#000",
                letterSpacing: 0.3,
                marginBottom: 19,
              }}
            >
              {data.description}
            </div>
          </ShowMore>
        </Card>
      </Grid>
    </Grid>
  );
}

export default function DetailsPage() {
  const { type, id } = useParams<RouteParams>();
  const history = useHistory();
  // const [type, setType] = useState()
  // const [id, setId] = useState('0')
  const int_id = parseInt(id);

  const serviceFct =
    type === "event"
      ? eventService.getEventDetails
      : imagenService.getImageDetails;

  const image_id = type === "image" ? int_id : undefined;

  const { data, isLoading, refetch } = useQuery(
    [type, { id: int_id }],
    serviceFct
  );

  useEffect(() => {
    refetch();
  }, [type, id]);

  if (isLoading) return null;

  function goHome() {
    history.push("/");
  }

  return (
    <BigContainer background style={{ paddingTop: 75 }}>
      <AppBar
        position="fixed"
        elevation={0}
        style={{ height: 75, background: "#F1F1F1" }}
      >
        <Box marginLeft="15px" marginTop="10px" flexWrap="item">
          <Logo onClick={goHome} />
        </Box>
      </AppBar>

      <Details type={type} id={parseInt(id)} data={data} />
      <ImageGrid
        id={data?.event_id === undefined ? int_id : data.event_id}
        image_id={image_id}
        event_title={type === "event" ? data.title : data.event.title}
        event_status={data.status}
      />
    </BigContainer>
  );
}
