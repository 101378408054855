import {
  Avatar,
  Box,
  Button,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import StatusButton from "../StatusButton";
import eventIcon from "../../_icons/eventIcon.png";
import React, { Fragment } from "react";
import ListProps from "./list";
import { useUser } from "../../userContext";
import { useMutation } from "react-query";
import { userService } from "../../_services/user.services";
import { Link as RouterLink } from "react-router-dom";

export default function DashboardListItem({ data }: ListProps<any>) {
  const {state: {user}, dialogDispatch } = useUser();

  const { mutateAsync } = useMutation(userService.start_review);

  function enlargeImg(url: string) {
    dialogDispatch({ type: "preview", payload: { img_url: url } });
  }

  function startReview(data: any) {
    dialogDispatch({ type: "wait_review", payload: { entity: data } });
    mutateAsync({ id: data.id, type: data.type })
      .then((value) => {
        dialogDispatch({
          type: "review",
          payload: { entity: data, timeout: value.timeout },
        });
      })
      .catch((_) => {
        dialogDispatch({
          type: "success",
          payload: { succes_type: "already_reviewed" },
        });
      });
  }

  function ifReviewedByCurrentUser(reviews: any){
    for(let i=0; i < reviews.length;i++){
      if(reviews[i].user_id == user.id)
        return true;
    }
    return false;
  }

  return (
    <>
      {data?.pages.map((page) => (
        <Fragment key={page.current_page}>
          {page.data.map((value: any, index: number) => (
            <ListItem alignItems="center" divider key={index}>
              {value.img_url !== null && (
                <ListItemAvatar>
                  <Avatar
                    src={value.img_url}
                    variant="square"
                    style={{
                      width: 40,
                      height: 40,
                      cursor: 'pointer'
                    }}
                    onClick={() => enlargeImg(value.img_url)}
                  />
                </ListItemAvatar>
              )}
              {value.img_url == null && (
                <ListItemAvatar>
                  <Avatar
                    src={eventIcon}
                    variant="square"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  />
                </ListItemAvatar>
              )}
              <ListItemText
                style={{
                  maxWidth: "40%",
                  marginLeft: value.img_url !== null ? 0 : 0,
                  marginRight: `calc(5% +  ${
                    value.img_url === null ? 65 : 65
                  }px)`,
                }}
                primary={
                  <div style={{ display: "flex" }}>
                    <Link
                      component={RouterLink}
                      to={`/details/${value.type}/${value.id}`}
                    >
                      <Box
                        fontWeight={700}
                        display="flex"
                        style={{ wordBreak: "break-all"}}
                        color="black"
                      >
                        {value.title}
                      </Box>
                    </Link>

                    <Box color={"#9F9F9F"} marginLeft="13px" minWidth="49px">
                      {value.type=='image' ? 'nft'.toUpperCase() : value.type.toUpperCase()}
                    </Box>
                  </div>
                }
                secondary={
                  value.event !== undefined && (
                    <div
                      style={{
                        wordBreak: "break-all",
                        fontWeight: 700,
                        display: "flex",
                        color: "#353535",
                      }}
                    >
                      Event name:
                      <Link
                        component={RouterLink}
                        to={`/details/event/${value.event.id}`}
                      >
                        <Box display="flex" color="black">
                          <Box
                            fontWeight={700}
                            marginLeft="4px"
                            style={{ wordBreak: "break-all" }}
                          >
                            {value.event.title}
                          </Box>
                        </Box>
                      </Link>
                    </div>
                  )
                }
              />
              <ListItemText
                style={{ maxWidth: "32%", marginBottom: 10 }}
                primary={
                  value.user !== undefined ? (
                    <div style={{fontWeight:400}}>
                      Posted By:
                      <Link
                        component={RouterLink}
                        color="textPrimary"
                        style={{ marginLeft: 5 }}
                        to={`profile/${value.user.id}`}
                      >
                        {value.user.name}
                      </Link>
                    </div>
                  ) : (
                    <Box display="flex">
                      <Box fontWeight={700} marginRight="4px">
                        Curators:
                      </Box>
                      {value.curators?.join(", ")}
                    </Box>
                  )
                }
                secondary={
                  <Box display="flex">
                    <Box
                      marginRight="4px"
                    >
                      {value.progress ? "Started: " : "Date:"}
                    </Box>

                    <>{value.review}</>

                    {value.progress
                      ? value.progress.created_at.toLocaleDateString("ro-RO")
                      : new Date(value.created_at).toLocaleDateString("ro-RO")}
                    {value.progress && (
                      <Box fontWeight={700} marginLeft="4px">
                        &#8226; {value.progress.user_name}
                      </Box>
                    )}
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                {value.status === null && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => startReview(value)}
                  >
                    Start Review
                  </Button>
                )}
                {(value.status === "contested" && !ifReviewedByCurrentUser(value.reviews))  && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => startReview(value)}
                    >
                      Start Review
                    </Button>
                )}
                {(value.status === "contested" && ifReviewedByCurrentUser(value.reviews))  && (
                    <StatusButton
                        variant={value.status}
                    >
                      Rejection appealed. 3 Votes needed
                    </StatusButton>
                )}

                {(value.status != null && value.status != "contested") && (
                  <StatusButton
                  variant={value.status}
                  style={{ textTransform: "uppercase" }}
                  >
                    {value.status}
                  </StatusButton>
                )}

              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Fragment>
      ))}
    </>
  );
}
